import Button from '@ingka/button'
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal'
import SSRIcon from '@ingka/ssr-icon'
import calendarIcon from '@ingka/ssr-icon/paths/calendar'
import clipboardPencilIcon from '@ingka/ssr-icon/paths/clipboard-pencil'
import starIcon from '@ingka/ssr-icon/paths/star'
import { space50, space100, space200 } from '@ingka/variables'
import { Badge, Divider, Flex } from '@mantine/core'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { useFormatDate } from 'hooks/formatting'

import { useGameEvents, useMissions } from 'state/slices/api'

import { backgroundColourDefault } from 'styles/tokens/insikt.tokens'
import { colourBrandBlue } from 'styles/tokens/oldSkapa.tokens'

import { Heading, Paragraph } from 'components/primitives/Text'

import { getEventTitle } from '../Level.utils'

interface Props {
  legacyId: string
  isOpen: boolean
  close: () => void
}

const GameInfo = ({ legacyId, isOpen, close }: Props) => {
  const { data: gameEvents = [] } = useGameEvents(legacyId)
  const { data: missions } = useMissions(legacyId)

  const formatDate = useFormatDate()
  const { t } = useTranslation()

  return (
    <Modal escapable visible={isOpen} handleCloseBtn={close}>
      <Sheets
        header={
          <ModalHeader>
            <Heading>{t('features.game.game-info.title')}</Heading>
          </ModalHeader>
        }
        footer={
          <ModalFooter>
            <Button hidden />
          </ModalFooter>
        }
      >
        <ModalBody>
          {gameEvents.map((event) => {
            const mission = missions?.find((mission) => mission.id === event.missionId)
            return (
              <Flex key={event.id} direction={'column'} gap={space100} align={'center'} mb={space100}>
                <Flex
                  w={'100%'}
                  direction={'column'}
                  style={{
                    borderWidth: '1px',
                    borderStyle: 'dashed',
                    borderColor: colourBrandBlue,
                  }}
                  gap={space50}
                  p={space50}
                >
                  <Badge color="yellow" size="md">
                    {t(getEventTitle(event.type))}
                  </Badge>

                  {mission && (
                    <Flex bg={backgroundColourDefault} gap={space50} align={'center'}>
                      <SSRIcon paths={clipboardPencilIcon} />
                      <Paragraph>{mission.title}</Paragraph>
                    </Flex>
                  )}

                  <Flex bg={backgroundColourDefault} gap={space50} align={'center'}>
                    <SSRIcon paths={starIcon} />
                    <Paragraph>
                      {event.xp} {'XP'}
                    </Paragraph>
                  </Flex>

                  <Flex bg={backgroundColourDefault} gap={space50} align={'center'}>
                    <SSRIcon paths={calendarIcon} />
                    <Paragraph>{formatDate(dayjs(event.dateEarned).toDate())}</Paragraph>
                  </Flex>
                </Flex>

                <Divider
                  size={'md'}
                  h={space200}
                  variant="dashed"
                  orientation="vertical"
                  style={{ margin: 'auto' }}
                />
              </Flex>
            )
          })}
        </ModalBody>
      </Sheets>
    </Modal>
  )
}

export default GameInfo
