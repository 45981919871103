import {
  AuthenticationResult,
  AuthError,
  EventMessage,
  EventType,
  InteractionType,
  RedirectRequest,
} from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { getAuth } from 'firebase/auth'
import { useEffect, useState } from 'react'

import { powerBiApiUrl, scopeBase } from 'config/mirosoft/azureAd.config'

/**
 * This hook will return the current logged in users Microsoft access token
 * that can be used to call microsoft api's
 * @param shouldLogin - lazyLoading option. Using this hook causes a redirect instantly. Setting this to false prevents the redirect
 * until set to true. This allows you to control when the user should be redirect to login page (probably after clicking a button or such)
 * @returns Logged in users Microsoft access token
 */
export const useMSAccessToken = (shouldLogin = false) => {
  const { instance: msalInstance, accounts: msalAccounts, inProgress: msalInProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  const [accessToken, setAccessToken] = useState('')

  const auth = getAuth()

  const currentUserEmail = auth.currentUser?.email || ''

  // Power BI REST API call to refresh User Permissions in Power BI
  // Refreshes user permissions and makes sure the user permissions are fully updated
  // https://docs.microsoft.com/rest/api/power-bi/users/refreshuserpermissions
  const tryRefreshUserPermissions = (): void => {
    fetch(powerBiApiUrl + 'v1.0/myorg/RefreshUserPermissions', {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
      method: 'POST',
    })
      .then(function (response) {
        if (response.ok) {
          // Do nothing
        } else {
          // Too many requests in one hour will cause the API to fail
          if (response.status === 429) {
            console.error('Permissions refresh will be available in up to an hour.')
          } else {
            console.error(response)
          }
        }
      })
      .catch(function (error) {
        console.error('Failure in making API call.' + error)
      })
  }

  const authenticate = (): void => {
    const eventCallback = msalInstance.addEventCallback((message: EventMessage) => {
      if (message.eventType === EventType.LOGIN_SUCCESS && !accessToken) {
        const payload = message.payload as AuthenticationResult

        setAccessToken(payload.accessToken)

        tryRefreshUserPermissions()
      }
    })

    const loginRequest: RedirectRequest = {
      scopes: scopeBase,
      account: msalAccounts[0],
      loginHint: currentUserEmail,
    }

    if (!isAuthenticated && msalInProgress === InteractionType.None) {
      if (shouldLogin) {
        msalInstance.loginRedirect(loginRequest)
      }
    } else if (isAuthenticated && !accessToken && msalInProgress === InteractionType.None) {
      // get access token silently from cached id-token
      msalInstance
        .acquireTokenSilent(loginRequest)
        .then((response: AuthenticationResult) => {
          setAccessToken(response.accessToken)

          if (eventCallback) msalInstance.removeEventCallback(eventCallback)
        })
        .catch((error: AuthError) => {
          // Refresh access token silently from cached id-token
          // Makes the call to handleredirectcallback
          if (
            error.errorCode === 'consent_required' ||
            error.errorCode === 'interaction_required' ||
            error.errorCode === 'login_required'
          ) {
            msalInstance.acquireTokenRedirect(loginRequest)
          } else if (error.errorCode === '429') {
            throw new Error(
              'Our Service Token Server (STS) is overloaded, please try again in sometime'
            )
          } else {
            throw new Error('There was some problem fetching the access token' + error.toString())
          }
        })
    }
  }

  useEffect(() => {
    authenticate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLogin])

  return accessToken
}
