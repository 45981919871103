import Text from '@ingka/text'
import { Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { OperationalMetricId } from 'config/domain/metrics.config'

import { spacing25 } from 'styles/tokens/insikt.tokens'

import {
  connectedMetricTextboxStyle,
  metricBannerStyle,
  transKey,
} from '../MissionPreview.constants'

export type Props = {
  metricId?: OperationalMetricId
}

export const ConnectedMetricBanner = ({ metricId }: Props) => {
  const { t } = useTranslation()
  const metricText = metricId ? t(`common.metrics.${metricId}.name`) : t(`${transKey}.no-metric`)
  return (
    <Flex align="center" justify="space-between" style={metricBannerStyle}>
      <Text
        style={{
          padding: spacing25,
          marginLeft: spacing25,
          fontWeight: 'bold',
        }}
      >
        {t(`${transKey}.connected-to`)}
      </Text>

      <Text style={connectedMetricTextboxStyle}>{metricText}</Text>
    </Flex>
  )
}
