import { Flex } from '@mantine/core'
import { useState } from 'react'

import { MissionStatus } from 'lib/types/mission.types'

import { useCoworkerId } from 'hooks/id/useCoworkerId.hooks'

import { useMissions } from 'state/slices/api'

import { spacing100 } from 'styles/tokens/insikt.tokens'

import { MissionsOverview } from 'components/features/CoworkerMVP/CoachingSession/MissionsOverview/MissionsOverview.component'
import { FilterMissionsButtons } from 'components/pages/shared/MissionsPage/MissionsActions/FilterMissionsButtons'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { useAnalytics } from './MissionPreviewList.hooks'
import { MissionPreviewListProps } from './MissionPreviewList.types'

const MissionPreviewList: React.FC<MissionPreviewListProps> = ({ disableFilter = false }) => {
  const coworkerId = useCoworkerId()

  const { data: missions = [], isLoading, isError } = useMissions(coworkerId)

  const missionsStatus = [MissionStatus.Active, MissionStatus.Completed]
  const [selected, setSelected] = useState<MissionStatus>(MissionStatus.Active)

  useAnalytics(disableFilter, selected, coworkerId)

  const filteredMissions = missions.filter((mission) => mission.status === selected)

  if (isError) return <UnexpectedErrorMessage />

  return (
    <>
      <Flex justify="space-between" my={spacing100}>
        <Flex justify="flex-start">
          <FilterMissionsButtons
            missionsStatus={missionsStatus}
            selected={selected}
            setSelected={setSelected}
          />
        </Flex>
      </Flex>
      <MissionsOverview
        missions={filteredMissions}
        isLoading={isLoading}
        completed={selected === MissionStatus.Completed}
      />
    </>
  )
}

export default MissionPreviewList
