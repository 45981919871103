import { isQaMetricId } from 'config/domain/qa.config'

import { CoachingSessionHelper } from 'lib/utils/coachingSession.helper'

import { useCoachingSessions } from 'state/slices/api'

const usePreviousScoreByQaId = (qaId: string, coworkerId: string) => {
  const { data: coachingSessions = [] } = useCoachingSessions(coworkerId)

  if (!isQaMetricId(qaId)) {
    return NaN
  }

  const previousSession = CoachingSessionHelper.getPreviousCoachingSession(coachingSessions)

  if (!previousSession) {
    return NaN
  }

  const previousScore =
    previousSession.sessionScore?.find((entry) => entry.id === qaId)?.averageScore ?? NaN

  return previousScore
}

export default usePreviousScoreByQaId
