import { format } from 'date-fns'
import * as dateFnsLocales from 'date-fns/locale'

type DateFnsLocaleKey = keyof typeof dateFnsLocales

export type Locale = {
  isoCode: string
  englishName: string
  languageName: string
  dateFnsCode: DateFnsLocaleKey
}

export const locales: Record<string, Locale> = {
  'en-XZ': {
    isoCode: 'en-XZ',
    englishName: 'English (International)',
    languageName: 'English (International)',
    dateFnsCode: 'enGB' as DateFnsLocaleKey,
  },
  'en-CA': {
    isoCode: 'en-CA',
    englishName: 'English (Canada)',
    languageName: 'English (Canada)',
    dateFnsCode: 'enCA' as DateFnsLocaleKey,
  },
  'fr-CA': {
    isoCode: 'fr-CA',
    englishName: 'French (Canada)',
    languageName: 'Français (Canada)',
    dateFnsCode: 'frCA' as DateFnsLocaleKey,
  },
  'da-DK': {
    isoCode: 'da-DK',
    englishName: 'Danish',
    languageName: 'Dansk',
    dateFnsCode: 'da' as DateFnsLocaleKey,
  },
  'sv-SE': {
    isoCode: 'sv-SE',
    englishName: 'Swedish',
    languageName: 'Svenska',
    dateFnsCode: 'sv' as DateFnsLocaleKey,
  },
  'pt-PT': {
    isoCode: 'pt-PT',
    englishName: 'Portuguese',
    languageName: 'Português',
    dateFnsCode: 'pt' as DateFnsLocaleKey,
  },
  'es-ES': {
    isoCode: 'es-ES',
    englishName: 'Spanish',
    languageName: 'Español',
    dateFnsCode: 'es' as DateFnsLocaleKey,
  },
  'de-CH': {
    isoCode: 'de-CH',
    englishName: 'German (Switzerland)',
    languageName: 'Deutsch (Schweiz)',
    dateFnsCode: 'deCH' as DateFnsLocaleKey,
  },
  'fr-CH': {
    isoCode: 'fr-CH',
    englishName: 'French (Switzerland)',
    languageName: 'Français (Suisse)',
    dateFnsCode: 'frCH' as DateFnsLocaleKey,
  },
  'it-CH': {
    isoCode: 'it-CH',
    englishName: 'Italian (Switzerland)',
    languageName: 'Italiano (Svizzera)',
    dateFnsCode: 'itCH' as DateFnsLocaleKey,
  },
  'nb-NO': {
    isoCode: 'nb-NO',
    englishName: 'Norwegian (Bokmål)',
    languageName: 'Norsk (Bokmål)',
    dateFnsCode: 'nb' as DateFnsLocaleKey,
  },
  'ro-RO': {
    isoCode: 'ro-RO',
    englishName: 'Romanian',
    languageName: 'Română',
    dateFnsCode: 'ro' as DateFnsLocaleKey,
  },
  'sr-RS': {
    isoCode: 'sr-RS',
    englishName: 'Serbian',
    languageName: 'Српски', // This name is using special characters - this C is not an actual C, which is why the language is displayed at the very end
    dateFnsCode: 'sr' as DateFnsLocaleKey,
  },
  'sl-SL': {
    isoCode: 'sl-SL',
    englishName: 'Slovenian',
    languageName: 'Slovenščina',
    dateFnsCode: 'sl' as DateFnsLocaleKey,
  },
  'hr-HR': {
    isoCode: 'hr-HR',
    englishName: 'Croatian',
    languageName: 'Hrvatski',
    dateFnsCode: 'hr' as DateFnsLocaleKey,
  },
  'sk-SK': {
    isoCode: 'sk-SK',
    englishName: 'Slovak',
    languageName: 'Slovenský',
    dateFnsCode: 'sk' as DateFnsLocaleKey,
  },
  'hu-HU': {
    isoCode: 'hu-HU',
    englishName: 'Hungarian',
    languageName: 'Magyar',
    dateFnsCode: 'hu' as DateFnsLocaleKey,
  },
  'cs-CZ': {
    isoCode: 'cs-CZ',
    englishName: 'Czech',
    languageName: 'Čeština',
    dateFnsCode: 'cs' as DateFnsLocaleKey,
  },
  'fi-FI': {
    isoCode: 'fi-FI',
    englishName: 'Finnish',
    languageName: 'Suomi',
    dateFnsCode: 'fi' as DateFnsLocaleKey,
  },
  // Hello there! Adding a new language? Do not forget to add it to the DB, so we could fetch translations from the Exponera!
  // Look at the addNewLanguage script in the scripts folder for more information!
  // PS: Force run the function in all envs afterwards.
}

export const defaultLocale = locales['en-XZ']

type SupportedLocaleIsoCode = keyof typeof locales
export const isSupportedLocaleIsoCode = (code: string): code is SupportedLocaleIsoCode =>
  Object.keys(locales).includes(code)

export const getDateFnsLocale = (localeCode: DateFnsLocaleKey) => dateFnsLocales[localeCode]
export const formatDate = (date: Date, localeCode: DateFnsLocaleKey) =>
  format(date, 'P', { locale: dateFnsLocales[localeCode] })
