import Button from '@ingka/button'
import { fontSize300, space50, space200 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'
import { CustomElementClickEventKeys } from 'lib/utils/analytics/analytics.types'
import { CoachingSessionHelper } from 'lib/utils/coachingSession.helper'

import { useCurrentCoachingSession } from 'hooks/coachingSessions'
import { useCoworkerStatus } from 'hooks/coworkers/useCoworkerStatus.hooks'
import { useCoworkerAndTeamleadParams } from 'hooks/id/useCoworkerAndTeamleadParams'
import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { useCoachingSessions, useCoworker, useTeamQuery } from 'state/slices/api'

import { colourNeutralGrey200 } from 'styles/tokens/oldSkapa.tokens'

import { CoworkersOverviewStatus } from 'components/composites/CoworkersOverviewStatus'
import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import CoworkerPhoto from 'components/features/Coworkers/CoworkersOverview/CoworkerPhoto/CoworkerPhoto.component'
import NextScheduledCoaching from 'components/features/Coworkers/CoworkersOverview/CoworkerStatus/NextCoachingStatus.component'
import { PageHeader } from 'components/layout/PageHeader'
import { RedirectingLayout } from 'components/layout/RedirectingLayout'
import { TeamleadTabs } from 'components/pages/teamlead/ManageCoworkerPage/TeamleadTabs'
import { Paragraph } from 'components/primitives/Text'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

const ManageCoworkerPage: React.FC = () => {
  const { t } = useTranslation()
  const { teamleadPath, coworkerPath } = useSectionPaths()
  const { coworkerId, teamleadId } = useCoworkerAndTeamleadParams()
  const { data: coworker, isFetching, isLoading, isSuccess, isError } = useCoworker(coworkerId)

  // Pre-fetch team and coaching session
  useTeamQuery(coworker?.teamId ?? skipToken)
  useCurrentCoachingSession(coworkerId)
  const { data: coachingSessions = [] } = useCoachingSessions(coworkerId)

  const { hash } = useLocation()

  const coworkerStatus = useCoworkerStatus(coworkerId)

  // Used for navigation back to teamlead tabs
  const fromUrl = coworkerPath + hash

  const upcomingSessionData =
    coworker && CoachingSessionHelper.getUpcomingCoachingSessionData(coachingSessions)

  const upcomingCoachingSession = upcomingSessionData?.session

  const upcomingCoachingSessionUrl =
    coworkerPath + CoachingSessionHelper.getHref(upcomingCoachingSession, true)
  const nav = useNavigate()

  useEffect(() => {
    if (coworker) {
      const analytics = getAnalytics()
      logEvent(analytics, 'manage_coworker', {
        description: 'Custom event for tracking when a teamlead enters the manage coworker page.',
        coworkerId: coworker.legacyId,
        teamleadId,
        nextSessionDate: upcomingCoachingSession?.plannedDate,
        status: coworkerStatus.status,
        assessmentsNeeded: coworkerStatus.assessmentsNeeded,
        assessmentsCompleted: coworkerStatus.assessmentsCompleted,
      })
    }
  }, [coworkerStatus, coworker, upcomingCoachingSession, teamleadId])

  if (!coworkerId) {
    if (process.env.REACT_APP_ENV === 'dev') {
      throw new Error('No coworkerId while trying to loading manage coworker page.')
    }
    return <RedirectingLayout />
  }

  return (
    <div data-testid="page-manage-coworker">
      <PageHeader
        title={t('features.coworker.manage-coworker.back-title')}
        backHref={`${teamleadPath}/coworkers`}
        coworker={coworker}
      />
      {isLoading && <LoadingIndicator message={t('loading-messages.fetching-coworker-profile')} />}
      {(isError || (isSuccess && !coworker)) && <UnexpectedErrorMessage />}
      {isSuccess && coworker && (
        <>
          {coworker && (
            <Flex
              align="center"
              justify={'space-between'}
              mt={space200}
              mb={space200}
              wrap={'wrap'}
            >
              <Flex gap={space50} align={'center'}>
                <CoworkerPhoto coworker={coworker} />
                <Paragraph style={{ fontSize: fontSize300 }} $bold>
                  {coworker.fullName}
                </Paragraph>
                <Flex
                  style={{
                    backgroundColor: colourNeutralGrey200,
                    padding: space50,
                    borderRadius: space50,
                    marginLeft: space50,
                  }}
                >
                  {isFetching ? (
                    <Paragraph>{t('common.loading')}</Paragraph>
                  ) : (
                    <CoworkersOverviewStatus coworkerId={coworkerId} />
                  )}
                </Flex>
              </Flex>

              <Flex gap={space50}>
                <Flex
                  align={'center'}
                  style={{
                    backgroundColor: colourNeutralGrey200,
                    padding: space50,
                    borderRadius: space50,
                  }}
                >
                  <NextScheduledCoaching coworkerId={coworkerId} />
                </Flex>

                {upcomingCoachingSession && (
                  <Button
                    type={upcomingCoachingSession.draftCompleted ? 'primary' : 'emphasised'}
                    small
                    text={
                      upcomingCoachingSession.draftCompleted
                        ? t('features.coaching-session.actions.start-coaching')
                        : t('features.coaching-session.actions.prepare-coaching')
                    }
                    onClick={() => {
                      upcomingCoachingSession.draftCompleted
                        ? analyticsHelper.createCustomElementClickEvent(
                            {
                              key: CustomElementClickEventKeys.StartCoaching,
                              description:
                                'Custom event for tracking when a teamlead clicks on the start coaching button.',
                            },
                            {
                              coachingSessionId: upcomingCoachingSession.id,
                              coworkerId,
                              teamleadId,
                            }
                          )
                        : analyticsHelper.createCustomElementClickEvent(
                            {
                              key: CustomElementClickEventKeys.PrepareCoaching,
                              description:
                                'Custom event for tracking when a teamlead clicks on the prepare coaching button.',
                            },
                            {
                              coachingSessionId: upcomingCoachingSession.id,
                              coworkerId: coworkerId,
                            }
                          )
                      nav(upcomingCoachingSessionUrl, {
                        state: { from: fromUrl, prepare: true },
                      })
                    }}
                  />
                )}
              </Flex>
            </Flex>
          )}

          <TeamleadTabs coworkerId={coworkerId} />
        </>
      )}
    </div>
  )
}

export default ManageCoworkerPage
