import { skipToken } from '@reduxjs/toolkit/dist/query'

import { isMetricId, MetricId, metricsConfig } from 'config/domain/metrics.config'

import { useCountries, useCoworker, useTeamQuery } from 'state/slices/api'

export const useMetricSymbol = (metricId: MetricId, id: string, idType: 'coworker' | 'team') => {
  const { data: coworker } = useCoworker(idType === 'coworker' ? id : skipToken)
  const { data: team } = useTeamQuery(idType === 'team' ? id : skipToken)
  const { data: countries } = useCountries()

  if (
    (coworker === undefined && team === undefined) ||
    countries === undefined ||
    !isMetricId(metricId)
  ) {
    return undefined
  }

  const country = idType === 'coworker' ? coworker?.country : team?.country

  const countryConfig = countries.find((c) => c.countryCode === country)

  const metricConfig = metricsConfig[metricId]

  if (countryConfig && metricConfig.metricBehaviour.unit === 'monetary') {
    return `${countryConfig.settings.currencyCode}`
  } else if (metricConfig.metricBehaviour.unit === 'percentage') {
    return '%'
  } else {
    return undefined
  }
}
