import SSRIcon from '@ingka/ssr-icon'
import Text from '@ingka/text'
import { durationSlowWeb, easeBob, radiusCap, space100 } from '@ingka/variables'

import styled from 'styled-components'

type LockedBadgeProps = {
  colored?: boolean
}
export const Badge = styled.div<LockedBadgeProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${space100};
  ${(props) => (props.colored ? `transition: ${easeBob} ${durationSlowWeb}` : '')};
  &:hover {
    ${(props) => (props.colored ? `transform: scale(1.3)` : '')};
  }
`

type EmojiWrapperProps = {
  colored?: boolean
}

export const EmojiWrapper = styled.div<EmojiWrapperProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100px;
  height: 100px;
  border-radius: ${radiusCap};
  ${(props) => (props.colored ? '' : 'filter: grayscale(1);')}
  overflow: hidden;
`

export const TextWrapper = styled.div`
  width: 100%;
  text-align: center;
`
export const BadgeImage = styled.img`
  width: 50px;
  display: flex;
`

export const Emoji = styled(Text)`
  font-size: 48px;
  user-select: none;
`

export const Icon = styled(SSRIcon)`
  position: absolute;
  bottom: 15px;
  right: 15px;
`
