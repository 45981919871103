import Button from '@ingka/button'
import createNew from '@ingka/ssr-icon/paths/create-new'
import { space75, space100 } from '@ingka/variables'
import { Flex, Grid, Stack } from '@mantine/core'
import { skipToken } from '@reduxjs/toolkit/query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Mission, MissionStatus } from 'lib/types/mission.types'

import { useValidateParams } from 'hooks/navigation/useValidateParams'

import { useMissions } from 'state/slices/api'

import { MissionPreviewTeaser } from 'components/composites/Missions/MissionPreview/MissionPreviewTeaser'
import MissionDraftDrawer from 'components/features/Missions/MissionDraftEditor/MissionDraftDrawer/MissionDraftDrawer.component'
import { RedirectingLayout } from 'components/layout/RedirectingLayout'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { CoachingSessionMissionDraftProps } from './MissionDraftEditor.types'
import MissionDraftSkeleton from './MissionDraftSkeleton/MissionDraftSkeleton.component'

const SKELETON_COUNT = 2

const MissionDraftEditor: React.FC<CoachingSessionMissionDraftProps> = ({ selectedCheckboxId }) => {
  const { coworkerId } = useParams()

  const { t } = useTranslation()
  const [showCreateMission, setShowCreateMission] = useState(false)

  const {
    data: missions,
    isLoading: isLoadingMissions,
    isError: isErrorMissions,
  } = useMissions(coworkerId || skipToken)

  const mission = missions?.find(
    (mission) =>
      mission.qaId === selectedCheckboxId &&
      (mission.status === MissionStatus.Active || mission.status === MissionStatus.Draft)
  ) as Mission

  const handleDrawerOpen = () => {
    setShowCreateMission(true)
  }
  const handleDrawerClose = () => {
    setShowCreateMission(false)
  }

  useValidateParams('coworkerId')
  if (!coworkerId) return <RedirectingLayout />

  return (
    <>
      {isLoadingMissions && (
        <Grid>
          {Array.from({ length: SKELETON_COUNT }).map((_, index) => (
            <Grid.Col key={index}>
              <MissionDraftSkeleton />
            </Grid.Col>
          ))}
        </Grid>
      )}
      {isErrorMissions && <UnexpectedErrorMessage />}

      {/* Show mission teaser with the selected qaId */}
      <Stack gap={space100}>
        {mission && mission.qaId === selectedCheckboxId && (
          <MissionPreviewTeaser mission={mission} coworkerId={coworkerId} />
        )}

        {/* Else show create button */}
        {!mission && (
          <Flex justify="flex-end" p={space75}>
            <Button
              small
              type="primary"
              text={t('common.actions.create-draft')}
              ssrIcon={createNew}
              iconPosition="leading"
              onClick={handleDrawerOpen}
              disabled={!selectedCheckboxId}
            />
          </Flex>
        )}
      </Stack>

      {/* Open create/edit mission drawer */}
      {showCreateMission && selectedCheckboxId && (
        <MissionDraftDrawer
          coworkerId={coworkerId!}
          showCreateMission={showCreateMission}
          closeDrawer={handleDrawerClose}
          mission={mission!}
          selectedCheckboxId={selectedCheckboxId}
          isEditing={false}
        />
      )}

      {!selectedCheckboxId && showCreateMission && (
        <Flex justify="flex-end">{t('A question must be selected to create a mission draft')}</Flex>
      )}
    </>
  )
}

export default MissionDraftEditor
