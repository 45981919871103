import SSRIcon from '@ingka/ssr-icon'
import InformationCircle from '@ingka/ssr-icon/paths/information-circle'
import { TableBody, TableHeader } from '@ingka/table'
import { space100 } from '@ingka/variables'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { QaMetricId } from 'config/domain/qa.config'
import { qarGroups } from 'config/domain/qarForm.config'
import { qasGroups } from 'config/domain/qasForm.config'
import { qsaGroups } from 'config/domain/qsaForm.config'

import { Mission } from 'lib/types/mission.types'

import { useCurrentQaDataForCoworker } from 'hooks/qualityAssssments/useCurrentQaDataForCoworker.hooks'

import { useMissions } from 'state/slices/api'

import QaScore from 'components/composites/QualityAssessments/QaScore'
import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import { useGroupedQuestionItems } from 'components/features/Missions/MissionQaMetricsTable/MissionQaMetricsTable.hook'
import { SubHeading } from 'components/primitives/Text'

import MissionQaMetricsInfo from './MissionQaMetricsInfo.component'
import * as Styled from './MissionQaMetricsTable.styling'
import { MissionQaMetricsTableProps } from './MissionQaMetricsTable.types'
import QaMetricsTableRow from './QaMetricTableRow.component'

const MissionQaMetricsTable: React.FC<MissionQaMetricsTableProps> = ({
  coworkerId,
  assessmentType,
  qaSelectForm,
  isChecked,
  setIsChecked,
  selectedCheckboxId,
  setSelectedCheckboxId,
  mission,
  setCurrentMission,
}) => {
  const [showInfo, setShowInfo] = useState(false)
  const closeInfoModal = () => setShowInfo(false)
  const openInfoModal = () => setShowInfo(true)
  const { t } = useTranslation()
  const { data: missions, isLoading: isLoadingMissions } = useMissions(coworkerId)

  const qaData = useCurrentQaDataForCoworker(coworkerId)

  let questionData = {}
  if (assessmentType === 'QAR') {
    questionData = qarGroups
  } else if (assessmentType === 'QAS') {
    questionData = qasGroups
  } else if (assessmentType === 'QSA') {
    questionData = qsaGroups
  }

  const groupedQuestionItems = useGroupedQuestionItems(assessmentType, questionData)

  const handleCheckboxSelected = (checked: boolean, id: string) => {
    const inMission = missions?.find(
      (mission: Mission) =>
        (mission.status === 'active' || mission.status === 'draft') && mission.qaId === id
    )
    if (checked) {
      setIsChecked(true)
      setSelectedCheckboxId(id)
      qaSelectForm.setValue('qaId', id as QaMetricId)
    } else {
      setCurrentMission(inMission)
      setSelectedCheckboxId(null)
      setIsChecked(false)
    }
  }

  useEffect(() => {
    if (assessmentType !== mission?.qaId) {
      setSelectedCheckboxId(null)
      setIsChecked(false)
    }
  }, [assessmentType, mission?.qaId, setIsChecked, setSelectedCheckboxId])

  return (
    <>
      {isLoadingMissions && <LoadingIndicator />}
      {qaData && (
        <>
          <MissionQaMetricsInfo show={showInfo} close={closeInfoModal} />

          <Styled.CustomTable>
            <TableHeader>
              <tr>
                <th>
                  {t(
                    'features.mission.create-mission.qa-metrics-table.table-headers.scorecard-item'
                  )}
                </th>

                <th>
                  {t(
                    'features.mission.create-mission.qa-metrics-table.table-headers.previous-score'
                  )}
                </th>
                <th>
                  {t(
                    'features.mission.create-mission.qa-metrics-table.table-headers.current-score'
                  )}
                </th>
                <th>
                  {t('features.mission.create-mission.qa-metrics-table.table-headers.draft-status')}
                </th>
                <th>
                  <SSRIcon
                    paths={InformationCircle}
                    onClick={openInfoModal}
                    style={{ cursor: 'pointer' }}
                  />
                </th>
              </tr>
            </TableHeader>
            <TableBody>
              {/* Totals row */}
              <tr>
                <td>
                  <SubHeading>{t(`qa.${assessmentType}TT`)}</SubHeading>
                </td>

                <td>
                  <SubHeading>
                    <QaScore coworkerId={coworkerId} qaId={assessmentType + 'TT'} type="previous" />
                  </SubHeading>
                </td>

                <td>
                  <SubHeading>
                    <QaScore coworkerId={coworkerId} qaId={assessmentType + 'TT'} type="current" />
                  </SubHeading>
                </td>
              </tr>

              {/* Group row */}
              {groupedQuestionItems.map((group, index) => (
                <React.Fragment key={group.groupId}>
                  <tr>
                    <td style={{ paddingLeft: space100 }}>
                      <SubHeading>{t(`qa.${group.groupId}`) as string}</SubHeading>
                    </td>
                    <td>
                      <SubHeading>
                        <QaScore coworkerId={coworkerId} qaId={group.groupId} type="previous" />
                      </SubHeading>
                    </td>
                    <td>
                      <SubHeading>
                        <QaScore coworkerId={coworkerId} qaId={group.groupId} type="current" />
                      </SubHeading>
                    </td>
                  </tr>

                  {/* QA Questions for the group */}
                  {group.questions.map((question) => (
                    <QaMetricsTableRow
                      coworkerId={coworkerId}
                      key={question.id}
                      question={question}
                      qaSelectForm={qaSelectForm}
                      isChecked={isChecked && selectedCheckboxId === question.id}
                      handleCheckboxSelected={(checked) =>
                        handleCheckboxSelected(checked, question.id)
                      }
                    />
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Styled.CustomTable>
        </>
      )}
    </>
  )
}

export default MissionQaMetricsTable
