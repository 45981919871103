import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Country } from 'lib/types/country.types'

import { useCountries } from 'state/slices/api'
import { useUpdateCountrySettings } from 'state/slices/api'

export const useCountrySelectionForm = () => {
  const form = useForm({
    defaultValues: {
      selectedCountryId: undefined,
    },
  })

  return form
}

export const useSelectedCountry = () => {
  const { data: countries, ...meta } = useCountries()

  const form = useCountrySelectionForm()
  const { watch } = form
  const { selectedCountryId } = watch()
  const selectedCountry = countries?.find((country) => country.countryCode === selectedCountryId)

  const sortedCountries = countries
    ? [...countries].sort((a, b) => a.name.localeCompare(b.name))
    : []

  return [
    {
      countries: sortedCountries,
      selectedCountry,
      ...meta,
    },
    form,
  ] as const
}

export const useManageCountryForm = (country: Country | undefined) => {
  const [updateCountrySettings, ...meta] = useUpdateCountrySettings()

  const form = useForm({
    defaultValues: {
      isAppEnabled: country?.settings.isAppEnabled ?? false,
    },
  })

  const { getValues, handleSubmit, reset } = form

  const handleSubmitCountrySettings = useCallback(() => {
    if (!country) return
    updateCountrySettings({
      countryCode: country.countryCode,
      setting: 'isAppEnabled',
      value: getValues().isAppEnabled,
    })
  }, [country, getValues, updateCountrySettings])

  // Reset form when country changes
  useEffect(() => {
    if (country) {
      reset({
        isAppEnabled: country.settings.isAppEnabled,
      })
    }
  }, [country, reset])

  return [
    {
      ...form,
      handleSubmit: handleSubmit(handleSubmitCountrySettings),
    },
    ...meta,
  ] as const
}
