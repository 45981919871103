import Text from '@ingka/text'
import { Divider, Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { BrandColour, spacing100 } from 'styles/tokens/insikt.tokens'

import { NumberWidget } from 'components/primitives/NumberWidget/NumberWidget.component'

export type Props = {
  initialScore: number
  target: number
}

export const MissionProgressArrow = ({ initialScore, target }: Props) => {
  const { t } = useTranslation()

  return (
    <Flex
      align="center"
      gap="md"
      style={{
        padding: spacing100,
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <NumberWidget
        number={initialScore}
        label={t(`features.coaching-session.coworker.missions.label-start`)}
      />
      <Divider
        variant="dashed"
        color={BrandColour.Blue}
        label={
          <Text
            style={{
              color: BrandColour.Blue,
              alignSelf: 'center',
              fontWeight: 'bold',
              marginLeft: -5,
            }}
          >
            &rarr;
          </Text>
        }
        labelPosition="right"
        size="sm"
        style={{
          width: '100%',
          color: BrandColour.Blue,
        }}
      />

      <NumberWidget
        number={target}
        label={t('features.coaching-session.coworker.missions.label-goal')}
      />
    </Flex>
  )
}
