import dayjs from 'dayjs'

import { CoachingSession, CoachingSessionStatus } from 'lib/types/coachingSession.types'

type UpcomingSessionReturnType = {
  formattedDate: string
  diffInDays: number
  isOverdue: boolean
  isPlanned: boolean
  session: CoachingSession
}

export class CoachingSessionHelper {
  static getUpcomingCoachingSessionData(
    coachingSessions: CoachingSession[]
  ): UpcomingSessionReturnType | undefined {
    // Get session with latest planned date
    const upcomingCoachingSession = this.getCurrentCoachingSession(coachingSessions)

    if (!upcomingCoachingSession) {
      return undefined
    }

    const formattedDate =
      upcomingCoachingSession && dayjs(upcomingCoachingSession.plannedDate).format('YYYY-MM-DD') //formatDate(new Date(plannedDate))

    // (14/09-23) Be aware that date handling is being changed currently (application wide), to ensure that calculations such as below, remains consistens and stable.
    const today = dayjs().startOf('day')
    const plannedTime =
      upcomingCoachingSession && dayjs(upcomingCoachingSession.plannedDate).startOf('day')
    const diffInDays = today.diff(plannedTime, 'days')
    const isOverdue = plannedTime?.isBefore(today)
    const isPlanned = upcomingCoachingSession?.status === 'planned'

    return {
      formattedDate,
      diffInDays,
      isOverdue,
      isPlanned,
      session: upcomingCoachingSession,
    }
  }

  static getCurrentCoachingSession(
    coachingSessions: CoachingSession[]
  ): CoachingSession | undefined {
    // Get session with latest planned date
    const currentCoachingSession = coachingSessions?.find((cs) => {
      return cs.status === CoachingSessionStatus.Planned
    })

    return currentCoachingSession
  }

  static getPreviousCoachingSession(
    coachingSessions: CoachingSession[]
  ): CoachingSession | undefined {
    const completedSessions = coachingSessions.filter((cs) => {
      return (
        cs.status === CoachingSessionStatus.Completed || cs.status === CoachingSessionStatus.Signed
      )
    })

    const previousCoachingSession =
      completedSessions.length > 0
        ? completedSessions.reduce((previous, current) =>
            dayjs(previous.completedDate).isAfter(dayjs(current.completedDate)) ? previous : current
          )
        : undefined

    return previousCoachingSession
  }

  static getInitialCoachingSession(
    coachingSessions: CoachingSession[],
    missionStarted: string
  ): CoachingSession | undefined {
    // Get session with latest planned date

    const initialCoachingSession = coachingSessions.find((cs) => {
      return dayjs(cs.completedDate).isSame(missionStarted, 'day')
    })

    return initialCoachingSession
  }

  static getHref(coachingSession: CoachingSession | undefined, coach = false): string {
    if (!coachingSession) {
      return '#coaching-sessions'
    }

    const baseHref = `/coaching-sessions/${coachingSession.id}`

    if (coachingSession.status !== CoachingSessionStatus.Planned) {
      return baseHref
    }

    if (coach && coachingSession.draftCompleted) {
      return baseHref + '/flow'
    }

    return baseHref + '/prepare'
  }
}
