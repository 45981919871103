import { useTranslation } from 'react-i18next'

import { FormattingHelper } from 'lib/utils/formatting.helper'

import { transKey } from '../MissionPreview/MissionPreview.constants'
import { MissionProgressProps } from './MissionProgress.types'

export const useEvaluateMission = ({
  currentScore,
  startScore,
  targetScore,
  isCompleted,
}: MissionProgressProps) => {
  const { t } = useTranslation()
  if (isCompleted) {
    return {
      color: 'green',
      label: t(`${transKey}.completed`),
      value: 100,
    }
  }

  if (isNaN(currentScore)) {
    return {
      color: 'yellow',
      label: t(`${transKey}.waiting-for-assessments`),
      value: 100,
    }
  }

  const initialScore = isNaN(startScore) ? 0 : startScore

  const value = Math.round(((currentScore - initialScore) / (targetScore - initialScore)) * 100)

  return {
    color: currentScore >= targetScore ? 'green' : 'blue',
    label: FormattingHelper.displayNumber(currentScore),
    value,
  }
}
