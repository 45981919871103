import 'fast-text-encoding'
import { getAnalytics, isSupported } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import {
  browserLocalPersistence,
  browserSessionPersistence,
  connectAuthEmulator,
  getAuth,
  OAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  User,
} from 'firebase/auth'
import {
  connectFirestoreEmulator,
  doc,
  getFirestore,
  updateDoc,
} from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'

import {
  firebaseConfigDev,
  firebaseConfigPreProd,
  firebaseConfigProd,
  firebaseConfigProdV2,
  firebaseConfigStage,
} from './firebaseProjects.config'

if (process.env.REACT_APP_API_BASEURL === undefined) {
  throw new Error(
    `REACT_APP_API_BASEURL has not been set. This will cause errors when trying to use the API.
    Please set it up in the frontend/.env file`,
  )
}

if (process.env.REACT_APP_ENV === undefined) {
  throw new Error(
    `REACT_APP_ENV has not been set. Please set it up in the frontend .env file.`,
  )
}

if (process.env.REACT_APP_FUNCTIONS_LOCATION === undefined) {
  console.warn(
    `REACT_APP_FUNCTIONS_LOCATION has not been set. This will cause errors when trying to use the API.`,
  )
}
// Switched from getting the window ref as this was causing issues with the local connection to the dev env4
export const isLocalEnv =
  process.env.REACT_APP_API_BASEURL.includes('localhost')

export const mapEnvToFirebaseConfig = () => {
  const envName = process.env.REACT_APP_ENV ?? ''
  switch (envName.toLowerCase()) {
    case 'prod':
      return firebaseConfigProd
    case 'dev':
      return firebaseConfigDev
    case 'pre-prod':
      return firebaseConfigPreProd
    case 'v2':
      return firebaseConfigProdV2
    case 'stage':
      return firebaseConfigStage
    default:
      throw new Error('Cannot match config for ENV name >' + envName)
  }
}

export const firebaseApp = initializeApp(mapEnvToFirebaseConfig())
export const firestore = getFirestore(firebaseApp)

export const analytics = isSupported().then((isSupported: boolean) => {
  if (isSupported) {
    getAnalytics()
  }
})
export const auth = getAuth(firebaseApp)
// TODO: We might want to evaluate whether we want a better persistance in dev or not
auth.setPersistence(
  process.env.REACT_APP_ENV === 'dev'
    ? browserLocalPersistence
    : browserSessionPersistence,
)

export const functions = getFunctions(
  firebaseApp,
  process.env.REACT_APP_FUNCTIONS_LOCATION,
)

export const onAuthStateChanged = async (): Promise<User | null> => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(async (user: User | null) => {
      // Set the last login timestamp to the current time
      try {
        if (user) {
          const userDoc = doc(firestore, `users/${user.uid}`)
          await updateDoc(userDoc, {
            lastLogin: new Date().toISOString(),
          })
        }
      } catch {
        // Ignore errors
      }

      unsubscribe()
      resolve(user)
    }, reject)
  })
}

export const signIn = async () => {
  const provider = new OAuthProvider('microsoft.com')
  provider.setCustomParameters({
    tenant: '720b637a-655a-40cf-816a-f22f40755c2c',
  })

  // If the browser is Firefox, use a popup instead of a redirect - ||

  if (
    navigator.userAgent.toLowerCase().indexOf('firefox') !== -1 ||
    window.location.hostname === 'localhost'
  ) {
    await signInWithPopup(auth, provider)
  } else {
    await signInWithRedirect(auth, provider)
  }
}

export const signOut = async () => {
  await auth.signOut()
  await signIn()
}

if (isLocalEnv) {
  // If the code is running in a test environment,
  // disable unnecessary warnings from the emulator
  connectAuthEmulator(auth, 'http://localhost:9099', {
    disableWarnings: process.env.JEST_WORKER_ID != null,
  })

  connectFirestoreEmulator(firestore, 'localhost', 8080)

  connectFunctionsEmulator(functions, 'localhost', 5002)
}
