import FormField from '@ingka/form-field'
import { Grid } from '@mantine/core'
import dayjs from 'dayjs'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Coworker } from 'lib/types/coworker.types'
import { Mission, MissionStatus } from 'lib/types/mission.types'

import { MissionDraftFormFields } from 'components/features/Missions/MissionDraftEditor/MissionDraftEditor.types'
import { DatePicker } from 'components/primitives/DatePicker'

type Props = {
  form: UseFormReturn<MissionDraftFormFields> & {
    watch: (field: keyof MissionDraftFormFields) => any
  }
  coworker: Coworker
  mission: Mission
}

const DurationStep: React.FC<Props> = ({ form, coworker, mission }) => {
  const { t } = useTranslation()
  const { control, formState, watch } = form
  let { startedDate } = watch()
  const { coachingSessionId } = useParams()

  const coachingSession = coworker?.coachingSessions?.find(
    (session) => session.id === coachingSessionId
  )

  const plannedDate = dayjs(coachingSession?.plannedDate).toDate()

  // If the mission already exists and is in draft mode
  if (!mission || mission.status === MissionStatus.Draft) {
    // If today is after the planned date, then set startedDate to today
    if (dayjs().isAfter(plannedDate)) {
      startedDate = dayjs().toDate()
    } else {
      // Else if today is before the planned date, then set startedDate to plannedDate of the coaching session
      startedDate = plannedDate
    }
  } else if (mission) {
    // Else if the mission is active or completed, then set startedDate to the mission's startedDate
    startedDate = dayjs(mission.startedDate).toDate()
  }

  return (
    <>
      <Grid>
        <Grid.Col span={6}>
          <Controller
            name="startedDate"
            control={control}
            disabled={true}
            render={({ field: { onChange, onBlur, value } }) => (
              <FormField
                data-testid="form-field-start-date"
                fieldHelper={{
                  msg: t('features.mission.create-mission.fields.start-date.hint'),
                }}
              >
                <DatePicker
                  id="startDate"
                  data-testid="input-field-startDate"
                  label={t('features.mission.create-mission.fields.start-date.title')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={startedDate || undefined}
                  disabled={true}
                />
              </FormField>
            )}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Controller
            name="endDate"
            control={control}
            rules={{
              required: t('features.mission.create-mission.fields.end-date.errors.missing'),
              pattern: {
                value: /^\d{4}-\d{1,2}-\d{1,2}$/,
                message: t(
                  'features.mission.create-mission.fields.end-date.errors.invalid-pattern'
                ),
              },
              validate: (value) => {
                return `${value} is a valid end date.`
              },
            }}
            render={({ field }) => (
              <FormField
                data-testid="form-field-end-date"
                fieldHelper={{
                  msg: t('features.mission.create-mission.fields.end-date.hint'),
                }}
                shouldValidate={formState.errors.endDate ? true : false}
                valid={formState.errors.endDate?.type === 'validate' ? true : false}
                validation={{
                  msg: formState.errors.endDate?.message,
                  id: 'form-error-end-date',
                  type: 'error',
                }}
              >
                <DatePicker
                  id="endDate"
                  data-testid="input-field-endDate"
                  label={t('features.mission.create-mission.fields.end-date.title')}
                  {...field}
                  minDate={startedDate || undefined}
                />
              </FormField>
            )}
          />
        </Grid.Col>
      </Grid>
    </>
  )
}

export default DurationStep
