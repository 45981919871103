import Table from '@ingka/table'
import { space50, space75 } from '@ingka/variables'

import { backgroundColourDefault } from 'styles/tokens/insikt.tokens'
import { colourSemanticCaution } from 'styles/tokens/oldSkapa.tokens'

import styled from 'styled-components'

// Work around to remove the border bottom color that comes with Skapa Tables
export const CustomTable = styled(Table)`
  tbody tr {
    &:hover {
      background-color: ${backgroundColourDefault};
    }
    td {
      padding-top: ${space75};
      padding-bottom: ${space50};
      border-bottom: 0 rgb(var(--colour-neutral-3, 223, 223, 223)) solid;
      vertical-align: middle;
      font-size: 0.95rem;
    }
    td:first-child {
      /* background-color: ${colourSemanticCaution}; //todo: remove color */
    }
  }
  .form-field {
    margin-bottom: 0.5rem;
  }
  .radio {
    align-items: center;
  }
`
