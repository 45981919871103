import Text from '@ingka/text'
import { Box } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { QaMetricId } from 'config/domain/qa.config'

import { spacing25 } from 'styles/tokens/insikt.tokens'

import { missionFocusStyle, transKey } from '../../MissionPreview.constants'
import { useQuestionTitle } from './MissionFocus.hooks'

export type Props = {
  qaId: QaMetricId
}

export const MissionFocus = ({ qaId }: Props) => {
  const { t } = useTranslation()
  const { title } = useQuestionTitle(qaId)
  return (
    <Box style={missionFocusStyle}>
      <Text style={{ padding: spacing25 }}>
        {t(`${transKey}.focus`)} <b>{' ' + title}</b>
      </Text>
    </Box>
  )
}
