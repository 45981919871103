import { space300, space550 } from '@ingka/variables'
import { Box, Flex } from '@mantine/core'
import { Helmet } from 'react-helmet-async'
import { Outlet } from 'react-router-dom'

import { NewVersionToast } from 'components/features/General/NewVersionToast'
import { WelcomeModal } from 'components/features/General/WelcomeModal'
import { EasterEggs } from 'components/shared/EasterEggs'
import { ErrorBoundary } from 'components/shared/ErrorBoundary'

import { Header } from '../Header'
import { useBackgroundColor } from './MainLayout.hooks'

const DefaultPageLayout: React.FC = () => {
  const bgColor = useBackgroundColor()
  return (
    <>
      <Box w="100%" bg={bgColor} mih="100vh">
        <Helmet>
          <title>{'Insikt'}</title>
        </Helmet>
        <EasterEggs />
        {/* <EnvironmentStickyHeader /> */}
        <Header />
        <WelcomeModal />
        <NewVersionToast />
        <Flex align={'center'} justify={'center'}>
          <Box component="main" px={space300} pb={space550} maw={'1600px'} w={'100%'}>
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </Box>
        </Flex>
      </Box>
    </>
  )
}

export default DefaultPageLayout
