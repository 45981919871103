export const scopeBase: string[] = [
  'https://analysis.windows.net/powerbi/api/Report.Read.All',
]

export const powerBiApiUrl: string = 'https://api.powerbi.com/'

export function generateMsalConfig() {
  const msalConfig = {
    auth: {
      clientId: '085fffb4-73d2-492a-8d12-d2bf4d120e64',
      authority:
        'https://login.microsoftonline.com/720b637a-655a-40cf-816a-f22f40755c2c',
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: 'sessionStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  }

  return msalConfig
}
