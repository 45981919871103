import Button from '@ingka/button'
import { LoadingBall } from '@ingka/loading'
import pencil from '@ingka/ssr-icon/paths/pencil'
import { space50, space75 } from '@ingka/variables'
import { Flex, Stack } from '@mantine/core'
import { t } from 'i18next'

import { CoachingSession } from 'lib/types/coachingSession.types'
import { Coworker } from 'lib/types/coworker.types'

import { useUpdateSessionComment } from 'state/slices/api'

import { Widget } from 'components/composites/Shared/Widget'
import { Label, SubHeading } from 'components/primitives/Text'
import RTFTextEditor from 'components/shared/RTFTextEditor/RTFTextEditor.component'
import { useTextEditor } from 'components/shared/RTFTextEditor/useTextEditor.hooks'

type SessionCommentEditorProps = {
  coworker: Coworker
  coachingSession: CoachingSession
}

const SessionCommentEditor: React.FC<SessionCommentEditorProps> = ({
  coworker,
  coachingSession,
}) => {
  const initialSessionComment = coachingSession.sessionComment || ''
  const placeholderText = t('features.coaching-session.session-comment.placeholder')
  const { editor, isEditable, setIsEditable, value } = useTextEditor(
    initialSessionComment,
    placeholderText
  )

  const [updateSessionComment, { isLoading: isLoadingUpdateSessionComment }] =
    useUpdateSessionComment()

  if (!editor) return null

  const toggleEditability = () => {
    setIsEditable((prev) => !prev)
    editor.setOptions({ editable: !isEditable })
  }

  const handleSaveChanges = () => {
    updateSessionComment({
      coworkerId: coworker.legacyId,
      coachingSessionId: coachingSession.id,
      sessionComment: value,
    })
    console.log('Session comment saved')
    toggleEditability()
  }

  return (
    <>
      <Widget>
        <Stack gap={space50} px={space75}>
          <Flex justify="space-between" align="center" wrap="wrap">
            <SubHeading>{t('features.coaching-session.session-comment.title')}</SubHeading>
            {/* Todo: Extend this into an info modal for the RTF editor like the one in MissionQaMetricsInfo */}
            {/* <SSRIcon paths={info} /> */}
          </Flex>
          <Label>{t('features.coaching-session.session-comment.description')}</Label>
          <Stack gap={space50}>
            {isLoadingUpdateSessionComment ? (
              <LoadingBall style={{ margin: 'auto' }} />
            ) : (
              <RTFTextEditor
                editor={editor}
                backgroundColor
                includesCheckboxes={false}
                className="session-comment-editor"
              />
            )}
          </Stack>
          <Stack gap={space50} align="end">
            {isEditable ? (
              <Button
                small
                type="primary"
                onClick={handleSaveChanges}
                loading={isLoadingUpdateSessionComment}
              >
                {t('common.actions.save-changes')}
              </Button>
            ) : (
              <Button small type="primary" onClick={toggleEditability} ssrIcon={pencil}>
                {t('common.actions.edit-comment')}
              </Button>
            )}
          </Stack>
        </Stack>
      </Widget>
    </>
  )
}

export default SessionCommentEditor
