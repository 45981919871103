import checkmarkBox from '@ingka/ssr-icon/paths/checkmark-box'
import people from '@ingka/ssr-icon/paths/people'
import { Accordion, Flex, Stack } from '@mantine/core'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { CoachingSession } from 'lib/types/coachingSession.types'

import { useCompletedCoachingSessions, useMissionsForCoachingSession } from 'hooks/coachingSessions'
import { useCoworkerId } from 'hooks/id/useCoworkerId.hooks'

import { colourGreyNeutral1, spacing50, spacing100 } from 'styles/tokens/insikt.tokens'

import { EmptyContent } from 'components/composites/Shared/EmptyContent'
import { GridLayout } from 'components/composites/Shared/GridLayout/GridLayout.component'
import { TabLayout } from 'components/composites/Shared/TabLayout/TabLayout.component'
import {
  CoworkerCoachingSessionTabKeys,
  TabDefinitions,
} from 'components/composites/Shared/TabLayout/TabLayout.types'
import { MissionsOverview } from 'components/features/CoworkerMVP/CoachingSession/MissionsOverview/MissionsOverview.component'
import FormattedCoworkerNote from 'components/features/CoworkerMVP/CoworkerCreateNote/FormattedCoworkerNote.component'
import { MissionPreviewList } from 'components/features/Missions/MissionPreviewList'
import { Paragraph, SubHeading, TitleBlue } from 'components/primitives/Text'
import { SubTitleBlue } from 'components/primitives/Text/Text.component'

import { SessionComment } from '../CoachingSessionOverview/SessionComment'

export const CoworkerCompletedCoachingSessions = () => {
  const coworkerId = useCoworkerId()
  const { data: coachingSessions = [] } = useCompletedCoachingSessions(coworkerId)

  const tabDefinitions: TabDefinitions = [
    {
      key: CoworkerCoachingSessionTabKeys.CoachingSession,
      tKeyForTitle: 'pages.manage-coworker.tabs.coaching-sessions.title',
      content: <CoachingSessionTab coachingSessions={coachingSessions} />,
      icon: people,
    },
    {
      key: CoworkerCoachingSessionTabKeys.Mission,
      tKeyForTitle: 'pages.manage-coworker.tabs.missions.title',
      content: <MissionPreviewList />,
      icon: checkmarkBox,
    },
  ]

  return (
    <>
      <GridLayout
        gridStyles={{ marginTop: `0` }}
        noDivider
        children={[
          {
            element: <CoachingSessionsTitle />,
          },
          { element: <TabLayout tabDefinitions={tabDefinitions} /> },
        ]}
      />
    </>
  )
}

interface CSTabProps {
  coachingSessions: CoachingSession[]
}

const CoachingSessionTab: FC<CSTabProps> = ({ coachingSessions }) => {
  if (coachingSessions.length === 0) {
    return (
      <EmptyContent translationKey="features.coaching-session.warnings.no-completed-coaching-sessions" />
    )
  }

  return (
    <Accordion
      key={'coaching sessions'}
      defaultValue={'coachingSession 0'}
      className="accordion-coaching-sessions"
    >
      {coachingSessions.map((coachingSession, index, arr) => {
        return (
          <CompletedCoachingSessionAccordion
            key={coachingSession.id}
            coachingSession={coachingSession}
            arr={arr}
            index={index}
          />
        )
      })}
    </Accordion>
  )
}

const CoachingSessionsTitle: React.FC = () => {
  const { t } = useTranslation()
  return (
    // Todo: Do we keep it tho it's removed from the design?
    <Stack gap={0} py={spacing50}>
      <TitleBlue>{t('features.coaching-session.completed-coaching-sessions.title')}</TitleBlue>
      <Paragraph>{t('features.coaching-session.completed-coaching-sessions.subtitle')}</Paragraph>
    </Stack>
  )
}

type Props = {
  coachingSession: CoachingSession
  index: number
  arr: CoachingSession[]
}

const CompletedCoachingSessionAccordion: React.FC<Props> = ({ coachingSession, index, arr }) => {
  const coworkerId = useCoworkerId()
  const { t } = useTranslation()
  /** Get missions relevant to the coaching session cycle */
  const { data: relatedMissions = [], isLoading } = useMissionsForCoachingSession({
    coworkerId,
    coachingSessionId: coachingSession?.id,
  })

  return (
    <>
      <Accordion.Item
        key={coachingSession.id}
        value={`coachingSession ${index}`}
        id={`coachingSession ${index}`}
        mt={spacing100}
      >
        <Accordion.Control p={`${spacing50} ${spacing100}`} bg={colourGreyNeutral1}>
          <Flex>
            <SubHeading>{`Coaching session ${arr.length - index}`}</SubHeading>
          </Flex>
        </Accordion.Control>
        <Accordion.Panel mt={spacing50}>
          {coachingSession && (
            <>
              <MissionsOverview missions={relatedMissions} isLoading={isLoading} />
              {/* Session comment and coworker note from the session */}
              <SubTitleBlue>
                {t('features.coaching-session.completed-coaching-sessions.summary.title')}
              </SubTitleBlue>
              <FormattedCoworkerNote coachingSession={coachingSession} />
              <SessionComment comment={coachingSession?.sessionComment ?? ''} />
            </>
          )}
        </Accordion.Panel>
      </Accordion.Item>
    </>
  )
}
