import Button from '@ingka/button'
import { space100 } from '@ingka/variables'
import { useTranslation } from 'react-i18next'

export interface ButtonDefinition {
  key: any
  translationKey: string
}

type Props = {
  options: ButtonDefinition[]
  selected: any
  setSelected: (selection: any) => void
}

const ToggleButtons: React.FC<Props> = ({ options, selected, setSelected }) => {
  const { t } = useTranslation()

  const handleSelected = (selection: any) => {
    setSelected(selection.key)
  }

  return (
    <>
      {options.map((button, id) => {
        return (
          <Button
            key={id}
            aria-pressed="true"
            small
            type={selected === button.key ? 'image-overlay' : 'secondary'}
            aria-activedescendant={selected}
            className={selected === button.key ? 'selected' : ''}
            text={t(button.translationKey)}
            onClick={() => handleSelected(button)}
            style={{
              marginRight: space100,
            }}
          />
        )
      })}
    </>
  )
}

export default ToggleButtons
