import { Page, VisualDescriptor } from 'powerbi-client'
import { ISlicerFilter } from 'powerbi-models'

import { PowerBiResponse } from 'state/slices/api/modules/power-bi'

import {
  BASE_SLICER_CONFIG,
  COWORKER_SLICER_TITLE,
  TEAM_SLICER_TITLE,
} from './EmbedReport.constants'
import { PowerBiReportMeta, ReportNavigationMap, SlicerState } from './EmbedReport.types'

export const cleanString = (title: string) => {
  return title.toLowerCase().trim().replace(' ', '-')
}

export const transformToMap = (data: PowerBiResponse[]): ReportNavigationMap =>
  data.reduce((map, obj) => {
    map.set(obj.name.toLowerCase(), {
      url: obj.url,
      initialPage: obj.initialPage,
      displayName: obj.displayName,
    })
    return map
  }, new Map<string, PowerBiReportMeta>())

const setSlicer = async (values: string[], visual: VisualDescriptor) => {
  const state = await visual.getSlicerState()
  const target = state.targets?.at(0)
  if (target) {
    await visual.setSlicerState({
      filters: [
        {
          ...BASE_SLICER_CONFIG,
          operator: 'In',
          target: target,
          values: values,
        },
      ],
    })
  }
}
// TODO: Discuss with Fredrik and Dan how we handle errors - soft or hard?
export const setInsiktCoworker = async (
  page: Page,
  teamName: string | undefined,
  coworkerId: string | undefined
) => {
  const slicers = await page.getSlicers()
  for (const slicer of slicers) {
    const cleanedTitle = cleanString(slicer.title)
    if (cleanedTitle === TEAM_SLICER_TITLE) {
      const visual = await page.getVisualByName(slicer.name)
      if (!teamName) {
        console.error('Cannot set the team name')
        continue
      }
      await setSlicer([teamName], visual)
    }

    if (cleanedTitle === COWORKER_SLICER_TITLE) {
      const visual = await page.getVisualByName(slicer.name)
      if (!coworkerId) {
        console.error('Cannot set the coworker ID')
        continue
      }
      await setSlicer([coworkerId], visual)
    }
  }
}

export const setSlicersFromPrevious = async (page: Page, previousState: SlicerState[]) => {
  if (previousState.length === 0) {
    return
  }

  const slicers = await page.getSlicers()
  const slicerTitleSet = new Set(slicers.map((slicer) => slicer.title))

  const setSlicersPromises = previousState.map(async ({ name, state }) => {
    if (name === COWORKER_SLICER_TITLE || name === TEAM_SLICER_TITLE) return
    if (!slicerTitleSet.has(name)) return

    const prevState = state.filters[0]
    if (!prevState) return

    const targetSlicer = slicers.filter((slicer) => slicer.title === name)[0]
    const visual = await page.getVisualByName(targetSlicer.name)
    const target = (await visual.getSlicerState()).targets?.at(0)
    if (!target) return

    return await visual.setSlicerState({
      filters: [
        {
          ...state.filters[0],
          target,
        } as ISlicerFilter,
      ],
    })
  })

  await Promise.all(setSlicersPromises)
}
