import { useEffect } from 'react'

import { Mission, MissionStatus } from 'lib/types/mission.types'
import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'
import { CustomViewKeys } from 'lib/utils/analytics/analytics.types'

type FilterMissionsByStatus = {
  missions: Mission[]
  selected: string
}

export const useFilterMissionsByStatus = (missions: Mission[], selected: string) => {
  const filterMissionsByStatus = ({ missions, selected }: FilterMissionsByStatus) => {
    if (selected === 'all') return missions.filter((mission) => mission.status !== 'draft') // Should we include drafts under 'All' list?
    if (selected === 'active') return missions.filter((mission) => mission.status === 'active')
    if (selected === 'completed')
      // Work around missions that have old status aka. 'closed' and not completed
      return missions.filter((mission) => mission.status === 'completed')
    return missions.filter((mission) => mission.status === selected)
  }

  return filterMissionsByStatus({ missions, selected })
}

export const useAnalytics = (
  disableFilter: boolean,
  selected: MissionStatus,
  coworkerId: string | undefined
) => {
  useEffect(() => {
    if (disableFilter) return
    if (!coworkerId) return
    switch (selected) {
      case MissionStatus.Active:
        analyticsHelper.createCustomViewEvent({
          key: CustomViewKeys.MissionsActive,
          description: 'Viewing active missions',
          coworkerId: coworkerId,
        })
        break
      case MissionStatus.Completed:
        analyticsHelper.createCustomViewEvent({
          key: CustomViewKeys.MissionsCompleted,
          description: 'Viewing completed missions',
          coworkerId: coworkerId,
        })
        break
      default:
        console.log('No analytics event for this filter: ', selected)
    }
  }, [selected, coworkerId, disableFilter])
}
