import { space100, thicknessThick } from '@ingka/variables'

import { colourPrimary } from 'styles/tokens/insikt.tokens'
import { colourBorderLight } from 'styles/tokens/oldSkapa.tokens'

import styled from 'styled-components'

import { DividerProps } from './Divider.types'

const VerticalDivider = styled.div<{ color: string; height?: string }>`
  border-inline-start: ${thicknessThick} solid ${(props) => props.color};
  margin-left: ${space100};
  margin-right: ${space100};
  height: ${(props) => (props.height ? props.height : 'unset')};
`

const Divider: React.FC<DividerProps> = ({ direction, type = 'primary', height }) => {
  const color = type === 'primary' ? colourPrimary.main : colourBorderLight
  return direction === 'vertical' ? (
    <VerticalDivider color={color} height={height} />
  ) : (
    <hr color={color} />
  )
}

export default Divider
