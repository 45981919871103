import Loading, { LoadingLinear } from '@ingka/loading'
import { useTranslation } from 'react-i18next'

import { Widget } from 'components/composites/Shared/Widget'

import { centeredFlex, loadingWidgetStyle } from './LoadingBar.style'

interface Props {
  labelTranslationKey?: string
  maxStep?: number
  currentStep: number
}

const STEP_MULTIPLIER = 10
const DEFAULT_MAX_STEP = 10
// Review Note: Do we need to check for negative stuff? Maybe SKAPA handles that.
// Do we need to force the numbers to be ints?
const LoadingBar: React.FC<Props> = ({
  currentStep,
  labelTranslationKey = 'common.loading',
  maxStep = DEFAULT_MAX_STEP,
}) => {
  const { t } = useTranslation()
  const label = t(labelTranslationKey)

  if (maxStep < 0) {
    if (process.env.REACT_APP_ENV === 'dev') {
      throw new Error('Cannot create a loading bar with a negative max step.')
    }
    maxStep = DEFAULT_MAX_STEP
  }

  const max = maxStep * STEP_MULTIPLIER

  return (
    <div style={centeredFlex}>
      <Widget style={loadingWidgetStyle}>
        <Loading labelTransitions text={label}>
          <LoadingLinear
            // No negative values
            loadingValue={Math.max(0, currentStep * STEP_MULTIPLIER)}
            style={{ minWidth: '45vw' }}
            loadingMax={max}
          />
        </Loading>
      </Widget>
    </div>
  )
}

export default LoadingBar
