import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import i18next from 'i18next'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAuthUser, useUser } from 'hooks/users'

import { invalidateTags, useUserPrefsQuery } from 'state/slices/api'
import { useAppDispatch } from 'state/utilities'

/**
 * Retrieves basic app state (auth user and user profile) and does checks for
 * whether the user can use the app.
 */
export const useInitData = (): {
  isLoading: boolean
  loadingMessage?: string
  error?: any
} => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const authUser = useAuthUser()

  const {
    data: user,
    error: fetchUserError,
    isSuccess: isUserFulfilled,
    isLoading: isUserLoading,
    isUninitialized: isUserUninitialized,
  } = useUser()
  const { data: prefs } = useUserPrefsQuery()

  const isLoading = isUserLoading || isUserUninitialized

  // If the user query is successful and the user does not exist or their profile says that
  // it is not ready, we wait 5s (magic number), then retry
  const isUserBeingCreated =
    (authUser != null && isUserFulfilled && user == null) ||
    prefs?.introduction.isProfileReady === false

  useEffect(() => {
    if (isUserBeingCreated) {
      setTimeout(() => {
        dispatch(invalidateTags(['User']))
      }, 5000)
    }
  }, [dispatch, isUserBeingCreated])

  let loadingMessage: string | undefined = undefined

  if (authUser == null) {
    loadingMessage = t('loading-messages.signing-in')
  }
  if (isUserLoading) {
    loadingMessage = t('loading-messages.fetching-user-profile')
  }
  if (isUserBeingCreated) {
    loadingMessage = t('loading-messages.creating-user-profile')
  }

  useEffect(() => {
    i18next.changeLanguage(prefs?.locale || i18next.resolvedLanguage)
  }, [prefs?.locale])

  return {
    isLoading,
    loadingMessage,
    error: fetchUserError,
  }
}
