import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useValidateParams } from 'hooks/navigation/useValidateParams'

import { CoachingSessionOverview } from 'components/features/CoachingSessions/CoachingSessionOverview'
import { PageHeader } from 'components/layout/PageHeader'
import { RedirectingLayout } from 'components/layout/RedirectingLayout'

import { useBackHref } from './CoachingSessionOverviewPage.hooks'

const CoachingSessionOverviewPage: React.FC = () => {
  const { t } = useTranslation()
  const { coworkerId, coachingSessionId } = useParams()
  // TODO: Inspect how this ties into the new coworker overview
  const backHref = useBackHref()

  useValidateParams(['coworkerId', 'coachingSessionId'])
  if (!coworkerId || !coachingSessionId) return <RedirectingLayout />

  return (
    <div data-testid="page-coaching-session-overview">
      <PageHeader backHref={backHref} title={t('features.coaching-session.title')} />
      <CoachingSessionOverview coworkerId={coworkerId!} coachingSessionId={coachingSessionId!} />
    </div>
  )
}

export default CoachingSessionOverviewPage
