import { Box } from '@mantine/core'
import { useState } from 'react'

import { spacing100 } from 'styles/tokens/insikt.tokens'

import { LazyLoader } from 'components/composites/Shared/LazyLoader'
import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import ToggleButtons, {
  ButtonDefinition,
} from 'components/composites/Shared/ToggleButtons/ToggleButtons.component'
import { EmbedReport } from 'components/features/EmbedReport'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { useTeamNameByTlId } from './TeamSalesPage.hooks'

const enum FilterOptions {
  Overview = 'overview',
  Comparison = 'comparison',
}

const buttons: ButtonDefinition[] = [
  {
    key: FilterOptions.Overview,
    translationKey: 'features.teamlead.team-sales.overview',
  },
  {
    key: FilterOptions.Comparison,
    translationKey: 'features.teamlead.team-sales.comparison',
  },
]

const pageTitle = 'Team Sales Page'

export const TeamSalesPage = () => {
  const [selected, setSelected] = useState(FilterOptions.Overview)
  const { teamData, isSuccess, isError } = useTeamNameByTlId()

  if (isError) return <UnexpectedErrorMessage />

  // Using isSuccess here as the isLoading is initially false, since we need to get coworker Info first
  if (!isSuccess) return <LoadingIndicator />

  return (
    <>
      <Box
        style={{
          marginTop: spacing100,
          marginBottom: spacing100,
        }}
      >
        <ToggleButtons options={buttons} setSelected={setSelected} selected={selected} />
      </Box>
      {/* TODO: This would work better with the implementation and lazy loading that we have for Tabs,
 so we would have state persinstance and such. Discuss this with Pablo whether we should use the toggle buttons or not
 As it might be interesting there to mimic this behaviour just with a different header structure? */}
      <LazyLoader visible={selected === FilterOptions.Overview}>
        <EmbedReport
          reportName={`team-overview`}
          pageTitle={pageTitle}
          teamNameProp={teamData?.name}
        />
      </LazyLoader>
      <LazyLoader visible={selected === FilterOptions.Comparison}>
        <EmbedReport
          reportName={`team-comparison`}
          pageTitle={pageTitle}
          teamNameProp={teamData?.name}
        />
      </LazyLoader>
    </>
  )
}
