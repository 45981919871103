import { Route } from 'react-router-dom'

import { UsersPage } from 'components/pages/admin'

import CoworkerRoutes from './CoworkerRoutes.component'
import TeamleadRoutes from './TeamleadRoutes.component'

/**
 * Be aware that this is *not* a ReactNode but rather a list of JSX.element
 * The motivation for this is that react-router only allows <Route/> as child elements.
 */
const QsRoutes = (
  <Route path="qs">
    <Route path="users" element={<UsersPage demoSearch={true} />} />

    {CoworkerRoutes}
    {TeamleadRoutes}
  </Route>
)

export default QsRoutes
