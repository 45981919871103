import { SkipToken, skipToken } from '@reduxjs/toolkit/dist/query'
import dayjs from 'dayjs'

import { CoachingSessionStatus } from 'lib/types/coachingSession.types'
import { Mission, MissionStatus } from 'lib/types/mission.types'

import { useCoachingSession } from 'state/slices/api'
import { useMissions } from 'state/slices/api/modules/mission'

export const useMissionsForCoachingSession = (
  args: { coworkerId: string; coachingSessionId: string } | SkipToken
) => {
  const { data: coachingSession, ...metaCoachingSession } = useCoachingSession(args)

  const { data: missions, ...metaInteractions } = useMissions(
    args === skipToken ? skipToken : args.coworkerId
  )

  let relatedMissions: Mission[] = []
  let completedMissionIds: string[] = []
  let newMissionIds: string[] = []

  if (coachingSession?.status === CoachingSessionStatus.Planned) {
    // If the coaching session is planned, return all drafts and active missions
    relatedMissions =
      missions?.filter(
        (mission) =>
          mission.status === MissionStatus.Draft || mission.status === MissionStatus.Active
      ) ?? []
  } else if (
    coachingSession?.status === CoachingSessionStatus.Completed ||
    coachingSession?.status === CoachingSessionStatus.Signed
  ) {
    // If the coaching session is completed or signed, return all missions that were started or completed on completion date of the coaching session
    relatedMissions =
      missions?.filter((mission) => {
        if (
          mission.startedDate &&
          dayjs(mission.startedDate).isSame(coachingSession.completedDate, 'day')
        ) {
          newMissionIds.push(mission.id)
          return true
        } else if (
          mission.completedDate &&
          dayjs(mission.completedDate).isSame(coachingSession.completedDate, 'day')
        ) {
          completedMissionIds.push(mission.id)
          return true
        } else {
          return false
        }
      }) ?? []
  }

  const meta = metaCoachingSession.isSuccess ? metaInteractions : metaCoachingSession

  return {
    ...meta,
    data: relatedMissions,
    completedMissionIds,
    newMissionIds,
    currentData: relatedMissions,
  }
}
