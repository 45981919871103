import {
  BrandColour,
  colourGreyNeutral2,
  lightBorderColor,
  radius25,
  radius50,
  spacing25,
  spacing50,
  spacing75,
} from 'styles/tokens/insikt.tokens'
import { colourNeutralGrey100 } from 'styles/tokens/oldSkapa.tokens'

export const transKey = 'features.coaching-session.coworker.missions'

export const missionCardBody = {
  backgroundColor: colourNeutralGrey100,
  minHeight: '20vh',
  marginTop: spacing75,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: spacing75,
  borderRadius: radius50,
}

export const missionFocusStyle = {
  margin: 'auto',
  marginTop: spacing50,
  backgroundColor: colourGreyNeutral2,
  borderRadius: radius25,
  padding: spacing25,
}

export const metricBannerStyle = {
  marginTop: spacing50,
  borderRadius: radius25,
  border: '1px dashed',
  borderColor: lightBorderColor,
}

export const connectedMetricTextboxStyle = {
  fontWeight: 'bold',
  backgroundColor: BrandColour.Yellow,
  margin: spacing25,
  padding: `${spacing50} ${spacing25} ${spacing50} ${spacing25}`,
  borderRadius: radius25,
}
