import chat from '@ingka/ssr-icon/paths/chat'
import facebook from '@ingka/ssr-icon/paths/facebook'
import mail from '@ingka/ssr-icon/paths/mail'
import phone from '@ingka/ssr-icon/paths/phone'
import twitter from '@ingka/ssr-icon/paths/twitter'

import { SkapaIconPaths } from 'lib/consts'
import { CustomerComment } from 'lib/types/customerComment.types'

export type CustomerCommentMediaDef = {
  key?: number
  text: string
  icon?: SkapaIconPaths
}

export const customerCommentMedia: CustomerCommentMediaDef[] = [
  {
    key: 1,
    text: 'Voice',
    icon: phone,
  },
  {
    key: 2,
    text: 'Email',
    icon: mail,
  },
  {
    key: 3,
    text: 'Chat',
    icon: chat,
  },
  {
    key: 4,
    text: 'Callback',
    icon: phone,
  },
  {
    key: 1001,
    text: 'Twitter',
    icon: twitter,
  },
  {
    key: 1026,
    text: 'Twitter Direct',
    icon: twitter,
  },
  {
    key: 1027,
    text: 'Facebook',
    icon: facebook,
  },
  {
    key: 1028,
    text: 'Facebook Session',
    icon: facebook,
  },
  {
    key: -1,
    text: 'Unknown media type',
    icon: undefined,
  },
]

export const useCustomerCommentInfoGroup = (customerComment: CustomerComment) => {
  const commentMediaType = customerCommentMedia.find(
    (media) => media.text === customerComment.mediaDesc
  )
  const commentInteractionDate = customerComment.date
  const commentStatus = customerComment.status

  return {
    commentMediaType,
    commentInteractionDate,
    commentStatus,
  }
}

export enum CustomerCommentsFilterKeys {
  AllComments = 'customer-comments-all',
  ForwardedComments = 'customer-comments-forwarded',
}

export const customerCommentsFilterMenu = [
  {
    key: CustomerCommentsFilterKeys.AllComments,
    tKeyForTitle: 'features.customer-comments.tabs.all-comments',
  },
  {
    key: CustomerCommentsFilterKeys.ForwardedComments,
    tKeyForTitle: 'features.customer-comments.tabs.forwarded-comments',
  },
]
