import { UsersOnboardingOverview } from 'components/features/Users/UsersOnboarding'
import { PageHeader } from 'components/layout/PageHeader'

const ManageUsersOnboardingPage = () => (
  <div data-testid="page-manage-user-onboarding">
    <PageHeader title="Manage User Onboarding" backHref="/admin" />
    <UsersOnboardingOverview />
  </div>
)

export default ManageUsersOnboardingPage
