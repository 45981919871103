import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { CoworkerCoachingStatus } from 'lib/types/coworker.types'
import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'
import { CustomOtherEventKeys } from 'lib/utils/analytics/analytics.types'

import { useCoworkerStatus } from 'hooks/coworkers/useCoworkerStatus.hooks'
import { useTeamleadId } from 'hooks/id/useTeamleadId.hooks'

import {
  colourNeutralGrey300,
  colourSemanticCaution,
  colourSemanticNegative,
  colourSemanticPositive,
} from 'styles/tokens/oldSkapa.tokens'

import { StatusIndicator } from 'components/composites/Shared/StatusIndicator'

interface Props {
  coworkerId: string
}

const CoworkersOverviewStatus: React.FC<Props> = ({ coworkerId }) => {
  const { t } = useTranslation()
  const teamleadId = useTeamleadId()

  const coworkerStatus = useCoworkerStatus(coworkerId)

  useEffect(() => {
    if (!coworkerId || !teamleadId) return
    analyticsHelper.createCustomOtherEvent(CustomOtherEventKeys.CurrentStatus, {
      status: coworkerStatus.status,
      coworkerId,
      teamleadId,
    })
  }, [coworkerId, teamleadId, coworkerStatus])

  let color, label

  switch (coworkerStatus.status) {
    case CoworkerCoachingStatus.Plan:
      label = t('features.coworker.coworkers-overview.upcoming-coaching-session-status.plan')
      color = colourSemanticNegative
      break

    case CoworkerCoachingStatus.Assess:
      label = t('features.coworker.coworkers-overview.upcoming-coaching-session-status.assess', {
        done: coworkerStatus.assessmentsCompleted,
        needed: coworkerStatus.assessmentsNeeded,
      })
      color = colourSemanticCaution
      break

    case CoworkerCoachingStatus.Prepare:
      label = t('features.coworker.coworkers-overview.upcoming-coaching-session-status.prepare')
      color = colourSemanticCaution
      break

    case CoworkerCoachingStatus.Prepared:
      label = t('features.coworker.coworkers-overview.upcoming-coaching-session-status.no-action')
      color = colourSemanticPositive
      break

    case CoworkerCoachingStatus.Coach:
      label = t('features.coworker.coworkers-overview.upcoming-coaching-session-status.coach')
      color = colourSemanticCaution
      break

    case CoworkerCoachingStatus.NoAction:
      label = t('features.coworker.coworkers-overview.upcoming-coaching-session-status.no-action')
      color = colourSemanticPositive
      break

    default:
      label = 'N/A'
      color = colourNeutralGrey300
      break
  }

  return (
    <>
      <StatusIndicator label={label} color={color} />
    </>
  )
}

export default CoworkersOverviewStatus
