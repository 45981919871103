import { SkipToken, skipToken } from '@reduxjs/toolkit/dist/query'
import dayjs from 'dayjs'

import { CoachingSessionStatus } from 'lib/types/coachingSession.types'

import { useCoachingSessions } from 'state/slices/api/modules/coachingSession/coachingSession.slice'

/** Fetches the latest completed coaching session and all completed sessions for a co-worker with the given ID */
export const useLastCompletedCoachingSession = (coworkerId: string | SkipToken) => {
  const { data: coachingSessions, ...meta } = useCoachingSessions(coworkerId)

  return {
    data: coachingSessions
      ?.filter((coachingSession) => coachingSession.status === CoachingSessionStatus.Completed)
      .sort((a, b) => dayjs(b.completedDate).unix() - dayjs(a.completedDate).unix())[0],
    ...meta,
  }
}

export const useCompletedCoachingSessions = (coworkerId: string | SkipToken) => {
  const { data: coachingSessions, ...meta } = useCoachingSessions(coworkerId ?? skipToken)
  const completedCoachingSessions = coachingSessions
    ?.filter(
      (coachingSession) =>
        coachingSession.status === CoachingSessionStatus.Completed ||
        coachingSession.status === CoachingSessionStatus.Signed
    )
    .sort((a, b) => dayjs(b.completedDate).unix() - dayjs(a.completedDate).unix())
  return { data: completedCoachingSessions, ...meta }
}
