import 'styles'
import 'config/charts/charts.config'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { MantineProvider } from '@mantine/core'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { App } from 'app'

import { initTranslations } from 'config/internationalisation/i18next.config'
import { generateMsalConfig } from 'config/mirosoft/azureAd.config'

import { store } from 'state/store'

import { mantineTheme } from 'styles/mantine.theme'

const msalInstanceProvider = new PublicClientApplication(generateMsalConfig())

const renderApp = async () => {
  const container = document.getElementById('root') as HTMLElement
  const root = createRoot(container)

  // Before we render the application content, we want to ensure that all translations
  // have been initialised to prevent the app from rendering translations keys
  await initTranslations()
  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstanceProvider}>
        <Provider store={store}>
          <MantineProvider theme={mantineTheme} withGlobalClasses>
            <HelmetProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </HelmetProvider>
          </MantineProvider>
        </Provider>
      </MsalProvider>
      ,
    </React.StrictMode>
  )
}

renderApp()
