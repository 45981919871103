import { GameEvent, GameEventTypes } from 'lib/types/game.types'

export const xpAmountForLevelUp = (level: number): number => {
  // Returns the required amount of xp to go from level to level + 1
  return level * 100
}

export const getTotalXpAmountForLevel = (level: number): number => {
  let xpRequired = 0
  for (let i = 1; i < level; i++) {
    xpRequired = xpRequired + xpAmountForLevelUp(i)
  }
  return xpRequired
}

export const getLevel = (xp: number): number => {
  for (let i = 1; i < 100; i++) {
    const xpRequired = getTotalXpAmountForLevel(i + 1)

    if (xp < xpRequired) {
      return i
    }
  }
  // Return NaN if level function can't find level. Should only happen if someone passes the level 100 mark.
  return 1
}

export const getEventText = (event: GameEvent): string => {
  switch (event.type) {
    case GameEventTypes.CompleteMission:
      return `You completed a mission!`
  }
  return ''
}

export const getEventTitle = (type: GameEventTypes): string => {
  switch (type) {
    case GameEventTypes.CompleteMission:
      return `features.game.game-info.event-type-headers.mission-completed`
  }
  return ''
}
