import { Outlet, Route } from 'react-router-dom'

import { CoworkerLayout } from 'components/layout'
import { CoworkerPage } from 'components/pages/coworker'
import { CoachingSessionsPage } from 'components/pages/shared'

/**
 * Be aware that this is *not* a ReactNode but rather a list of JSX.element
 * The motivation for this is that react-router only allows <Route/> as child elements.
 */
const CoworkerRoutes = (
  <Route path="cw/:coworkerId" element={<CoworkerLayout />}>
    <Route path="" element={<CoworkerPage />} />
    <Route path="coaching-sessions" element={<Outlet />}>
      <Route path="" element={<CoachingSessionsPage />} />
    </Route>
  </Route>
)

export default CoworkerRoutes
