import { Flex } from '@mantine/core'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { useFormatDate } from 'hooks/formatting'

import { Heading, Paragraph } from 'components/primitives/Text'

interface Props {
  title: string
  qaId: string
  missionEnd?: string
  missionStarted?: string
  missionCompleted?: string
}

const MissionOverviewTitle = ({
  title,
  qaId,
  missionEnd,
  missionCompleted,
  missionStarted,
}: Props) => {
  const { t } = useTranslation()
  const formatDate = useFormatDate()

  return (
    <Flex justify={'space-between'} w={'100%'}>
      <Heading>{title}</Heading>

      <div>
        <Paragraph $bold>{t(`qa.${qaId}` as string)}</Paragraph>
        {missionEnd && (
          <Paragraph>
            {t('features.mission.preview.mission-planned', {
              date: formatDate(new Date(missionEnd)),
            })}
          </Paragraph>
        )}
        {missionStarted && (
          <>
            <Paragraph>
              {t('features.mission.preview.mission-started', {
                date: formatDate(new Date(missionStarted)),
              })}
            </Paragraph>
            <Paragraph>
              {dayjs().isBefore(missionEnd)
                ? t('features.mission.preview.days-left', {
                    date: dayjs(missionEnd).diff(dayjs(), 'days'),
                  })
                : t('features.mission.preview.days-overdue', {
                    date: dayjs().diff(missionEnd, 'days'),
                  })}
            </Paragraph>
          </>
        )}
        {missionCompleted && (
          <Paragraph>
            {t('features.mission.preview.mission-completed', {
              date: formatDate(new Date(missionCompleted)),
            })}
          </Paragraph>
        )}
      </div>
    </Flex>
  )
}

export default MissionOverviewTitle
