import { useTranslation } from 'react-i18next'

import { CoachingSessionStatus } from 'lib/types/coachingSession.types'

import {
  colourBrandBlue,
  colourCampaignSustainability,
  colourNeutralGrey300,
} from 'styles/tokens/oldSkapa.tokens'

import { StatusIndicator } from 'components/composites/Shared/StatusIndicator'

import { CoachingSessionStatusIndicatorProps } from './CoachingSessionStatusIndicator.types'

const CoachingSessionStatusIndicator: React.FC<CoachingSessionStatusIndicatorProps> = ({
  status,
}) => {
  const { t } = useTranslation()

  let color, label
  switch (status) {
    case CoachingSessionStatus.Planned:
      color = colourNeutralGrey300
      label = t('features.coaching-session.status.draft')
      break
    case CoachingSessionStatus.Completed:
      color = colourBrandBlue
      label = t('features.coaching-session.status.awaiting-signature')
      break
    case CoachingSessionStatus.Signed:
      color = colourCampaignSustainability
      label = t('features.coaching-session.status.signed')
  }
  return <StatusIndicator label={label} color={color} />
}

export default CoachingSessionStatusIndicator
