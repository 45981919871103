import { ScoreEntry } from 'hooks/qualityAssssments/useCurrentQaDataForCoworker.hooks'

export type CoachingSession = {
  /** Unique identifier for the session */
  id: string
  missions: string[]
  sessionComment?: string
  status: CoachingSessionStatus

  createdBy: string
  createdDate: string
  plannedDate: string
  startedDate?: string
  completedDate?: string
  signedDate?: string
  assessmentsNeeded: number
  sessionScore?: ScoreEntry[]
  draftCompleted: boolean
  coworkerNote?: CoworkerNote
}

export enum CoachingSessionStatus {
  Planned = 'planned',
  Completed = 'completed',
  Signed = 'signed',
}

export type CoworkerNote = {
  note: string
  createdDate: string
  createdBy: string
  isAvailableToTeamlead?: boolean
}
