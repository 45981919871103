import Button from '@ingka/button'
import { space1350 } from '@ingka/variables'
import { Stack } from '@mantine/core'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CoachingSessionStatus } from 'lib/types/coachingSession.types'
import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'
import { CustomViewEventInput, CustomViewKeys } from 'lib/utils/analytics/analytics.types'

import { useCoworker } from 'state/slices/api'
import { useCoachingSession } from 'state/slices/api/modules/coachingSession/coachingSession.slice'

import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import { CoachingSessionEditorProps } from 'components/features/CoachingSessions/CoachingSessionEditor/CoachingSessionEditor.types'
import StickyFooterCoachingSession from 'components/layout/StickyFooter/StickyFooter.component'
import ConditionalTooltip from 'components/primitives/ConditionalTooltip/ConditionalTooltip.component'
import UnexpectedErrorMessage from 'components/shared/UnexpectedErrorMessage/UnexpectedErrorMessage.component'

import { CoachingSessionFooterStepper } from '../CoachingSessionFooter'
import { EditorHeader } from './EditorHeader'
import { ReviewMetrics } from './ReviewMetrics'
import { ReviewMissions } from './ReviewMissions'
import { Summary } from './Summary'
import { ConfirmModal } from './Summary/ConfirmModal'

const REVIEW_STEP = 0
const MISSIONS_STEP = 1
const SUMMARY_STEP = 2

const CoachingSession: React.FC<CoachingSessionEditorProps> = ({
  coworkerId,
  coachingSessionId,
}) => {
  const { t } = useTranslation()
  const [activeStep, setActiveStep] = useState(0)

  const {
    data: coworker,
    isLoading: isLoadingCoworker,
    isSuccess: isSuccessCoworker,
    isError: isErrorCoworker,
  } = useCoworker(coworkerId)
  const {
    data: coachingSession,
    isLoading: isLoadingCoachingSession,
    isSuccess: isSuccessCoachingSession,
    isError: isErrorCoachingSession,
  } = useCoachingSession({
    coworkerId,
    coachingSessionId,
  })

  const [showCompletionModal, setShowCompletionModal] = useState(false)

  const isCompleteCoachingSessionDisabled =
    // Review Note: Active step === 4? That is not really allowed anywhere. Is this a leftover piece?
    activeStep === 4 && coachingSession?.status !== CoachingSessionStatus.Planned

  const isLoading = isLoadingCoworker || isLoadingCoachingSession
  const isSuccess = isSuccessCoworker && isSuccessCoachingSession && coworker && coachingSession
  const isError = isErrorCoworker || isErrorCoachingSession

  useEffect(() => {
    const input: CustomViewEventInput = {
      key:
        activeStep === 0
          ? CustomViewKeys.CoachingOverview
          : activeStep === 1
            ? CustomViewKeys.CoachingMissions
            : CustomViewKeys.CoachingSummary,
      description:
        'Custom event for tracking when a teamlead switches between steps in coaching flow.',
      coworkerId,
    }

    analyticsHelper.createCustomViewEvent(input, {
      coachingSessionId: coachingSessionId,
    })
  }, [activeStep, coachingSessionId, coworkerId])

  return (
    <>
      {isLoading && (
        <LoadingIndicator
          message={
            isLoadingCoworker
              ? t('loading-messages.fetching-coworker-profile')
              : isLoadingCoachingSession
                ? t('loading-messages.fetching-coaching-session')
                : ''
          }
        />
      )}
      {isError && <UnexpectedErrorMessage />}
      {isSuccess && (
        <Stack m="auto">
          {/* CoachingSession footer stepper */}
          <EditorHeader plannedDate={coachingSession.plannedDate} coworker={coworker} />
          <StickyFooterCoachingSession>
            <Button
              small
              text={t('common.actions.back')}
              onClick={() => setActiveStep(activeStep - 1)}
              // Review note: I have changed that to 0, as we do not want to tie this to a particular step.
              // If you are at the beginning of the steps (no matter which step is the first), you cannot go back
              disabled={activeStep === 0}
            />

            <CoachingSessionFooterStepper activeStep={activeStep} setActiveStep={setActiveStep} />
            <div>
              <ConditionalTooltip
                condition={isCompleteCoachingSessionDisabled}
                tooltipText={t(
                  'features.coaching-session.warnings.cannot-complete-draft-coaching-session'
                )}
              >
                <Button
                  small
                  type="primary"
                  style={{ minWidth: space1350 }}
                  text={
                    activeStep === SUMMARY_STEP
                      ? t('features.coaching-session.actions.complete-coaching-session')
                      : t('common.actions.next')
                  }
                  onClick={
                    activeStep === SUMMARY_STEP
                      ? () => setShowCompletionModal(true)
                      : () => setActiveStep(activeStep + 1)
                  }
                  disabled={isCompleteCoachingSessionDisabled}
                />
              </ConditionalTooltip>
            </div>
          </StickyFooterCoachingSession>
          {activeStep === REVIEW_STEP && (
            <ReviewMetrics coworkerId={coworkerId} coachingSession={coachingSession} />
          )}
          {activeStep === MISSIONS_STEP && (
            <ReviewMissions coworkerId={coworkerId} coachingSession={coachingSession} />
          )}
          {activeStep === SUMMARY_STEP && (
            <Summary coworkerId={coworkerId} coachingSession={coachingSession} />
          )}
        </Stack>
      )}
      {activeStep === SUMMARY_STEP && (
        <ConfirmModal
          showCompletionModal={showCompletionModal}
          setShowCompletionModal={setShowCompletionModal}
          coworkerId={coworkerId}
          coachingSession={coachingSession!}
        />
      )}
    </>
  )
}

export default CoachingSession
