import { ISODateString } from 'lib/types/common.types'

import { UserPhoto } from './coworker.types'

export enum UserRole {
  Admin = 'admin',
  TeamLead = 'teamlead',
  Coworker = 'coworker',
  Guest = 'guest',
  Qs = 'qs',
  Demo = 'demo',
}

export type User = {
  id: string
  createdDate: ISODateString
  legacyId: string
  name: string
  email: string
  role: UserRole
  prefs: UserPrefs
  lastLogin?: ISODateString
  notifications?: Notification[]
  photoData?: UserPhoto
}

export type Notification = {
  id: string
  createdDate: ISODateString
  title: string
  message?: string
  link?: string
  read: boolean
}

export type UserPrefs = {
  locale?: string
  latestAppVersion?: string
  showCustomerComments?: boolean
  introduction: IntroductionPrefs
}

type IntroductionPrefs = {
  isProfileReady: boolean
  showWelcomeModal: boolean
}

export type TeamleadUser = {
  legacyId: string
  name: string
  email: string
  role: string
  photoData?: UserPhoto
}
