import dayjs from 'dayjs'

import { QaGroupKey, QaMetricId } from 'config/domain/qa.config'
import { isQarKey } from 'config/domain/qarForm.config'
import { isQasKey } from 'config/domain/qasForm.config'
import { isQsaKey } from 'config/domain/qsaForm.config'

import { QaType, QualityAssessment } from 'lib/types/qualityAssessment.types'

export class QualityAssessmentHelper {
  public static getQaAverage = (qas: QualityAssessment[], qaId: QaMetricId) => {
    const filteredQas = this.filterQasById(qas, qaId)

    let total = 0

    // Calculate average for current Qas
    for (let index = 0; index < qas.length; index++) {
      const groups = qas[index].groups
      let score = 0

      groups.forEach((group) => {
        group.questions.forEach((question) => {
          if (question.id === qaId) {
            score = score + question.score
          }
        })
      })

      total = total + score
    }

    return total / filteredQas.length
  }

  static filterQasById = (assessments: QualityAssessment[], qaId: QaMetricId) => {
    // Filter qas for month before startdate
    const filteredQas = assessments.filter((qa) =>
      qa.groups.some((group) => group.questions.some((question) => question.id === qaId))
    )

    return filteredQas
  }

  static filterQasByGroupId = (assessments: QualityAssessment[], qaId: QaGroupKey) => {
    // Filter qas for month before startdate
    const filteredQas = assessments.filter((qa) => qa.groups.some((group) => group.id === qaId))

    return filteredQas
  }

  static filterQasByTopId = (assessments: QualityAssessment[], type: QaType) => {
    // Filter qas for month before startdate
    const filteredAssessments = assessments.filter((qa) => {
      let returnValue = false
      if (type === QaType.QAR) {
        returnValue = isQarKey(qa.id)
      } else if (type === QaType.QAS) {
        returnValue = isQasKey(qa.id)
        return isQasKey(qa.id)
      } else if (type === QaType.QSA) {
        returnValue = isQsaKey(qa.id)
      }
      return returnValue
    })

    return filteredAssessments
  }

  static filterQasByDates = (assessments: QualityAssessment[], start: Date, end: Date) => {
    const filteredQas = assessments
      .filter((qa) => qa.evaluationDate != null)
      .filter(
        (qa) =>
          dayjs(qa.evaluationDate.value).toDate() >= start &&
          dayjs(qa.evaluationDate.value).toDate() <= end
      )

    return filteredQas
  }
}
