export class FormattingHelper {
  static formatNumber(
    value: number | string | undefined,
    decimalPlaces: number = 0,
    locale: Intl.LocalesArgument = undefined
  ): string | undefined {
    if (value === undefined) return undefined

    return value.toLocaleString(locale, {
      maximumFractionDigits: decimalPlaces,
      minimumFractionDigits: decimalPlaces,
    })
  }

  static displayNumber(value: number): string {
    if (isNaN(value)) {
      return 'N/A'
    }

    return String(value.toFixed(0))
  }

  static extractInteractionIdFromLink(url: string): string {
    const dialogRegex = /interactions\/([a-f0-9-]+)/
    const dialogMatch = url.match(dialogRegex)

    const oneSolutionRegex = /interactionId=([0-9]+)/
    const oneSolutionMatch = url.match(oneSolutionRegex)

    if (dialogMatch) {
      return dialogMatch[1]
    } else if (oneSolutionMatch) {
      return oneSolutionMatch[1]
    }

    return ''
  }
}
