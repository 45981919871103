import { CoachingSession, CoachingSessionStatus } from 'lib/types/coachingSession.types'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

// TODO: Replace with helper function
export const useCoachingSessionHref = (
  coachingSession: CoachingSession | undefined,
  prepare?: boolean
) => {
  const { coworkerPath } = useSectionPaths()

  if (!coachingSession) {
    return `${coworkerPath}#coaching-sessions`
  }

  const path = `${coworkerPath}/coaching-sessions/${coachingSession.id}`

  if (prepare) return `${path}/prepare`
  if (coachingSession.status === CoachingSessionStatus.Planned) return `${path}/flow`

  return path
}
