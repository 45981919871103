import { Skeleton } from '@mantine/core'

import { Widget } from '../Widget'

export const LoadingSkeleton = ({ height = '25vh' }) => (
  <Widget style={{ minHeight: height }}>
    <Skeleton
      style={{
        minHeight: height,
        minWidth: '100%',
      }}
    />
  </Widget>
)
