import FormField from '@ingka/form-field'
import InputField from '@ingka/input-field'
import { space50 } from '@ingka/variables'
import { Box } from '@mantine/core'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Coworker } from 'lib/types/coworker.types'

import { MissionDraftFormFields } from 'components/features/Missions/MissionDraftEditor/MissionDraftEditor.types'
import { Caption, SubHeading } from 'components/primitives/Text'

type Props = {
  form: UseFormReturn<MissionDraftFormFields> & {
    watch: (field: keyof MissionDraftFormFields) => any
  }
  coworker: Coworker
}

const TitleStep: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation()
  const { control, formState } = form

  return (
    <>
      <SubHeading>{t('features.mission.create-mission.steps.mission-statement.title')}</SubHeading>
      <Caption>{t('features.mission.create-mission.steps.mission-statement.subtitle')}</Caption>
      <Box mt={space50}>
        <Controller
          name="title"
          control={control}
          rules={{
            required: {
              value: true,
              message: t('features.mission.create-mission.fields.title.required'),
            },
            validate: (value) => {
              return `${value} is a valid title.`
            },
          }}
          render={({ field }) => {
            return (
              <FormField
                data-testid="form-field-title"
                valid={formState.errors.title?.type === 'validate' ? true : false}
                shouldValidate={formState.errors.title ? true : false}
                fieldHelper={{
                  msg: t('features.mission.create-mission.fields.title.hint'),
                }}
                validation={{
                  id: 'error-title',
                  msg: formState.errors.title?.message,
                  type: 'error',
                }}
              >
                <InputField
                  label={t('features.mission.create-mission.fields.name.title')}
                  {...field}
                  id="title"
                  data-testid="input-field-title"
                  type="text"
                />
              </FormField>
            )
          }}
        />
      </Box>
    </>
  )
}

export default TitleStep
