import Avatar from '@ingka/avatar'
import { space100 } from '@ingka/variables'

import { Coworker } from 'lib/types/coworker.types'

import { coworkerPhotoStyle } from './CoworkerPhotos.styles'

type CoworkerPhotosProps = {
  coworker: Coworker
  marginRight?: boolean
}

const CoworkerPhoto: React.FC<CoworkerPhotosProps> = ({ coworker, marginRight }) => {
  const coworkerInitials = coworker && `${coworker.firstName[0]}${coworker.lastName[0]}`

  return (
    <>
      {   coworker.photoData?.photo ? (
        <img
          src={coworker?.photoData?.photo}
          alt={coworker.fullName}
          style={{
            ...coworkerPhotoStyle,
            marginRight: marginRight ? space100 : 0,
          }}
        />
      ) : (
        <Avatar
          screenReaderText={coworkerInitials}
          text={coworkerInitials}
          variant="primary"
          size="medium"
          style={{
            ...coworkerPhotoStyle,
            marginRight: marginRight ? space100 : 0,
          }}
        />
      )}
    </>
  )
}

export default CoworkerPhoto
