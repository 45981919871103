import { useCoworkerId } from 'hooks/id/useCoworkerId.hooks'
import { useCurrentPageType } from 'hooks/navigation/useSectionPaths.hooks'

import { useDismissCoworkerCustomerComment } from 'state/slices/api'

import { CoworkerCustomerComments } from 'components/features/CoworkerMVP/CoworkerCustomerComments'
import { CustomerCommentsOverview } from 'components/features/CustomerComments'
import { useCustomerCommentsForTeamleads } from 'components/features/CustomerComments/CustomerComments.hooks'

const CustomerCommentsPage: React.FC = () => {
  const pageType = useCurrentPageType()
  const coworkerId = useCoworkerId()

  const { forwardCustomerComment, dismissCustomerComment } = useCustomerCommentsForTeamleads()

  const [coworkerDismissComment] = useDismissCoworkerCustomerComment()

  const handleForwardComment = async (interactionId: string) => {
    await forwardCustomerComment({ coworkerId, interactionId })
  }

  const handleTeamleadDismissComment = async (interactionId: string) => {
    await dismissCustomerComment({ coworkerId, interactionId })
  }

  const handleCoworkerDismissComment = async (interactionId: string) => {
    await coworkerDismissComment({ coworkerId, interactionId })
  }

  return (
    <>
      {pageType === 'teamlead' ? (
        <CustomerCommentsOverview
          coworkerId={coworkerId}
          availableActions={['forward', 'dismiss']}
          onForward={handleForwardComment}
          onDismiss={handleTeamleadDismissComment}
        />
      ) : (
        <CoworkerCustomerComments
          coworkerId={coworkerId}
          availableActions={['dismiss']}
          onDismiss={handleCoworkerDismissComment}
        />
      )}
    </>
  )
}

export default CustomerCommentsPage
