import arrowOnPath from '@ingka/ssr-icon/paths/arrow-on-path'
import checkmark from '@ingka/ssr-icon/paths/checkmark'
import cleanSparkles from '@ingka/ssr-icon/paths/clean-sparkles'
import documentUpload from '@ingka/ssr-icon/paths/document-upload'
import pencil from '@ingka/ssr-icon/paths/pencil'
import dayjs from 'dayjs'

import { isQarKey } from 'config/domain/qarForm.config'
import { isQasKey } from 'config/domain/qasForm.config'
import { isQsaKey } from 'config/domain/qsaForm.config'

import { Mission, MissionChanges, MissionStatus } from 'lib/types/mission.types'
import { QaType } from 'lib/types/qualityAssessment.types'

export enum IconStatus {
  SuggestedToComplete = 'checkmark',
  Modified = 'pencil',
  Draft = 'clean-sparkles',
  Active = 'active',
  NewState = 'new-state',
}

export class MissionHelper {
  public static missionOverDue = (endDate: Date | string) => {
    return dayjs(endDate).diff(dayjs(), 'day') < 0
  }

  public static missionDueInDays = (endDate: Date | string) => {
    const currentDate = new Date()
    return dayjs(endDate).diff(dayjs(currentDate), 'day')
  }

  public static completedMissionEndDate = (endDate: Date | string) => {
    return dayjs(endDate).format('DD/MM/YYYY')
  }

  public static getMissionProgress = (missionStart: string, missionEnd: string) => {
    const missionLength =
      dayjs(missionEnd).toDate().getTime() - dayjs(missionStart).toDate().getTime()
    const missionProgress =
      ((dayjs().toDate().getTime() - dayjs(missionStart).toDate().getTime()) / missionLength) * 100
    return missionProgress
  }

  public static isMissionModified = (mission: Mission): boolean =>
    mission.changes.coachingPointers !== mission.coachingPointers ||
    mission.changes.endDate !== mission.endDate ||
    mission.changes.target !== mission.target ||
    mission.changes.title !== mission.title

  public static missionChanges = (mission: Mission): Partial<MissionChanges> => {
    const returnObj: Partial<MissionChanges> = {}

    if (mission.endDate !== mission.changes.endDate) {
      returnObj.endDate = mission.changes.endDate
    }
    if (mission.target !== mission.changes.target) {
      returnObj.target = mission.changes.target
    }
    if (mission.coachingPointers !== mission.changes.coachingPointers) {
      returnObj.coachingPointers = mission.changes.coachingPointers
    }
    if (mission.title !== mission.changes.title) {
      returnObj.title = mission.changes.title
    }

    return returnObj
  }

  public static getIconFromMission = (mission?: Mission) => {
    if (mission) {
      if (mission.status === MissionStatus.Draft) {
        return this.getIconFromStatus(IconStatus.Draft)
      } else if (mission.status === MissionStatus.Active) {
        if (mission.suggestedToComplete) {
          return this.getIconFromStatus(IconStatus.SuggestedToComplete)
        } else if (this.isMissionModified(mission)) {
          return this.getIconFromStatus(IconStatus.Modified)
        }
      }
    }
  }

  public static getIconFromStatus = (iconStatus: IconStatus) => {
    switch (iconStatus) {
      case IconStatus.SuggestedToComplete:
        return checkmark
      case IconStatus.Modified:
        return pencil
      case IconStatus.Draft:
        return cleanSparkles
      case IconStatus.Active:
        return arrowOnPath
      case IconStatus.NewState:
        return documentUpload
      default:
        console.warn('Cannot find icon for the status: ' + iconStatus)
        return undefined
    }
  }

  static filterMissionsByType = (missions: Mission[], type: QaType): Mission[] => {
    const filteredMissions = missions.filter((mission) => {
      if (type === QaType.QAR) {
        return (
          isQarKey(mission.qaId) &&
          (mission.status === MissionStatus.Active || mission.status === MissionStatus.Draft)
        )
      } else if (type === QaType.QAS) {
        return (
          isQasKey(mission.qaId) &&
          (mission.status === MissionStatus.Active || mission.status === MissionStatus.Draft)
        )
      } else if (type === QaType.QSA) {
        return (
          isQsaKey(mission.qaId) &&
          (mission.status === MissionStatus.Active || mission.status === MissionStatus.Draft)
        )
      } else {
        return false
      }
    })

    return filteredMissions
  }

  static getActiveMissions = (missions: Mission[]): Mission[] => {
    return missions.filter(
      (mission) => mission.status === MissionStatus.Active || mission.status === MissionStatus.Draft
    )
  }
}
