import Tooltip from '@ingka/tooltip'
import { RichTextEditor } from '@mantine/tiptap'
import { Editor } from '@tiptap/react'
import { useTranslation } from 'react-i18next'

import {
  backgroundColourDefault,
  colourGreyNeutral1,
  colourGreyNeutral2,
} from 'styles/tokens/insikt.tokens'
import { colourNeutralGrey100 } from 'styles/tokens/oldSkapa.tokens'

type RTFTextEditorProps = {
  editor: Editor
  backgroundColor?: boolean // Editor's background relevant to the page background color in CoachingPointersStep
  includesCheckboxes?: boolean // Editor includes checkboxes
  className?: string
}

const RTFTextEditor: React.FC<RTFTextEditorProps> = ({
  editor,
  backgroundColor,
  includesCheckboxes = true,
  className,
}) => {
  const { t } = useTranslation()
  const isDisabled = !editor.isEditable && editor.options.editable === false

  return (
    <>
      <RichTextEditor
        editor={editor}
        className={className}
        styles={{
          root: {
            minHeight: 200,
            backgroundColor: backgroundColor ? colourGreyNeutral1 : colourGreyNeutral2,
          },
          toolbar: {
            marginBottom: 2,
            borderColor: 'transparent',
            ...(isDisabled && {
              display: 'none',
            }),
          },
          content: {
            paddingInline: 8,
            paddingBlock: 4,
            backgroundColor: backgroundColor ? backgroundColourDefault : 'white',
            minHeight: 200,
            borderRadius: 4,
          },
          controlsGroup: {
            marginBottom: 2,
          },
          control: {
            border: `1px solid ${colourNeutralGrey100}`,
            borderRadius: 4,
            ...(isDisabled && {
              pointerEvents: 'none',
              backgroundColor: colourNeutralGrey100,
              color: 'black',
            }),
          },
          linkEditor: {
            padding: 2,
            borderRadius: 2,
            boxShadow: 'none',
          },
          linkEditorDropdown: {
            padding: 2,
          },
          linkEditorInput: {
            padding: 2,
            borderRadius: 2,
          },
          // display none to hide Mantine's default link controls
          linkEditorExternalControl: {
            display: 'none',
          },
          linkEditorSave: {
            display: 'none',
          },
        }}
      >
        <RichTextEditor.Toolbar suppressContentEditableWarning>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H3 /> {/* disabled={isDisabled} */}
            <RichTextEditor.H4 disabled={isDisabled} data-disabled={isDisabled} />
            <RichTextEditor.H5 disabled={isDisabled} data-disabled={isDisabled} />
          </RichTextEditor.ControlsGroup>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold disabled={isDisabled} data-disabled={isDisabled} />
            <RichTextEditor.Italic disabled={isDisabled} data-disabled={isDisabled} />
            <RichTextEditor.Underline disabled={isDisabled} />
            <RichTextEditor.Strikethrough />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList
              disabled={isDisabled}
              aria-pressed={isDisabled}
              data-disabled={isDisabled}
            />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            {includesCheckboxes && <RichTextEditor.TaskList disabled={isDisabled} />}
            {/* Todo: Extend this tooltip into an info modal like the one in MissionQaMetricsInfo  */}
            <Tooltip position="top" tooltipText={t('features.rich-text-editor.tooltip')}>
              <RichTextEditor.Link />
            </Tooltip>
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content />
      </RichTextEditor>
    </>
  )
}

export default RTFTextEditor
