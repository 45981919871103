import {
  SurveyMetricData,
  SurveyMetricDataset,
  SurveyMetricRollingAVG,
  SurveyMetricTransactional,
} from 'lib/types/surveyMetric.types'

import { apiSlice } from '../../api.slice'

export const surveyMetricSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSurveyMetricsByCoworkerId: builder.query<SurveyMetricDataset, string>({
      query: (coworkerId) => ({
        url: `/coworkers/${coworkerId}/surveys`,
        method: 'GET',
      }),
      providesTags: ['SurveyMetric'],
      transformResponse: (response: {
        legacyId: string
        surveyData: SurveyMetricData[]
      }): SurveyMetricDataset => {
        const rollingCSATData = response.surveyData.find((data) => data.source_ === 'CSAT rolling')
        const rollingCSAT = rollingCSATData
          ? (rollingCSATData.surveyMetric[0] as SurveyMetricRollingAVG)
          : null
        const transactionalData = response.surveyData.find(
          (data) => data.source_ === 'CSAT transactions'
        )
        const transactionalCSAT = transactionalData
          ? (transactionalData.surveyMetric as SurveyMetricTransactional[])
          : []
        return {
          legacyId: response.legacyId,
          CSATRolling: rollingCSAT ? rollingCSAT : null,
          CSATTransactions: transactionalCSAT,
        }
      },
    }),
  }),
})

export const { useGetSurveyMetricsByCoworkerIdQuery: useSurveyMetrics } = surveyMetricSlice
