import { useCallback, useMemo } from 'react'

import {
  useActivateMission,
  useCompleteMission,
  useDeleteMission,
  useSuggestMission,
} from 'state/slices/api'

/** A collection of custom hooks for various missions related CRUD operations */

export const useDeleteMissionDraftClicked = (coworkerId: string, missionId: string) => {
  const [deleteMission, meta] = useDeleteMission()

  const handleDeleteMissionDraft = useCallback(() => {
    const deleteMissionArgs = { coworkerId, missionId }
    deleteMission(deleteMissionArgs).then(() => {
      console.log('Delete mission success')
    })
  }, [deleteMission, coworkerId, missionId])
  return [handleDeleteMissionDraft, meta] as const
}

export const useSuggestCompleteMissionClicked = (
  coworkerId: string,
  missionId: string,
  suggestedToComplete: boolean
) => {
  const [suggestMission, meta] = useSuggestMission()

  const suggestMissionArgs = useMemo(
    () => ({
      coworkerId,
      missionId,
      suggestedToComplete: !suggestedToComplete,
    }),
    [coworkerId, missionId, suggestedToComplete]
  )

  return [suggestMission, suggestMissionArgs, meta] as const
}

export const useCompleteMissionClicked = (coworkerId: string, missionId: string) => {
  const [completeMission, meta] = useCompleteMission()

  const handleCompleteMission = useCallback(() => {
    const completeMissionArgs = { coworkerId, missionId }
    completeMission(completeMissionArgs).then(() => {
      console.log('Complete mission success')
    })
  }, [completeMission, coworkerId, missionId])

  return [handleCompleteMission, meta] as const
}

export const useActivateMissionClicked = (coworkerId: string, missionId: string) => {
  const [activateMission, meta] = useActivateMission()

  const handleActivateMission = useCallback(() => {
    activateMission({
      missionId,
      coworkerId,
    }).then(() => {
      console.log('Activate mission success')
    })
  }, [activateMission, coworkerId, missionId])

  return [handleActivateMission, meta] as const
}
