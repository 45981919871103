import Pill from '@ingka/pill'

import styled from 'styled-components'

export const StyledPill = styled(Pill)`
  border-radius: 50%;
  width: 34px;
  height: 34px;
  border: 1px solid black;
  pointer-events: none;
  align-self: baseline;
`
