import { collection, collectionGroup, onSnapshot } from 'firebase/firestore'
import { useEffect, useState } from 'react'

import { firestore } from 'config/firebase/firebase.config'

import { CoachingSession } from 'lib/types/coachingSession.types'
import { Mission } from 'lib/types/mission.types'
import { User } from 'lib/types/user.types'

export const useAdminStats = () => {
  // Coaching sessions stats
  const [coachingSessions, setCoachingSessions] = useState<CoachingSession[]>()

  // Missions stats
  const [missions, setMissions] = useState<Mission[]>()

  // Users stats
  const [users, setUsers] = useState<User[]>()
  useEffect(() => {
    const unsubscribeUsers = onSnapshot(collection(firestore, `users`), (snapshot) => {
      const usersData = snapshot.docs.map((doc) => doc.data()) as User[]
      setUsers(usersData)
    })

    const unsubscribeCoachingSessions = onSnapshot(
      collectionGroup(firestore, `coachingSessions`),
      (snapshot) => {
        const coachingSessionsData = snapshot.docs.map((doc) => doc.data()) as CoachingSession[]
        setCoachingSessions(coachingSessionsData)
      }
    )

    const unsubscribeMissions = onSnapshot(collectionGroup(firestore, `missions`), (snapshot) => {
      const missionsData = snapshot.docs.map((doc) => doc.data()) as Mission[]
      setMissions(missionsData)
    })

    // Clean up the listener when the component unmounts)
    return () => {
      unsubscribeCoachingSessions()
      unsubscribeMissions()
      unsubscribeUsers()
    }
  }, [])

  return {
    coachingSessions,
    missions,
    users,
  }
}
