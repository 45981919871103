import {
  activate,
  fetchConfig,
  getRemoteConfig,
  getValue,
} from 'firebase/remote-config'

import { firebaseApp } from 'config/firebase/firebase.config'

const remoteConfig = getRemoteConfig(firebaseApp)

// console.log('Setting short fetch interval for dev testings of feature flags')
remoteConfig.settings.minimumFetchIntervalMillis = 1000 * 60 * 5

// Set default feature key values in case the given parameter does not exist in Remote Config
const defaultFeatureKeys: Record<string, boolean> = {
  coaching_session: false,
  qa_notes: false,
  badges: false,
  notifications: false,
  action_points: false,
  coworkerOld: false,
  coworkerNew: false,
}

remoteConfig.defaultConfig = defaultFeatureKeys

export const getFeatureFlag = (featureKey: string): boolean => {
  const value = getValue(remoteConfig, featureKey)
  return value.asBoolean()
}

export const initializeFeatureFlags = async () => {
  try {
    await fetchConfig(remoteConfig)

    await activate(remoteConfig)
  } catch (error) {
    console.error('Failed to load remote config: ', error)
  }
}
