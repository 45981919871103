import { Outlet, Route } from 'react-router-dom'

import { GuestPage } from 'components/pages/global/GuestPage'
import { StartPage } from 'components/pages/global/StartPage'

/**
 * Be aware that this is *not* a ReactNode but rather a list of JSX.element
 * The motivation for this is that react-router only allows <Route/> as child elements.
 */
const GlobalRoutes = (
  <Route path="" element={<Outlet />}>
    <Route path="" element={<StartPage />} />
    <Route path="guest" element={<GuestPage />} />
  </Route>
)

export default GlobalRoutes
