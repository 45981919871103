import Button from '@ingka/button'
import { space50, space100 } from '@ingka/variables'

import { colourSecondary } from 'styles/tokens/insikt.tokens'

import { Heading as OriginalSubHeading } from 'components/primitives/Text'
import styled from 'styled-components'

export const UserName = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${space50};
`

export const MenuButton = styled(Button)``

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${space100};
  cursor: pointer;
`

export const RoutesWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const SubHeading = styled(OriginalSubHeading)`
  color: ${colourSecondary.main};
`

export const FlexBreak = styled.div`
  flex-basis: 100%;
  height: 0;
`
