import { CountryOverview } from 'components/features/Countries/CountryOverview'
import { PageHeader } from 'components/layout/PageHeader'

const ManageCountriesPage = () => (
  <div data-testid="page-manage-countries">
    <PageHeader title="Manage countries" backHref="/admin" />
    <CountryOverview />
  </div>
)

export default ManageCountriesPage
