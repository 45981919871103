import Button from '@ingka/button'
import arrowRight from '@ingka/ssr-icon/paths/arrow-right'
import pencil from '@ingka/ssr-icon/paths/pencil'
import Table, { TableBody, TableHeader } from '@ingka/table'
import Text from '@ingka/text'
import { space50, space100 } from '@ingka/variables'
import { Flex, Group } from '@mantine/core'
import { orderBy } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import { CoachingSession, CoachingSessionStatus } from 'lib/types/coachingSession.types'
import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'
import { CustomElementClickEventKeys } from 'lib/utils/analytics/analytics.types'
import { CoachingSessionHelper } from 'lib/utils/coachingSession.helper'

import { useFormatDate } from 'hooks/formatting'
import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { useToggleCoachingSessionDraft } from 'state/slices/api/modules/coachingSession'

import { Widget } from 'components/composites/Shared/Widget/Widget.styling'

import * as Styled from './CoachingSessionPreviewList.styles'
import { CoachingSessionPreviewTableProps, TableRowProps } from './CoachingSessionPreviewList.types'
import CoachingSessionModal from './CoachingSessionSheetModal.component'

const CoachingSessionPreviewTable: React.FC<CoachingSessionPreviewTableProps> = ({
  coachingSessions,
}) => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState(false)
  const [editSessionId, setEditSessionId] = useState('')
  const [editSession, setEditSession] = useState<CoachingSession | undefined>(undefined)

  let coachingSessionsSorted = coachingSessions.map((cs) => {
    return {
      ...cs,
      sessionDate: cs.completedDate ? cs.completedDate : cs.plannedDate,
    }
  })

  const handleOpenEditModal = (id: string) => {
    setEditSessionId(id)
    setOpenModal(true)
  }

  useEffect(() => {
    setEditSession(coachingSessions.find((cs) => cs.id === editSessionId))
  }, [editSessionId, coachingSessions])

  return (
    <>
      <Widget style={{ marginTop: space100 }}>
        <Table fullWidth inset>
          <TableHeader>
            <Styled.TableRowHeader>
              <th>{t('features.coaching-session.table-headers.session-name')}</th>
              <th>{t('features.coaching-session.table-headers.session-status')}</th>
              <th>{t('features.coaching-session.table-headers.session-date')}</th>
              <th>{''}</th>
            </Styled.TableRowHeader>
          </TableHeader>
          <TableBody>
            {orderBy(coachingSessionsSorted, ['sessionDate'], ['desc'])?.map((cs, idx, arr) => {
              return (
                <TableRow
                  key={cs.id}
                  cs={cs}
                  date={cs.sessionDate}
                  index={arr.length - idx}
                  openModal={() => handleOpenEditModal(cs.id)}
                />
              )
            })}
          </TableBody>
        </Table>
      </Widget>
      {editSession && (
        <CoachingSessionModal
          coachingSession={editSession}
          closeModal={() => setOpenModal(false)}
          visible={openModal}
        />
      )}
    </>
  )
}

const TableRow: React.FC<TableRowProps> = ({ cs, index, openModal, date }) => {
  const formatDate = useFormatDate()
  const formattedDate = formatDate(new Date(date))
  const { coworkerPath } = useSectionPaths()
  const { hash } = useLocation()
  // Used for navigation back to teamlead tabs
  const { coworkerId } = useParams()
  const fromUrl = coworkerPath + hash
  const href = coworkerPath + CoachingSessionHelper.getHref(cs)

  const { t } = useTranslation()
  const nav = useNavigate()
  const [toggleDraft] = useToggleCoachingSessionDraft()

  return (
    <Styled.TableRow active={cs.status !== CoachingSessionStatus.Signed}>
      <Styled.TableCell
        style={{ fontWeight: 'bold' }}
      >{`Coaching session ${index}`}</Styled.TableCell>
      <Styled.TableCell>
        <Group gap={space50}>
          <Text style={{ fontWeight: 'bold' }}>{t(`common.coaching-status.${cs.status}`)}</Text>
        </Group>
      </Styled.TableCell>
      <Styled.TableCell>
        <Group gap={space50}>
          {formattedDate}
          {cs.status === CoachingSessionStatus.Planned && (
            <Button
              style={{ zIndex: 10 }}
              type="tertiary"
              small
              ssrIcon={pencil}
              iconOnly
              onClick={openModal}
            />
          )}
        </Group>
      </Styled.TableCell>
      <Styled.TableCell>
        {cs.status === CoachingSessionStatus.Planned ? (
          <Flex direction="row" align={'center'} gap={space50}>
            <Button
              type="emphasised"
              small
              onClick={async () => {
                if (cs.draftCompleted) {
                  await toggleDraft({
                    coachingSessionId: cs.id,
                    coworkerId: coworkerId!,
                  })
                }
                analyticsHelper.createCustomElementClickEvent(
                  {
                    key: CustomElementClickEventKeys.PrepareCoaching,
                    description:
                      'Custom event for tracking when a teamlead clicks on the modify/prepare button.',
                  },
                  { coachingSessionId: cs.id, coworkerId: coworkerId }
                )
                nav(href, {
                  state: { from: fromUrl },
                })
              }}
            >
              {cs.draftCompleted
                ? t('features.coaching-session.actions.modify')
                : t('features.coaching-session.actions.prepare')}
            </Button>
            {cs.draftCompleted && (
              <>
                <Text>{t('common.text.or')}</Text>
                <Button
                  type="secondary"
                  small
                  onClick={() => {
                    analyticsHelper.createCustomElementClickEvent(
                      {
                        key: CustomElementClickEventKeys.ReviewCoaching,
                        description:
                          'Custom event for tracking when a teamlead clicks on the review button.',
                      },
                      { coachingSessionId: cs.id }
                    )
                    nav(href, {
                      state: { from: fromUrl, review: true },
                    })
                  }}
                >
                  {t('features.coaching-session.actions.review')}
                </Button>
              </>
            )}
          </Flex>
        ) : (
          <Link
            onClick={() => {
              analyticsHelper.createCustomElementClickEvent(
                {
                  key: CustomElementClickEventKeys.InspectCoaching,
                  description:
                    'Custom event when teamleads are clicking the inspect button on a coaching session.',
                },
                {
                  coachingSessionId: cs.id,
                }
              )
            }}
            to={href}
            state={{ from: fromUrl }}
          >
            <Button type="tertiary" small ssrIcon={arrowRight} iconPosition="trailing">
              {t('features.coaching-session.session-record')}
            </Button>
          </Link>
        )}
      </Styled.TableCell>
    </Styled.TableRow>
  )
}

export default CoachingSessionPreviewTable
