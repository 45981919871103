import './styles.css'
import InlineMessage from '@ingka/inline-message'
import Tooltip from '@ingka/tooltip'
import { space50, space100 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { Mission, MissionStatus } from 'lib/types/mission.types'
import { FormattingHelper } from 'lib/utils/formatting.helper'

import useCurrentScoreByQaId from 'hooks/qualityAssssments/useCurrentScoreByQaId.hooks'

import { MissionProgress } from 'components/composites/Missions/MissionProgress/MissionProgress.component'
import { Heading, Paragraph } from 'components/primitives/Text'

interface Props {
  mission: Mission
  showChanges?: boolean
  coworkerId: string
}

const MissionQaProgress = ({ mission, coworkerId, showChanges }: Props) => {
  const { t } = useTranslation()

  const selectedTarget = showChanges ? mission.changes.target : mission.target

  const currentScore = useCurrentScoreByQaId(mission.qaId, coworkerId)

  const score =
    mission.status === MissionStatus.Completed ? mission.completedScore ?? NaN : currentScore

  return (
    <>
      <Flex direction={'row'} align={'center'}>
        <Tooltip tooltipText={t('features.mission.preview.initial-description')}>
          <Flex mr={space50} direction={'column'} align={'center'}>
            <Paragraph>{t('features.mission.preview.initial')}</Paragraph>
            <Heading>{FormattingHelper.displayNumber(mission.startedScore ?? NaN)}</Heading>
          </Flex>
        </Tooltip>

        <Tooltip
          tooltipText={t('features.mission.preview.current-description')}
          className="progress-tooltip"
        >
          <MissionProgress
            currentScore={score}
            isCompleted={mission.status === MissionStatus.Completed}
            targetScore={mission.target}
            startScore={mission.startedScore ?? NaN}
          />
        </Tooltip>

        <Tooltip tooltipText={t('features.mission.preview.target-description')}>
          <Flex ml={space50} direction={'column'} align={'center'}>
            <Paragraph>{t('features.mission.preview.target')}</Paragraph>
            <Heading>{FormattingHelper.displayNumber(selectedTarget)}</Heading>
          </Flex>
        </Tooltip>
      </Flex>
      {mission.startedScore && score < mission.startedScore && (
        <InlineMessage
          style={{ marginTop: space100 }}
          variant="cautionary"
          title={t('features.mission.progress.current-lower-than-initial', {
            score: FormattingHelper.displayNumber(score),
          })}
        />
      )}
    </>
  )
}

export default MissionQaProgress
