import { QaMetricId } from 'config/domain/qa.config'

import { apiSlice } from 'state/slices/api/api.slice'

export type Translations = {
  translations: TranslationObject
  languageCode: string
}

export type TranslationObject = {
  [key in QaMetricId]: TranslationEntry // Review note: Should we use key: string or key in QaMetricId?
}

export type TranslationEntry = {
  questionId: string
  assessmentCriteria?: string[]
  title: string
}

const translationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchLanguage: builder.query<Translations, string>({
      query: (languageCode) => `/translations/${languageCode}`,
      providesTags: (result) => [{ type: 'Translations', language: result?.languageCode }],
    }),
  }),
})

export const { useFetchLanguageQuery } = translationSlice
