import { GridVariant } from './GridLayout.types'

const halfPageSpan = { base: 12, md: 12, lg: 6, xl: 6 } // Acc to PR commit suggestion
const fullPageSpan = { base: 12, md: 12, lg: 12, xl: 12 }
const smallerSpan = { base: 12, md: 12, lg: 4, xl: 4 } // OR LG: 5
const largerSpan = { base: 12, md: 12, lg: 8, xl: 8 } // OR LG: 7

export const getSpanFromVariant = (variant: GridVariant) => {
  switch (variant) {
    case GridVariant.Half:
      return halfPageSpan
    case GridVariant.FullLine:
      return fullPageSpan
    case GridVariant.OneSidedSmall:
      return smallerSpan
    case GridVariant.OneSidedLarge:
      return largerSpan
    default:
      return fullPageSpan
  }
}
