import SSRIcon from '@ingka/ssr-icon'
import document from '@ingka/ssr-icon/paths/document'
import documentPencil from '@ingka/ssr-icon/paths/document-pencil'
import orderedList from '@ingka/ssr-icon/paths/ordered-list'
import { Stepper } from '@mantine/core'
import { t } from 'i18next'

type CoachingGuidedFlowStepperProps = {
  activeStep: number
  setActiveStep: (activeStep: number) => void
}

const CoachingSessionFooterStepper: React.FC<CoachingGuidedFlowStepperProps> = ({
  activeStep,
  setActiveStep,
}) => {
  const steps = [
    {
      icon: <SSRIcon paths={orderedList} />,
      completedIcon: <SSRIcon paths={orderedList} />,
      label: t('features.coaching-session.review.title'),
    },
    {
      icon: <SSRIcon paths={document} />,
      completedIcon: <SSRIcon paths={document} />,
      label: t('features.coaching-session.mission-draft.title'),
    },
    {
      icon: <SSRIcon paths={documentPencil} />,
      completedIcon: <SSRIcon paths={documentPencil} />,
      label: t('features.coaching-session.summary.title'),
    },
  ]

  return (
    <Stepper active={activeStep} onStepClick={setActiveStep} iconSize={42} size="md" miw="35%">
      {steps.map((step, index) => {
        return (
          <Stepper.Step
            key={index}
            icon={step.icon}
            completedIcon={step.completedIcon}
            label={step.label}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          ></Stepper.Step>
        )
      })}
    </Stepper>
  )
}

export default CoachingSessionFooterStepper
