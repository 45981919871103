import { BadgeDefinition } from 'lib/types/badge.types'

import { apiSlice } from 'state/slices/api/api.slice'

const badgeSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchBadges: builder.query<BadgeDefinition[], void>({
      query: () => '/badges',
      providesTags: ['Badge'],
    }),
    createBadge: builder.mutation<BadgeDefinition, BadgeDefinition>({
      query: (badge) => ({
        url: '/badges',
        method: 'POST',
        body: badge,
      }),
      invalidatesTags: ['Badge'],
    }),
    editBadge: builder.mutation<BadgeDefinition, BadgeDefinition>({
      query: (badge) => ({
        url: `/badges/${badge.badgeDefinitionId}`,
        method: 'PUT',
        body: badge,
      }),
      invalidatesTags: ['Badge'],
    }),
  }),
})

export const { useFetchBadgesQuery, useCreateBadgeMutation, useEditBadgeMutation } = badgeSlice
