/* eslint-disable react-hooks/exhaustive-deps */
import './EmbedReport.css'
import Button from '@ingka/button'
import InlineMessage from '@ingka/inline-message'
import lock from '@ingka/ssr-icon/paths/lock'
import { Box } from '@mantine/core'
import { skipToken } from '@reduxjs/toolkit/query'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { Embed, Report, service } from 'powerbi-client'
import { PowerBIEmbed } from 'powerbi-client-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { FormattingHelper } from 'lib/utils/formatting.helper'

import { useMSAccessToken } from 'hooks/users/useMSAuthWrapper'

import { useFetchTeamQuery } from 'state/slices/api'
import { useCoworker } from 'state/slices/api/api.hooks'

import { spacing100, spacing150 } from 'styles/tokens/insikt.tokens'

import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import LoadingBar from '../../composites/Shared/LoadingBar/LoadingBar.component'
import {
  LOADING_STEP_COUNT,
  LoadingSteps,
  NAV_BUTTON_HEADER,
  PAGE_SWAP_HEADER,
  POWER_BI_SYNC_TIMEOUT,
} from './EmbedReport.constants'
import { ReportError, useReloadReport, useReportNavigationMap } from './EmbedReport.hooks'
import {
  EmbedReportProps,
  NavigationHistory,
  PowerBiReportMeta,
  SlicerState,
} from './EmbedReport.types'
import { cleanString, setInsiktCoworker, setSlicersFromPrevious } from './EmbedReport.utils'
import ReportBreadcrumbs from './ReportBreadcrumbs/ReportBreadcrumbs.component'

/**
 * Before working with this function, take a look at README.md in this folder!
 * Handles all functionality necessary for the PowerBI embedding and analitics of that. Primarly based on
 * functionalities from power-bi-client library. Can get quite hacky as it is not the most obvious thing to work with.
 *
 * @param props - contains the name of the initial report and current page title (for analitics purposes)
 * @returns Embed container with all functionalities.
 */
const EmbedReport: React.FC<EmbedReportProps> = ({ reportName, pageTitle, teamNameProp }) => {
  const { t } = useTranslation()
  const { coworkerId, teamleadId } = useParams()
  const coworkerData = useCoworker(coworkerId ?? skipToken).data
  const teamData = useFetchTeamQuery(coworkerData?.teamId ?? skipToken).data

  const navigationMap = useReportNavigationMap()

  const isDev = process.env.REACT_APP_ENV === 'dev'

  const teamName = teamNameProp ?? teamData?.name

  const [report, setReport] = useState<Report | undefined>()
  const [slicerStates, setSlicerStates] = useState<SlicerState[]>([])
  const [readyForLogin, setReadyForLogin] = useState(false)
  const [reportRendered, setReportRendered] = useState(false)
  const [reportFullyPrepared, setReportFullyPrepared] = useState(false)
  const msAccessToken = useMSAccessToken(readyForLogin)
  const shouldAskForLogin = !readyForLogin && !msAccessToken

  const [loadingInfo, setLoadingInfo] = useState({
    stepNumber: LoadingSteps.Auth,
    label: 'features.embedded-report.loading.auth',
  })

  useEffect(() => {
    if (navigationMap && navigationMap.size !== 0 && msAccessToken) {
      isDev && console.time('initialLoad')
      navigateToReport(reportName, true)
    }
  }, [navigationMap, msAccessToken])

  const [reportMeta, setReportMeta] = useState<PowerBiReportMeta>()

  const [navigationHistory, setNavigationHistory] = useState<NavigationHistory[]>([])

  const analytics = getAnalytics()

  const { reportConfiguration, reportError, isLoading } = useReloadReport(msAccessToken, reportMeta)

  const eventHandlersMap: Map<
    string,
    (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) => void | null
  > = new Map([
    [
      'rendered',
      () => {
        setReportRendered(true)
      },
    ],
    [
      'loaded',
      () => {
        isDev && console.time('Rendering')
        isDev && console.timeEnd('Data Load')
        setLoadingInfo({
          stepNumber: LoadingSteps.Loading,
          label: 'features.embedded-report.loading.load',
        })
      },
    ],
    [
      'error',
      (event?: service.ICustomEvent<any>) => {
        // TODO: Remove from prod?
        if (event) {
          console.error(event.detail)
        }
      },
    ],
    [
      'dataHyperlinkClicked',
      (event) => {
        const url: string = event?.detail?.url ?? ''
        logEvent(analytics, 'interaction-link-clicked', {
          description:
            'Custom event for tracking when a teamlead opens an interaction link from Power BI (either from one solution or dialog)',
          coworkerId: coworkerId,
          teamleadId,
          openedFromPage: pageTitle,
          reportName: navigationHistory.at(-1)?.name,
          pageName: event?.detail?.page?.displayName,
          url,
          interactionId: FormattingHelper.extractInteractionIdFromLink(url) as string,
        })
      },
    ],
  ])

  const handleHistoryUpdate = (newHistoryEntry: NavigationHistory) => {
    // Find if the report name is already in the history
    const index = navigationHistory.findIndex(
      (historyName) => historyName.displayName === newHistoryEntry.displayName
    )
    if (index === -1) {
      setNavigationHistory((prev) => [...prev, newHistoryEntry])
    } else {
      setNavigationHistory((prev) => prev.slice(0, index + 1))
    }
  }

  const retrieveAndLogReport = (reportName: string) => {
    // This should not happen, but maybe just return without the error. Should help debugging tho.
    if (!navigationMap) {
      throw new Error('Navigation map is missing - cannot navigate!')
    }

    const newReportMeta = navigationMap.get(reportName)
    if (!newReportMeta) {
      console.error(`Could not find nav data for the report named: "${reportName}"`)
      return
    }

    logAndHistoryUpdate(reportName, newReportMeta.displayName)
    return newReportMeta
  }

  const logAndHistoryUpdate = (cleanReportName: string, displayName: string) => {
    // Send event to analytics about opening a new report
    logEvent(analytics, 'power-bi-opened', {
      description:
        'Custom event for tracking when a teamlead opens an embbeded report for a coworker',
      coworkerId: coworkerId,
      teamleadId,
      openedFromPage: pageTitle,
      reportName: cleanReportName,
    })

    handleHistoryUpdate({
      displayName: displayName,
      name: cleanReportName,
    })
  }

  const handleBreadcrumbsClick = async (reportName: string) => {
    if (reportName === 'sales-dashboard') {
      await switchPage('Sales Dashboard')
      return
    }
    await navigateToReport(reportName)
  }
  /**
   * Performs lookup for ReportNavigation in the NavigationMap, sets history and state, allowing us for a reload.
   * @param newReportName the name of the report to which navigate
   * @returns
   */
  const navigateToReport = async (newReportName: string, initialLoad = false) => {
    isDev && console.time('reportSwap')
    const cleanReportName = cleanString(newReportName)

    const newReportMeta = retrieveAndLogReport(cleanReportName)
    if (!newReportMeta) {
      return
    }

    isDev && console.time('Data Load')
    setLoadingInfo({
      stepNumber: LoadingSteps.Data,
      label: 'features.embedded-report.loading.report-data',
    })

    // Save current slicer state for future insertion
    if (!initialLoad) {
      setSlicerStates(await grabAllSlicers())
    }

    setReportRendered(false)
    setReportFullyPrepared(false)

    setReportMeta(newReportMeta)
  }

  // Get the state of all the slicer before the navigation
  const grabAllSlicers = async (): Promise<SlicerState[]> => {
    if (!report) throw new Error('Could not grab state of the slicers - Report is undefined!')
    const page = await report.getActivePage()
    const slicers = await page.getSlicers()
    const slicerPromises: Promise<SlicerState>[] = slicers.map(async (slicer) => {
      const visual = await page.getVisualByName(slicer.name)
      const state = await visual.getSlicerState()
      return {
        state,
        name: slicer.title,
      }
    })
    return await Promise.all(slicerPromises)
  }

  const switchPage = async (key: string) => {
    setReportFullyPrepared(false)
    const cleanReportName = cleanString(key)
    retrieveAndLogReport(cleanReportName)
    isDev && console.time('pageSwap')

    if (!report) {
      return
    }
    report.getPages().then((pages) => {
      const page = pages.find((page) => page.displayName === key)
      if (!page) {
        console.error('Could not find page with name: ', key)
      } else {
        page.setActive().then(() => {
          setTimeout(() => {
            setReportFullyPrepared(true)
            isDev && console.timeEnd('pageSwap')
          }, 1000)
        })
      }
    })
  }

  const handleButtonClick = async (event: any) => {
    const title: string = event?.detail?.title
    if (!title) return

    if (title.startsWith(PAGE_SWAP_HEADER)) {
      const key = title.split(':')[1]
      await switchPage(key)
      return
    }

    if (title.startsWith(NAV_BUTTON_HEADER)) {
      const key = title.split(':')[1]
      await navigateToReport(key)
      return
    }
  }

  useEffect(() => {
    const prefilteringFunction = async () => {
      if (!report) {
        return
      }
      // Remove the click navigation while the slicers are loading to prevent instability of the Power BI iFrame.
      report.off('buttonClicked')
      isDev && console.timeEnd('Rendering')
      isDev && console.time('Prefiltering')

      setLoadingInfo({
        stepNumber: LoadingSteps.Render,
        label: 'features.embedded-report.loading.render',
      })

      const page = await report.getActivePage()
      await setInsiktCoworker(page, teamName, coworkerId)
      await setSlicersFromPrevious(page, slicerStates)
      // Once everything is done, readd the button click
      report.on('buttonClicked', (event) => handleButtonClick(event))
      // Adding a timeot to give Power BI time to sync the data after slicers update (to get rid of the spinning wheels)
      setTimeout(() => {
        setReportFullyPrepared(true)
        isDev && console.timeEnd('initialLoad')
        isDev && console.timeEnd('Prefiltering')
        isDev && console.timeEnd('reportSwap')
      }, POWER_BI_SYNC_TIMEOUT)
    }

    if (reportRendered) {
      prefilteringFunction().catch((error) => console.error('Error prefiltering: ', error))
    }
  }, [reportRendered, report])

  if (reportError === ReportError.Unauthorised) {
    return (
      <InlineMessage variant="cautionary" title={t('features.embedded-report.errors.no-auth')} />
    )
  }
  if (reportError === ReportError.Generic) {
    return <UnexpectedErrorMessage />
  }

  return (
    <Box mt={spacing100}>
      {shouldAskForLogin ? (
        <Button
          text={t('features.embedded-report.actions.login')}
          type="emphasised"
          onClick={() => setReadyForLogin(true)}
          ssrIcon={lock}
          style={{ marginTop: spacing150 }}
        />
      ) : (
        <>
          <ReportBreadcrumbs
            navigateToReport={handleBreadcrumbsClick}
            navigationHistory={navigationHistory}
          />
          {!reportFullyPrepared && (
            <LoadingBar
              currentStep={loadingInfo.stepNumber}
              labelTranslationKey={loadingInfo.label}
              maxStep={LOADING_STEP_COUNT}
            />
          )}
          {
            /* We need to use visibility here instead of the condition && <Comp>, as we want the report to be created, just hidden - otherwise it will not begin to render */
            !isLoading && (
              <div
                style={{
                  visibility: reportFullyPrepared ? 'visible' : 'hidden',
                }}
              >
                <PowerBIEmbed
                  embedConfig={reportConfiguration}
                  cssClassName="embeded-report-container"
                  eventHandlers={eventHandlersMap}
                  getEmbeddedComponent={(embedObject: Embed) => {
                    setReport(embedObject as Report)
                  }}
                />
              </div>
            )
          }
        </>
      )}
    </Box>
  )
}

export default EmbedReport
