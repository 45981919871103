import { Trans, useTranslation } from 'react-i18next'

import { FullscreenCenter } from 'components/layout/FullScreenCenter'
import { Paragraph } from 'components/primitives/Text'

const GuestPage: React.FC = () => {
  const { t } = useTranslation()
  return (
    <>
      <FullscreenCenter>
        <Paragraph>
          {t('pages.guest.message-1')}
          <br />
          <br />
          <Trans i18nKey="pages.guest.message-2">
            If you believe this is an error, please reach out to us on
            <a href="https://teams.microsoft.com/l/team/19%3aRQ5meDGPGb_a4y_-xzZU0lsjHWePLYPMEku8dQLjK-41%40thread.tacv2/conversations?groupId=152e29cf-423f-4115-b34d-e7ddf18e96ae&tenantId=720b637a-655a-40cf-816a-f22f40755c2c">
              Teams
            </a>
            .
          </Trans>
        </Paragraph>
      </FullscreenCenter>
    </>
  )
}

export default GuestPage
