import { useLocation, useParams } from 'react-router-dom'

import { UserRole } from 'lib/types/user.types'

export interface Paths {
  rootPath: string
  currentPath: string
  specialPath?: string
  teamleadPath?: string
  coworkerPath?: string
}

const specialPaths = new Set(['admin', 'qs', 'demo'])

// TODO: Unify?
const isDev = process.env.REACT_APP_ENV === 'dev'

const getStarterPath = (pathname: string) => {
  const path = pathname.split('/')[1] ?? ''
  if (specialPaths.has(path)) {
    return path
  }
  return ''
}

const getPathObj = (
  pathname: string,
  teamleadId: string | undefined,
  coworkerId: string | undefined
): Paths => {
  let starterPath = getStarterPath(pathname)
  if (starterPath) {
    return {
      rootPath: `/${starterPath}`,
      specialPath: `/${starterPath}`,
      currentPath: pathname,
    }
  }

  return {
    rootPath: teamleadId ? `/tl/${teamleadId}` : `/cw/${coworkerId}`,
    currentPath: pathname,
  }
}

/**
 * @description
 * This hook is used to segment the current location (URL) into sections, allowing us to identify
 * if the user is in the admin, teamlead or coworker section. This allows us to link correctly when
 * a user may be in multiple sections.
 *
 * Be aware that the result returned only returns the root path of the given section, not the full path
 * @example
 * HOW THIS WORKS:
 * // currentPath: you are here!
 * // rootPath: Base path for the current section/user time - use for errors that cannot be recovered - navigate to the landing page
 * // specialPart: Tells you if this is an impersonation path (admin, qs, demo)
 * // teamleadPath: Get back to teamlead base
 * // coworkerPath: Get back to coworker base - both for coworkers and teamlead.
 *  -- IF existing anything connected to individual coworker - such as CS or QA - use this one ^^^ !
 * @example
 * // currentPath: /admin/tl/TILEA/coworkers/SUPOR/missions
 * // rootPath: /admin
 * // specialPart: /admin
 * // teamleadPath: /admin/tl/TILEA
 * // coworkerPath: /admin/tl/TILEA/coworkers/SUPOR
 *  * @example
 * // currentPath: /tl/TILEA/coworkers/SUPOR/missions
 * // rootPath: /tl/TILEA
 * // specialPart: undefined
 * // teamleadPath: /tl/TILEA
 * // coworkerPath: /tl/TILEA/coworkers/SUPOR
 *  * @example
 * // currentPath: /qs/cw/SUPOR/coaching-sessions
 * // rootPath: /qs
 * // specialPart: /qs
 * // teamleadPath: undefined
 * // coworkerPath: /qs/cw/SUPOR
 */
export const useSectionPaths = (): Paths => {
  const { coworkerId, teamleadId } = useParams()

  const pathname = useLocation().pathname

  const pathObj: Paths = getPathObj(pathname, teamleadId, coworkerId)

  pathObj.currentPath = pathname

  if (teamleadId) {
    pathObj.teamleadPath = `${pathObj.specialPath ?? ''}/tl/${teamleadId}`
    const coworkerPart = coworkerId ? `${pathObj.teamleadPath}/coworkers/${coworkerId}` : ''
    pathObj.coworkerPath = coworkerPart
  } else if (coworkerId) {
    pathObj.coworkerPath = `${pathObj.specialPath ?? ''}/cw/${coworkerId}`
  }

  return pathObj
}

/** Returns the type of the current page */
export const useCurrentPageType = () => {
  const { coworkerPath, teamleadPath, currentPath } = useSectionPaths()
  if (teamleadPath) {
    return UserRole.TeamLead
  }
  if (coworkerPath) {
    return UserRole.Coworker
  }

  if (isDev) throw new Error(`Cannot match a path in a page type: ${currentPath}`)

  return undefined
}
