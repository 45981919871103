import { FormattingHelper } from 'lib/utils/formatting.helper'

import useCurrentScoreByQaId from 'hooks/qualityAssssments/useCurrentScoreByQaId.hooks'
import usePreviousScoreByQaId from 'hooks/qualityAssssments/usePreviousScoreByQaId.hooks'

type QaScoreProps = {
  coworkerId: string
  qaId: string
  type: 'previous' | 'current'
}
const QaScore = ({ coworkerId, qaId, type }: QaScoreProps) => {
  const currentScore = useCurrentScoreByQaId(qaId, coworkerId)
  const previousScore = usePreviousScoreByQaId(qaId, coworkerId)

  const score =
    type === 'current'
      ? FormattingHelper.displayNumber(currentScore)
      : FormattingHelper.displayNumber(previousScore)

  return <>{score}</>
}

export default QaScore
