import Button from '@ingka/button'
import { LoadingBall } from '@ingka/loading'
import SSRIcon from '@ingka/ssr-icon'
import note from '@ingka/ssr-icon/paths/note'
import pencil from '@ingka/ssr-icon/paths/pencil'
import { Flex, Group, Stack } from '@mantine/core'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { CoachingSession } from 'lib/types/coachingSession.types'

import { useCreateCoworkerNote } from 'state/slices/api/modules/coachingSession'

import { spacing25, spacing50, spacing75 } from 'styles/tokens/insikt.tokens'

import { LoadingSkeleton } from 'components/composites/Shared/LoadingSkeleton/LoadingSkeleton.component'
import { Widget } from 'components/composites/Shared/Widget'
import { SubHeading } from 'components/primitives/Text'
import { HelperText } from 'components/primitives/Text/Text.component'
import { RTFTextEditor } from 'components/shared/RTFTextEditor'
import { useTextEditor } from 'components/shared/RTFTextEditor/useTextEditor.hooks'

interface CoworkerCreateNoteProps {
  coworkerId: string
  coachingSession: CoachingSession
}

const CoworkerCreateNote: React.FC<CoworkerCreateNoteProps> = ({ coworkerId, coachingSession }) => {
  const { t } = useTranslation()

  const initialNote = coachingSession?.coworkerNote?.note || ''
  const placeholderText = t('features.coworker-note.placeholder')
  const { value, editor, isEditable, setIsEditable } = useTextEditor(initialNote, placeholderText)

  const [createCoworkerNote, { isLoading: isCreatingNote, isSuccess: isCreatedNote }] =
    useCreateCoworkerNote()

  if (!editor) return null

  const toggleEditability = () => {
    setIsEditable((prev) => !prev)
    editor.setOptions({ editable: !isEditable })
  }

  const handleCreateNote = () => {
    createCoworkerNote({
      coworkerId: coworkerId,
      coachingSessionId: coachingSession.id,
      coworkerNote: {
        note: value,
        createdDate: dayjs(new Date()).format('YYYY-MM-DD'),
        createdBy: coworkerId,
        isAvailableToTeamlead: coachingSession.draftCompleted ? true : false,
      },
    })
    if (isCreatedNote) {
      console.log('Coworker created note')
    }
    toggleEditability()
  }

  if (isCreatingNote) return <LoadingSkeleton height={'25vh'} />

  return (
    <>
      <Widget border>
        <Flex justify="space-between">
          <Stack align="flex-start" gap={spacing25}>
            <Group gap={spacing50}>
              <SSRIcon paths={note} />
              <SubHeading>{t('features.coworker-note.title')}</SubHeading>
            </Group>
            <HelperText>{t('features.coworker-note.subtitle')}</HelperText>
          </Stack>
        </Flex>

        <Stack my={spacing75}>
          {isCreatingNote ? (
            <LoadingBall style={{ margin: 'auto' }} />
          ) : (
            <RTFTextEditor
              editor={editor}
              backgroundColor
              includesCheckboxes={false}
              className="coworker-note-editor"
            />
          )}
        </Stack>
        {isEditable ? (
          <Button
            small
            type="primary"
            text={t('common.actions.save-changes')}
            ssrIcon={pencil}
            onClick={handleCreateNote}
            loading={isCreatingNote}
          />
        ) : (
          <Button
            small
            type="emphasised"
            text={t('common.actions.edit-comment')}
            ssrIcon={pencil}
            onClick={toggleEditability}
          />
        )}
      </Widget>
    </>
  )
}

export default CoworkerCreateNote
