import FormField from '@ingka/form-field'
import { useEffect } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Coworker } from 'lib/types/coworker.types'

import { SubHeading } from 'components/primitives/Text'
import RTFTextEditor from 'components/shared/RTFTextEditor/RTFTextEditor.component'
import { useTextEditor } from 'components/shared/RTFTextEditor/useTextEditor.hooks'

import { MissionDraftFormFields } from '../../MissionDraftEditor.types'

type Props = {
  form: UseFormReturn<MissionDraftFormFields> & {
    watch: (field: keyof MissionDraftFormFields) => any
  }
  coworker: Coworker
}

const CoachingPointersStep: React.FC<Props> = ({ form, coworker }) => {
  const { control, formState } = form
  const { t } = useTranslation()
  const initialCoachingPointers = form.getValues('coachingPointers') || ''

  const placeholderText = t('features.mission.create-mission.fields.coaching-pointers.placeholder')
  const { editor, setIsEditable, value } = useTextEditor(initialCoachingPointers, placeholderText)

  // Since the editor in coaching pointers doesn't have a edit/save handler,
  // we add an onClick even to make it editable on clicking the field

  const handleEditOnClick = () => {
    if (editor) {
      editor.setOptions({ editable: true })
      setIsEditable(true)
    }
  }

  useEffect(() => {
    form.setValue('coachingPointers', value)
  }, [value, form])

  if (!editor) return null

  return (
    <>
      <SubHeading>{t('features.mission.create-mission.steps.coaching-pointers.title')}</SubHeading>

      <Controller
        name="coachingPointers"
        control={control}
        rules={{
          required: {
            value: true,
            message: t('features.mission.create-mission.fields.coaching-pointers.errors.missing'),
          },
          validate: (value) => {
            return `${value} is valid coaching pointers.`
          },
        }}
        render={({ field }) => {
          return (
            <FormField
              data-testid="form-field-coachingPointers"
              shouldValidate={formState.errors.coachingPointers ? true : false}
              valid={formState.errors.coachingPointers?.type === 'validate'}
              validation={{
                msg: formState.errors.coachingPointers?.message,
                id: 'form-error-coaching-pointers',
                type: 'error',
              }}
              onClick={() => {
                handleEditOnClick()
              }}
            >
              <RTFTextEditor
                editor={editor}
                className="coaching-pointer-editor"
                includesCheckboxes
              />
            </FormField>
          )
        }}
      />
    </>
  )
}

export default CoachingPointersStep
