import Button from '@ingka/button'
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal'
import SSRIcon from '@ingka/ssr-icon'
import checkmark from '@ingka/ssr-icon/paths/checkmark'
import cleanSparkles from '@ingka/ssr-icon/paths/clean-sparkles'
import pencil from '@ingka/ssr-icon/paths/pencil'
import { space50 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { colourNeutralGrey200, colourSemanticPositive } from 'styles/tokens/oldSkapa.tokens'

import { Heading, Paragraph, SubHeading } from 'components/primitives/Text'

interface Props {
  show: boolean
  close: () => void
}

const MissionQaMetricsInfo = ({ show, close }: Props) => {
  const { t } = useTranslation()
  return (
    <Modal escapable visible={show} handleCloseBtn={close}>
      <Sheets
        header={
          <ModalHeader>
            <Heading>
              {t('features.mission.create-mission.qa-metrics-table.information.title')}
            </Heading>
          </ModalHeader>
        }
        footer={
          <ModalFooter>
            <Button hidden />
          </ModalFooter>
        }
      >
        <ModalBody>
          <div style={{ marginBottom: space50 }}>
            <SubHeading>
              {t('features.mission.create-mission.qa-metrics-table.table-headers.previous-score')}
            </SubHeading>
            <Paragraph>
              {t('features.mission.create-mission.qa-metrics-table.information.previous-score')}
            </Paragraph>
          </div>

          <div style={{ marginBottom: space50 }}>
            <SubHeading>
              {t('features.mission.create-mission.qa-metrics-table.table-headers.current-score')}
            </SubHeading>
            <Paragraph>
              {t('features.mission.create-mission.qa-metrics-table.information.current-score')}
            </Paragraph>
          </div>

          <div>
            <SubHeading>
              {t('features.mission.create-mission.qa-metrics-table.table-headers.draft-status')}
            </SubHeading>
            <Paragraph style={{ marginBottom: space50 }}>
              {t('features.mission.create-mission.qa-metrics-table.information.status')}
              <Paragraph $bold style={{ color: colourSemanticPositive, display: 'inline' }}>
                {t('features.mission.create-mission.qa-metrics-table.information.status-highlight')}
              </Paragraph>
            </Paragraph>
            <Flex direction={'column'} gap={space50}>
              <Flex dir="row" align={'center'} gap={space50}>
                <SSRIcon
                  paths={cleanSparkles}
                  style={{
                    backgroundColor: colourNeutralGrey200,
                    padding: '5px',
                    width: '35px',
                    height: '35px',
                    borderRadius: '50%',
                  }}
                />
                <Paragraph>
                  {t(
                    'features.mission.create-mission.qa-metrics-table.information.new-mission-draft'
                  )}
                </Paragraph>
              </Flex>
              <Flex dir="row" align={'center'} gap={space50}>
                <SSRIcon
                  paths={pencil}
                  style={{
                    backgroundColor: colourNeutralGrey200,
                    padding: '5px',
                    width: '35px',
                    height: '35px',
                    borderRadius: '50%',
                  }}
                />
                <Paragraph>
                  {t(
                    'features.mission.create-mission.qa-metrics-table.information.active-mission-changes'
                  )}
                </Paragraph>
              </Flex>
              <Flex dir="row" align={'center'} gap={space50}>
                <SSRIcon
                  paths={checkmark}
                  style={{
                    backgroundColor: colourNeutralGrey200,
                    padding: '5px',
                    width: '35px',
                    height: '35px',
                    borderRadius: '50%',
                  }}
                />
                <Paragraph>
                  {t(
                    'features.mission.create-mission.qa-metrics-table.information.mission-suggested-to-complete'
                  )}
                </Paragraph>
              </Flex>
            </Flex>
          </div>
        </ModalBody>
      </Sheets>
    </Modal>
  )
}

export default MissionQaMetricsInfo
