export type CountryFlag = {
  [key: string]: string
}

export const countryFlags: CountryFlag = {
  ES: '🇪🇸',
  SE: '🇸🇪',
  NO: '🇳🇴',
  CA: '🇨🇦',
  DK: '🇩🇰',
  CH: '🇨🇭',
  FI: '🇫🇮',
  FR: '🇫🇷',
  DE: '🇩🇪',
  GB: '🇬🇧',
  IE: '🇮🇪',
  IT: '🇮🇹',
  PL: '🇵🇱',
  PT: '🇵🇹',
  SRB: '🇷🇸',
  HRV: '🇭🇷',
  RO: '🇷🇴',
  SI: '🇸🇮',
  AU: '🇦🇺',
  BE: '🇧🇪',
  NL: '🇳🇱',
  US: '🇺🇸',
  BR: '🇧🇷',
  AR: '🇦🇷',
  CL: '🇨🇱',
  MX: '🇲🇽',
  CO: '🇨🇴',
  PE: '🇵🇪',
  CR: '🇨🇷',
  PA: '🇵🇦',
  GT: '🇬🇹',
  HN: '🇭🇳',
  SV: '🇸🇻',
  NI: '🇳🇮',
  EC: '🇪🇨',
  VE: '🇻🇪',
  BO: '🇧🇴',
}

// Get country flag emoji based on coworker?.country ISO code
export const getCountryFlag = (countryCode: string): string => {
  return countryFlags[countryCode] ?? ''
}
