import InlineMessage from '@ingka/inline-message'
import { useTranslation } from 'react-i18next'

import { CoachingSession } from 'lib/types/coachingSession.types'

import { useMissionsForCoachingSession } from 'hooks/coachingSessions'

import { useCoworker } from 'state/slices/api'

import { SessionCommentEditor } from 'components/features/CoachingSessions/CoachingSessionEditor/SessionCommentEditor'
import MissionDraftSkeleton from 'components/features/Missions/MissionDraftEditor/MissionDraftSkeleton/MissionDraftSkeleton.component'
import { NumberedHeader } from 'components/primitives/NumberedHeader'

import { MissionDiffs, MissionDiffsSummary } from './MissionDiffs'

type SummaryCoachingSessionProps = {
  coworkerId: string
  coachingSession: CoachingSession
}

const Summary: React.FC<SummaryCoachingSessionProps> = ({ coworkerId, coachingSession }) => {
  const { t } = useTranslation()

  const {
    data: sessionMissions,
    isLoading: loadingMissions,
    isError: errorMissions,
    isSuccess: successMissions,
  } = useMissionsForCoachingSession({
    coworkerId,
    coachingSessionId: coachingSession.id,
  })

  const { data: coworker } = useCoworker(coworkerId)

  return (
    <>
      <NumberedHeader
        step={3}
        title={t('features.coaching-session.summary-commit.title')}
        subtitle={t('features.coaching-session.summary-commit.subtitle')}
      />
      {errorMissions && <InlineMessage variant="cautionary" />}

      {/* Mission diffs summary/new state */}
      <MissionDiffsSummary coworkerId={coworkerId} missions={sessionMissions} />

      {/* Session comment with the RTF editor */}
      {coworker && (
        <>
          <SessionCommentEditor coworker={coworker} coachingSession={coachingSession} />
        </>
      )}

      {/* Mission recap in Summary and Commit */}
      {loadingMissions && <MissionDraftSkeleton />}
      {successMissions && (
        <>
          <MissionDiffs missions={sessionMissions} coworkerId={coworkerId} />
        </>
      )}
    </>
  )
}

export default Summary
