import InlineMessage from '@ingka/inline-message'
import { Box, BoxProps } from '@mantine/core'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'
import { CustomOtherEventKeys } from 'lib/utils/analytics/analytics.types'

/** Component used to render an error message for when an error is not expected to occur. */
const UnexpectedErrorMessage: React.FC<BoxProps> = (boxProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    analyticsHelper.createCustomOtherEvent(CustomOtherEventKeys.Error)
  }, [])

  return (
    <Box maw="400px" {...boxProps}>
      <InlineMessage
        variant="negative"
        title={t('common.errors.unexpected-error.title')}
        body={t('common.errors.unexpected-error.body')}
      />
    </Box>
  )
}

export default UnexpectedErrorMessage
