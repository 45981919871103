import { Mission } from 'lib/types/mission.types'

import { apiSlice } from 'state/slices/api/api.slice'

import { CreateMissionArgs, SuggestCompleteArgs, UpdateMissionArgs } from './mission.slice.types'

export const missionSlice = apiSlice.injectEndpoints({
  // endpoints utlizing the new mission model
  endpoints: (builder) => ({
    findMissionByIdAndCoworkerId: builder.query<Mission, { coworkerId: string; missionId: string }>(
      {
        query: ({ coworkerId, missionId }) => `/coworkers/${coworkerId}/missions/${missionId}`,
        providesTags: (result, error, { missionId }) => [{ type: 'Mission', id: missionId }],
      }
    ),

    findMissionsByCoworkerId: builder.query<Mission[], string>({
      query: (coworkerId) => `/coworkers/${coworkerId}/missions`,
      providesTags: (result, error, coworkerId) => [{ type: 'Mission', id: coworkerId }],
    }),

    createMission: builder.mutation<string, CreateMissionArgs>({
      query: ({ coworkerId, mission }) => ({
        url: `/coworkers/${coworkerId}/missions`,
        method: 'POST',
        body: mission,
        responseHandler: 'text',
      }),
      invalidatesTags: (result, error, { coworkerId }) => [{ type: 'Mission', id: coworkerId }],
    }),

    activateMission: builder.mutation<void, { coworkerId: string; missionId: string }>({
      query: ({ coworkerId, missionId }) => ({
        url: `/coworkers/${coworkerId}/missions/${missionId}/activate`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { coworkerId, missionId }) => [
        { type: 'Mission', id: coworkerId },
        { type: 'Mission', id: missionId },
      ],
    }),

    suggestMission: builder.mutation<void, SuggestCompleteArgs>({
      query: ({ coworkerId, missionId }) => ({
        url: `/coworkers/${coworkerId}/missions/${missionId}/suggest`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { coworkerId, missionId }) => [
        { type: 'Mission', id: coworkerId },
        { type: 'Mission', id: missionId },
      ],
    }),

    completeMission: builder.mutation<void, { coworkerId: string; missionId: string }>({
      query: ({ coworkerId, missionId }) => ({
        url: `/coworkers/${coworkerId}/missions/${missionId}/complete`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { coworkerId, missionId }) => [
        { type: 'Mission', id: coworkerId },
        { type: 'Mission', id: missionId },
      ],
    }),

    updateChanges: builder.mutation<void, UpdateMissionArgs>({
      query: ({ coworkerId, missionId, changesInput, metricId }) => ({
        url: `/coworkers/${coworkerId}/missions/${missionId}`,
        method: 'PUT',
        body: { ...changesInput, metricId },
      }),
      invalidatesTags: (result, error, { coworkerId, missionId }) => [
        { type: 'Mission', id: coworkerId },
        { type: 'Mission', id: missionId },
      ],
    }),

    deleteMission: builder.mutation<void, { coworkerId: string; missionId: string }>({
      query: ({ coworkerId, missionId }) => ({
        url: `/coworkers/${coworkerId}/missions/${missionId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { coworkerId, missionId }) => [
        { type: 'Mission', id: coworkerId },
        { type: 'Mission', id: missionId },
      ],
    }),
  }),
})

export const {
  useFindMissionByIdAndCoworkerIdQuery: useMission,
  useFindMissionsByCoworkerIdQuery: useMissions,
  useCreateMissionMutation: useCreateMission,
  useActivateMissionMutation: useActivateMission,
  useSuggestMissionMutation: useSuggestMission,
  useCompleteMissionMutation: useCompleteMission,
  useUpdateChangesMutation: useUpdateChangesMission,
  useDeleteMissionMutation: useDeleteMission,
} = missionSlice
