import { Box } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { TabLayout } from 'components/composites/Shared/TabLayout/TabLayout.component'
import { PageHeader } from 'components/layout/PageHeader'

import { teamleadLandingPageTabs } from './TeamleadLandingPage.constants'

export const TeamleadLandingPage = () => {
  const { t } = useTranslation()
  const { specialPath } = useSectionPaths()

  const backHref = specialPath ? `${specialPath}/users` : undefined
  return (
    <Box data-testid="page-manage-coworkers">
      <PageHeader
        backHref={backHref}
        // TODO: Replace the title with the team name + flag?
        title={t('features.coworker.coworkers-overview.title')}
      />

      <TabLayout tabDefinitions={teamleadLandingPageTabs} />
    </Box>
  )
}
