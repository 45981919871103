import FormField from '@ingka/form-field'
import { LoadingBall } from '@ingka/loading'
import RadioButton from '@ingka/radio-button'
import { space100 } from '@ingka/variables'
import { Box, Stack } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { Locale, locales } from 'config/internationalisation/locales.config'

import { useUser } from 'hooks/users'

import { useLocale, useUserPrefsQuery } from 'state/slices/api/api.hooks'
import { useUpdatePrefsMutation } from 'state/slices/api/modules/user/userPrefs.slice'

import { Caption, Heading } from 'components/primitives/Text'

import { Section } from '../SideMenu.styling'
import * as Styled from './MenuPages.styling'

const LanguageMenuPage = () => {
  const { t } = useTranslation()

  const currentLocale = useLocale()
  const { isLoading: isLoadingUserPrefs } = useUserPrefsQuery()
  const [updatePrefs, { isLoading: isLoadingUpdatePrefs }] = useUpdatePrefsMutation()

  const { data: user } = useUser()
  const { data: prefs } = useUserPrefsQuery()

  const handleLanguageSelected = (locale: Locale) => {
    if (prefs && user) {
      const updatedPrefs = {
        ...prefs,
        locale: locale.isoCode,
      }
      updatePrefs({ userId: user.id, prefs: updatedPrefs })
    }
  }

  const localesAsList = Object.values(locales).sort((a, b) =>
    a.languageName.localeCompare(b.languageName)
  )

  return (
    <Section>
      <Stack gap={space100}>
        <Box>
          <Heading>{t('navigation.sidebar-right.select-language.title')}</Heading>
          <Caption>{t('navigation.sidebar-right.select-language.note')}</Caption>
        </Box>

        <Styled.LanguageChoicesContainer>
          {(isLoadingUpdatePrefs || isLoadingUserPrefs) && (
            <Styled.LoadingBallContainer>
              <LoadingBall />
            </Styled.LoadingBallContainer>
          )}
          {localesAsList.map((locale) => (
            <FormField key={locale.isoCode}>
              <RadioButton
                id={locale.isoCode}
                value={locale.isoCode}
                label={locale.languageName}
                checked={locale.isoCode === currentLocale.isoCode}
                onChange={() => handleLanguageSelected(locale)}
              />
            </FormField>
          ))}
        </Styled.LanguageChoicesContainer>
      </Stack>
    </Section>
  )
}

export default LanguageMenuPage
