import Button from '@ingka/button'
import InlineMessage from '@ingka/inline-message'
import { Stack } from '@mantine/core'
import { useIdle } from '@mantine/hooks'
import { getAnalytics, setUserId } from 'firebase/analytics'
import { useEffect } from 'react'

import { signOut } from 'config/firebase/firebase.config'

import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'

import { useUser } from 'hooks/users'

import { Routing } from 'routes'

import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import { FullscreenCenter } from 'components/layout/FullScreenCenter'
import { Paragraph } from 'components/primitives/Text'

import { useInitData } from './App.hooks'

const App: React.FC = () => {
  const { isLoading, loadingMessage, error } = useInitData()

  const user = useUser()
  const analytics = getAnalytics()
  const isIdle = useIdle(30 * 1000 * 60, { initialState: false })

  useEffect(() => {
    // Ensure that the analytics user id is set after idle peirod of > 30 minutes
    if (!isIdle && user.data) {
      setUserId(analytics, user.data.email)
    }
  }, [isIdle, user, analytics])

  useEffect(() => {
    if (user.data) {
      analyticsHelper.createCustomRootEvent({
        userId: user.data.legacyId,
        role: user.data.role,
      })
    }
  }, [user])

  let BlockingMessage: React.FC | undefined = undefined

  if (error) {
    if (error?.data?.includes('Error while verifying Firebase ID token: NotFoundError:')) {
      BlockingMessage = () => (
        <FullscreenCenter>
          <Stack>
            <InlineMessage
              title={'It appears that your account creation is taking longer than expected.'}
              body={
                'Please wait a couple of seconds and refresh the page. If the error persists for more than few minutes, please contact the administration.'
              }
            />
            <Button type="emphasised" onClick={() => window.location.reload()} text="Refresh" />
          </Stack>
        </FullscreenCenter>
      )
    } else {
      BlockingMessage =
        'status' in error
          ? () => (
              <FullscreenCenter>
                <Stack>
                  <InlineMessage
                    title={
                      <>{error.status === 'PARSING_ERROR' ? error.originalStatus : error.status}</>
                    }
                    body={
                      <>
                        {error.status === 'FETCH_ERROR' || error.status === 'TIMEOUT_ERROR'
                          ? error.error
                          : error.data}
                      </>
                    }
                  />
                  <Button type="emphasised" onClick={() => signOut()} text="Sign out" />
                </Stack>
              </FullscreenCenter>
            )
          : () => (
              <FullscreenCenter>
                <InlineMessage title={error.name} body={error.message} />
                <Paragraph>{error.message}</Paragraph>
              </FullscreenCenter>
            )
    }
  }

  if (isLoading) {
    BlockingMessage = undefined
  }

  return (
    <>
      {BlockingMessage && <BlockingMessage />}
      {isLoading && <LoadingIndicator message={loadingMessage} />}
      {!BlockingMessage && !isLoading && <Routing />}
    </>
  )
}

export default App
