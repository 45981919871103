import { Interaction } from 'lib/types/interaction.types'

import { apiSlice } from 'state/slices/api/api.slice'

const interactionSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findInteractionsByIdAndCoworkerId: builder.query<
      Interaction,
      { interactionId: string; coworkerId: string }
    >({
      query: ({ interactionId, coworkerId }) =>
        `/coworkers/${coworkerId}/interactions/${interactionId}`,
      providesTags: (result, _error, arg) =>
        result
          ? [
              {
                type: 'Interaction',
                id: arg?.interactionId,
              },
            ]
          : ['Interaction'],
    }),
    findInteractionsByCoworkerId: builder.query<Interaction[], string>({
      query: (coworkerId) => ({
        url: `/coworkers/${coworkerId}/interactions`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result?.map((interaction) => ({
          type: 'Interaction',
          id: interaction.id,
        })) ?? ['Interaction'],
    }),
  }),
})

export const {
  useFindInteractionsByIdAndCoworkerIdQuery: useInteraction,
  useFindInteractionsByCoworkerIdQuery: useInteractions,
} = interactionSlice
