import { space50 } from '@ingka/variables'
import { Breadcrumbs } from '@mantine/core'

import { NavigationHistory } from '../EmbedReport.types'

type ReportBreadcrumbsProps = {
  navigateToReport: (name: string, initialLoad: boolean) => void
  navigationHistory: NavigationHistory[]
}

const ReportBreadcrumbs: React.FC<ReportBreadcrumbsProps> = ({
  navigateToReport,
  navigationHistory,
}) => {
  const breadCrumbItems = navigationHistory.map((historyEntry, index) => (
    <div
      className="mock-link"
      key={historyEntry.name}
      onClick={() => {
        if (index === navigationHistory.length - 1) return
        navigateToReport(historyEntry.name, false)
      }}
    >
      {historyEntry.displayName}
    </div>
  ))

  return <Breadcrumbs mb={space50}>{breadCrumbItems} </Breadcrumbs>
}

export default ReportBreadcrumbs
