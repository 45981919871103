import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

export const useBackHref = () => {
  const { specialPath } = useSectionPaths()

  if (specialPath) {
    return `${specialPath}/users`
  }

  return undefined
}
