import { partition } from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Mission, MissionStatus } from 'lib/types/mission.types'
import { IconStatus, MissionHelper } from 'lib/utils/mission.helper'

export const useSplitMissions = (missions: Mission[]): MissionDiffDisplayProps[] => {
  const { t } = useTranslation()

  return useMemo(() => {
    const relevantMissions = missions.filter(
      (mission) => mission.status !== MissionStatus.Completed
    )
    const [suggestedToComplete, incompleteMissions] = partition(
      relevantMissions,
      (mission) => mission.suggestedToComplete
    )
    const [newMissions, changedAndActiveMissions] = partition(
      incompleteMissions,
      (mission) => mission.status === MissionStatus.Draft
    )
    const [changedMission, activeMissions] = partition(changedAndActiveMissions, (mission) =>
      MissionHelper.isMissionModified(mission)
    )

    return [
      {
        missions: suggestedToComplete,
        headerText: t('features.coaching-session.mission-categories.suggested-to-complete.title'),
        note: t('features.coaching-session.mission-categories.suggested-to-complete.note'),
        iconPath: MissionHelper.getIconFromStatus(IconStatus.SuggestedToComplete),
      },
      {
        missions: newMissions,
        headerText: t('features.coaching-session.mission-categories.new-missions.title'),
        note: t('features.coaching-session.mission-categories.new-missions.note'),
        iconPath: MissionHelper.getIconFromStatus(IconStatus.Draft),
      },
      {
        missions: changedMission,
        headerText: t('features.coaching-session.mission-categories.updated-missions.title'),
        note: t('features.coaching-session.mission-categories.updated-missions.note'),
        iconPath: MissionHelper.getIconFromStatus(IconStatus.Modified),
      },
      {
        missions: activeMissions,
        headerText: t('features.coaching-session.mission-categories.active-missions.title'),
        note: t('features.coaching-session.mission-categories.active-missions.note'),
        iconPath: MissionHelper.getIconFromStatus(IconStatus.Active),
      },
    ]
  }, [missions, t])
}

type MissionDiffDisplayProps = {
  missions: Mission[]
  headerText: string
  note: string
  iconPath: any
  visualiseChanges?: boolean
}

export const useMissionsDiffSummary = (missions: Mission[]) => {
  const { t } = useTranslation()
  return useMemo(() => {
    const summaryMissions = missions.filter((mission) => mission.status !== MissionStatus.Completed)
    return [
      {
        missions: summaryMissions,
        headerText: t('features.coaching-session.mission-categories.new-mission-state.title'),
        note: t('features.coaching-session.mission-categories.new-mission-state.note'),
        iconPath: MissionHelper.getIconFromStatus(IconStatus.NewState),
        visualiseChanges: false,
      },
    ]
  }, [missions, t])
}
