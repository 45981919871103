import {
  useDismissTeamleadCustomerComment,
  useForwardTeamleadCustomerComment,
} from 'state/slices/api'

export const useCustomerCommentsForTeamleads = () => {
  const [forwardCustomerComment, { isLoading: isForwardLoading, isSuccess: isForwardSuccess }] =
    useForwardTeamleadCustomerComment()
  const [dismissCustomerComment, { isLoading: isDismissLoading, isSuccess: isDismissSuccess }] =
    useDismissTeamleadCustomerComment()

  return {
    forwardCustomerComment,
    dismissCustomerComment,
    isLoading: isForwardLoading || isDismissLoading,
    isSuccess: isForwardSuccess || isDismissSuccess,
  }
}
