import InlineMessage from '@ingka/inline-message'
import Table, { TableBody, TableHeader } from '@ingka/table'
import { useTranslation } from 'react-i18next'

import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'

import {
  useHandleUserClicked,
  useUsersOverviewData,
  useUsersOverviewTable,
} from './UsersOverview.hooks'
import * as Styled from './UsersOverview.styling'
import { UsersOverviewProps } from './UsersOverview.types'

const UsersOverview = ({ query, demoSearch = false }: UsersOverviewProps) => {
  const { t } = useTranslation()

  const handleUserClicked = useHandleUserClicked(demoSearch)

  const { isLoading, isSuccess, isError } = useUsersOverviewData(query)

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useUsersOverviewTable(query)

  return (
    <>
      {/* Loading State */}
      {isLoading && <LoadingIndicator height="200px" />}
      {/* Error State */}
      {isError && <InlineMessage variant="negative" body={t('errors.unexpected')} />}
      {/* Success State*/}
      {isSuccess && (
        <>
          {/* No data */}
          {rows.length === 0 && (
            <InlineMessage variant="informative" body={t('features.user.no-users')} />
          )}
          {/* Data exists */}
          {rows.length !== 0 && (
            <Table width="100%" inset {...getTableProps}>
              <TableHeader sticky>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </TableHeader>
              <TableBody striped {...getTableBodyProps}>
                {rows.map((row) => {
                  prepareRow(row)
                  return (
                    <Styled.TableRow
                      {...row.getRowProps()}
                      userRole={row.values['role']}
                      data-testid={row.values['id']}
                      onClick={() => handleUserClicked(row.original)}
                    >
                      {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      })}
                    </Styled.TableRow>
                  )
                })}
              </TableBody>
            </Table>
          )}
        </>
      )}
    </>
  )
}

export default UsersOverview
