import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { QaQuestionGroupProps } from './MissionQaMetricsTable.types'

export type GroupedQuestionItemProps = {
  groupId: string
  groupOrder: number
  questions: Array<{
    groupId: string
    key: string
    id: string
    label: string
    name: string
    value: string
  }>
  name: string
}

type GroupedQuestionItemsResult = GroupedQuestionItemProps[]

export const useGroupedQuestionItems = (
  assessmentType: string,
  qaQuestionsList: QaQuestionGroupProps
): GroupedQuestionItemsResult => {
  const { t } = useTranslation()
  const [groupedQaQuestions, setGroupedQaQestions] = useState<GroupedQuestionItemsResult>([])

  useEffect(() => {
    if (qaQuestionsList) {
      const mappedQuestions = Object.values(qaQuestionsList).map((group) => {
        const groupItems = Object.entries(group.questions).map(([questionId, question]) => {
          let questionLabel = t(`qa.${question.id}`) as string
          return {
            key: group.id,
            groupId: group.id,
            id: questionId,
            label: questionLabel,
            name: questionId,
            value: questionId,
          }
        })
        return {
          groupId: group.id,
          groupOrder: group.order,
          questions: groupItems,
          name: group.id,
        }
      })
      setGroupedQaQestions(mappedQuestions)
    }
  }, [assessmentType, qaQuestionsList, t])

  return groupedQaQuestions
}
