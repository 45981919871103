import SSRIcon from '@ingka/ssr-icon'
import Text from '@ingka/text'
import { Accordion, Group } from '@mantine/core'

import { Mission } from 'lib/types/mission.types'

import { missionIconSquare, missionsWidgetStyle } from 'styles/missions'

import { Widget } from 'components/composites/Shared/Widget/Widget.styling'

import { MissionRecap } from '../MissionRecap'
import { useMissionsDiffSummary } from './MissionDiffs.hooks'

type MissionDiffsSummaryProps = {
  coworkerId: string
  missions: Mission[]
}

const MissionDiffsSummary: React.FC<MissionDiffsSummaryProps> = ({ coworkerId, missions }) => {
  const missionsSummaryList = useMissionsDiffSummary(missions)
  return (
    <>
      <Widget style={missionsWidgetStyle}>
        {missionsSummaryList.map((missionDefinition, index) => {
          return (
            <Accordion key={index} defaultValue={missionDefinition.headerText}>
              <Accordion.Item value={missionDefinition.headerText}>
                <Accordion.Control disabled={missionDefinition.missions.length === 0}>
                  <Group>
                    <SSRIcon style={missionIconSquare} paths={missionDefinition.iconPath}></SSRIcon>
                    <div style={{ marginLeft: '10px' }}>
                      <Text>{missionDefinition.headerText}</Text>
                      <Text bodySize="s">{missionDefinition.note}</Text>
                    </div>
                  </Group>
                </Accordion.Control>

                {missionDefinition.missions.map((mission) => (
                  <Accordion.Panel key={mission.id}>
                    <MissionRecap
                      mission={mission}
                      coworkerId={coworkerId}
                      visualiseChanges={missionDefinition.visualiseChanges}
                    />
                  </Accordion.Panel>
                ))}
              </Accordion.Item>
            </Accordion>
          )
        })}
      </Widget>
    </>
  )
}

export default MissionDiffsSummary
