import { useDefaultBackHref } from 'hooks/navigation/useDefaultBackHref.hooks'
import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

export const useBackHref = () => {
  const { coworkerPath } = useSectionPaths()

  const defaultPath = `${coworkerPath}#coaching-sessions`

  return useDefaultBackHref(defaultPath)
}
