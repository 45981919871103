import Button from '@ingka/button'
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal'
import SSRIcon from '@ingka/ssr-icon'
import dissatisfied from '@ingka/ssr-icon/paths/feedback-dissatisfied'
import verySatisfied from '@ingka/ssr-icon/paths/feedback-happy'
import neutral from '@ingka/ssr-icon/paths/feedback-neutral'
import veryDisatisfied from '@ingka/ssr-icon/paths/feedback-sad'
import satisfied from '@ingka/ssr-icon/paths/feedback-satisfied'
import { Box, Group } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import {
  BrandColour,
  colourGreyNeutral2,
  lightBorderColor,
  radius,
  SemanticColour,
  spacing25,
  spacing50,
  spacing100,
  spacing200,
} from 'styles/tokens/insikt.tokens'

import { Label, Paragraph, SubHeading } from 'components/primitives/Text'
import { SubTitleBlue } from 'components/primitives/Text/Text.component'

interface Props {
  isOpen: boolean
  close: () => void
}

const customerFeedbackImage = '/images/customer-feedback-scorecards.png'

const AptitudeInfoModal: React.FC<Props> = ({ isOpen, close }) => {
  const { t } = useTranslation()

  const surveyIcons = [
    {
      icon: veryDisatisfied,
      color: SemanticColour.Negative,
      text: t(
        'features.aptitude-panel.info-modal.what-is-customer-feedback.content.very-dissatisfied'
      ),
    },
    {
      icon: dissatisfied,
      color: SemanticColour.Caution,
      text: t('features.aptitude-panel.info-modal.what-is-customer-feedback.content.dissatisfied'),
    },
    {
      icon: neutral,
      color: BrandColour.Yellow,
      text: t('features.aptitude-panel.info-modal.what-is-customer-feedback.content.neutral'),
    },
    {
      icon: satisfied,
      color: BrandColour.Blue,
      text: t('features.aptitude-panel.info-modal.what-is-customer-feedback.content.satisfied'),
    },
    {
      icon: verySatisfied,
      color: SemanticColour.Positive,
      text: t(
        'features.aptitude-panel.info-modal.what-is-customer-feedback.content.very-satisfied'
      ),
    },
  ]

  return (
    <>
      <Modal escapable visible={isOpen} handleCloseBtn={close}>
        <Sheets
          header={<ModalHeader title={''}></ModalHeader>}
          footer={
            <ModalFooter>
              <Button hidden />
            </ModalFooter>
          }
        >
          <Group justify="flex-start" align="flex-start" px={spacing200} gap={0}>
            <SubTitleBlue>{t('features.aptitude-panel.info-modal.title')}</SubTitleBlue>
            <Label>{t('features.aptitude-panel.info-modal.subtitle')}</Label>
          </Group>

          <ModalBody>
            <Group gap={spacing100}>
              <img
                src={customerFeedbackImage}
                alt="Customer feedback scorecards"
                style={{
                  width: '100%',
                  height: '100%',
                  aspectRatio: 'auto',
                  borderRadius: radius[25],
                }}
              />
              <StyledBox>
                <SubHeading style={{ marginBottom: spacing100 }}>
                  {t('features.aptitude-panel.info-modal.about-section.title')}
                </SubHeading>
                <Paragraph>
                  {t('features.aptitude-panel.info-modal.about-section.content')}
                </Paragraph>
              </StyledBox>

              <Box
                style={{
                  marginTop: spacing50,
                  borderRadius: radius[25],
                  border: '1px dashed',
                  borderColor: lightBorderColor,
                  padding: spacing100,
                }}
              >
                <SubHeading style={{ marginBottom: spacing100 }}>
                  {t('features.aptitude-panel.info-modal.what-is-customer-feedback.title')}
                </SubHeading>
                <StyledBox>
                  {surveyIcons.map((icon, index) => {
                    return (
                      <Group key={index}>
                        <SSRIcon
                          paths={icon.icon}
                          color={icon.color}
                          style={{ marginBottom: spacing25 }}
                        />
                        <Paragraph>{icon.text}</Paragraph>
                      </Group>
                    )
                  })}
                </StyledBox>
              </Box>
              <StyledBox>
                <SubHeading style={{ marginBottom: spacing100 }}>
                  {t('features.aptitude-panel.info-modal.percentage-section.title')}
                </SubHeading>
                <Paragraph>
                  {t('features.aptitude-panel.info-modal.percentage-section.content')}
                </Paragraph>
              </StyledBox>

              <StyledBox>
                <SubHeading style={{ marginBottom: spacing100 }}>
                  {t('features.aptitude-panel.info-modal.number-of-reviews.title')}
                </SubHeading>
                <Paragraph>
                  {t('features.aptitude-panel.info-modal.number-of-reviews.content')}
                </Paragraph>
              </StyledBox>
            </Group>
          </ModalBody>
        </Sheets>
      </Modal>
    </>
  )
}

interface StyledBoxProps {
  children: React.ReactNode
  style?: React.CSSProperties
}

const StyledBox = ({ children }: StyledBoxProps) => (
  <Box
    bg={colourGreyNeutral2}
    p={`${spacing50}`}
    mt={spacing50}
    style={{ borderRadius: radius[25] }}
  >
    {children}
  </Box>
)

export default AptitudeInfoModal
