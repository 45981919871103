import SSRIcon from '@ingka/ssr-icon'
import infoCircled from '@ingka/ssr-icon/paths/information-circle'
import Text from '@ingka/text'
import { space100 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { QaMetricId } from 'config/domain/qa.config'

import { Coworker } from 'lib/types/coworker.types'
import { Team } from 'lib/types/team.types'

import { Caption, SubHeading } from 'components/primitives/Text'

import { MissionDraftFormFields } from '../../MissionDraftEditor.types'

type Props = {
  form: UseFormReturn<MissionDraftFormFields> & {
    watch: (field: keyof MissionDraftFormFields) => QaMetricId
    resetField: (field: keyof MissionDraftFormFields) => void
  }
  coworker: Coworker
  team: Team
}

const MetricStep: React.FC<Props> = ({ form, coworker, team }) => {
  const { t } = useTranslation()

  const { watch } = form
  const qaId = watch('qaId')

  return (
    <>
      <SubHeading>{t('features.mission.create-mission.steps.focus-area.title')}</SubHeading>
      <Caption>
        {t('features.mission.create-mission.steps.focus-area.subtitle', {
          coworker: coworker.firstName,
        })}
      </Caption>

      {watch('qaId') && (
        <Flex gap={space100} my={space100}>
          <Text bodySize="l">{t(`qa.${qaId && qaId}` as string)}</Text>
          <SSRIcon paths={infoCircled} enableBackground="white" />
        </Flex>
      )}
    </>
  )
}

export default MetricStep
