import { LoadingBall } from '@ingka/loading'
import Switch from '@ingka/switch'
import Tooltip from '@ingka/tooltip'
import { Box, Flex } from '@mantine/core'
import { ChartData, ChartOptions } from 'chart.js'
import { format } from 'date-fns'
import { useState } from 'react'
import { Line } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

import { isMetricId, metricsConfig, qualityAssessmentConfig } from 'config/domain/metrics.config'
import { isQaMetricId } from 'config/domain/qa.config'
import { getDateFnsLocale } from 'config/internationalisation/locales.config'

import { MetricValueType } from 'lib/types/metric.types'
import { FormattingHelper } from 'lib/utils/formatting.helper'

import { useFormatMetric } from 'hooks/metrics/useFormatMetric'

import { useCoworker, useLocale } from 'state/slices/api/api.hooks'

import { coworkerColour, targetColour, teamColour } from 'styles/tokens/insikt.tokens'

import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import { useDatasets } from 'components/features/Metrics/MetricLineChart/MetricLineChart.hooks'
import { MetricLineChartProps } from 'components/features/Metrics/MetricLineChart/MetricLineChart.types'
import { convertMetricsToDataset } from 'components/features/Metrics/MetricLineChart/MetricLineChart.utils'
import { useCoworkerLabel } from 'components/pages/shared/shared.hooks'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

const MetricLineChart: React.FC<MetricLineChartProps> = ({
  coworkerId,
  teamId,
  metricId,
  startDate,
  endDate,
  target,
  controls,
  annotations = [],
}) => {
  const { t } = useTranslation()
  const { dateFnsCode } = useLocale()
  const [selectedValueType, setSelectedValueType] = useState<MetricValueType>(MetricValueType.Trend)

  const dateFnsLocale = getDateFnsLocale(dateFnsCode)
  const indicatorConfig = isMetricId(metricId)
    ? metricsConfig[metricId]
    : isQaMetricId(metricId)
      ? qualityAssessmentConfig
      : undefined

  const { metricBehaviour } = indicatorConfig ?? {}

  const {
    min: yMin,
    max: yMax,
    inverseScore,
    noTrendData: noTrend,
    decimalPlaces,
    noTeamData,
  } = metricBehaviour ?? {}

  const formatMetric = useFormatMetric(metricId, coworkerId, 'coworker')

  const [
    {
      data: coworkerData = [],
      isLoading: isLoadingCoworkerData,
      isFetching: isFetchingCoworkerData,
      isError: isErrorCoworkerData,
      isSuccess: isSuccessCoworkerData,
    },
    {
      data: teamData = [],
      isFetching: isFetchingTeamData,
      isError: isErrorTeamData,
      isSuccess: isSuccessTeamData,
    },
  ] = useDatasets({
    metricId: metricId,
    coworkerId,
    teamId,
    startDate,
    endDate,
    selectedValueType,
  })

  const {
    data: coworker,
    isLoading: isLoadingCoworker,
    isFetching: isFetchingCoworker,
    isError: isErrorCoworker,
    isSuccess: isSuccessCoworker,
  } = useCoworker(coworkerId)

  const coworkerLabel = useCoworkerLabel(coworker)

  // We don't require team data to show the chart, so we only use
  // isFetching for it, not isLoading
  const isLoading = isLoadingCoworkerData || isLoadingCoworker
  const isFetching = isFetchingCoworkerData || isFetchingTeamData || isFetchingCoworker
  const isError = isErrorCoworkerData || isErrorCoworkerData || isErrorCoworker
  const isSuccess = isSuccessCoworkerData && isSuccessTeamData && isSuccessCoworker

  const coworkerDataset = isErrorCoworkerData
    ? []
    : convertMetricsToDataset(coworkerData, startDate, endDate)

  const teamDataset =
    noTeamData || isErrorTeamData ? [] : convertMetricsToDataset(teamData, startDate, endDate)

  const handleValueTypeChanged = () => {
    if (selectedValueType === MetricValueType.Raw) {
      setSelectedValueType(MetricValueType.Trend)
    } else {
      setSelectedValueType(MetricValueType.Raw)
    }
  }

  const options: ChartOptions<'line'> = {
    responsive: true,
    aspectRatio: 2.5,
    scales: {
      x: {
        offset: true,
        type: 'time',
        time: {
          unit: 'day',
        },
        ticks: {
          source: 'auto',
        },
        adapters: {
          date: {
            locale: dateFnsLocale,
          },
        },
      },
      y: {
        offset: true,
        reverse: inverseScore === true,
        ticks: {
          callback: (value) => {
            if (typeof value === 'string' && isNaN(Number(value))) {
              return value
            }
            return formatMetric(value as number)
          },
        },
        min: yMin,
        max: yMax,
      },
    },
    plugins: {
      tooltip: {
        mode: 'nearest',
        intersect: false,
        callbacks: {
          title: (item) => {
            const xValue = item[0].parsed.x
            const formattedValue = format(new Date(xValue), 'PPPP', {
              locale: dateFnsLocale,
            })
            return formattedValue
          },
          label: (item) => formatMetric(item.parsed.y),
        },
      },
      annotation: {
        annotations,
      },
    },
  }

  if (target && options?.plugins?.annotation?.annotations) {
    // Typescript does not pick up on the null check because Chart.js uses optional typing,
    // so we add a @ts-ignore to avoid a compile error
    // @ts-ignore
    options.plugins.annotation.annotations.push({
      type: 'line',
      yMin: target,
      yMax: target,
      borderWidth: 2,
      label: {
        content: t('features.chart.labels.target', { target }),
      },
      borderColor: targetColour,
    })
  }

  const chartData: ChartData<'line'> = {
    datasets: [
      {
        label: coworkerLabel,
        data: coworkerDataset,
        tension: 0.1,
        borderColor: coworkerColour,
        backgroundColor: coworkerColour,
      },
    ],
  }

  if (teamData && teamData.length !== 0) {
    chartData.datasets.push({
      label: t('features.chart.labels.team'),
      data: teamDataset,
      borderColor: teamColour,
      backgroundColor: teamColour,
    })
  }

  // Workaround to show a label for "Target" above the chart
  if (target) {
    chartData.datasets.push({
      label: t('features.chart.labels.target', {
        target: FormattingHelper.formatNumber(target, decimalPlaces),
      }),
      data: [],
      borderColor: targetColour,
      backgroundColor: targetColour,
    })
  }

  return (
    <>
      {isLoading && <LoadingIndicator height="300px" />}
      {isError && <UnexpectedErrorMessage />}
      {isSuccess && (
        <Flex direction="column" w="100%" h="100%" align={'center'} style={{ flexGrow: 1 }}>
          <Flex w="100%" justify="flex-end">
            {controls?.smoothing && !noTrend && (
              <Tooltip tooltipText={t('features.chart.labels.enable-smoothing-tooltip')}>
                <Switch
                  id="enable-smoothing"
                  label={t('features.chart.labels.enable-smoothing')}
                  value="enabled"
                  checked={selectedValueType === MetricValueType.Trend}
                  onChange={handleValueTypeChanged}
                />
              </Tooltip>
            )}
          </Flex>
          <Flex
            justify={'center'}
            style={{ flexGrow: 1 }}
            h={'100%'}
            w={'100%'}
            maw={1000}
            mah={500}
          >
            {/* When fetching, add the loading ball as an overlay */}
            {isFetching && (
              <Box pos="absolute" top="40%" left="50%">
                <LoadingBall />
              </Box>
            )}
            <Line options={options} data={chartData} height="100%" width={'100%'} />
          </Flex>
        </Flex>
      )}
    </>
  )
}

export default MetricLineChart
