import { Flex, Grid } from '@mantine/core'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { BrandColour, colourGreyNeutral1, SemanticColour } from 'styles/tokens/insikt.tokens'

import { Heading } from 'components/primitives/Text'

import classes from './CustomerCommentAnimations.module.css'

interface CustomerCommentAnimationCardProps {
  action: 'forward' | 'dismiss' | null
}

const CustomerCommentAnimationCard: FC<CustomerCommentAnimationCardProps> = ({ action }) => {
  const { t } = useTranslation()
  return (
    <>
      <Grid>
        <Grid.Col
          span={12}
          mih={'40vh'}
          className={`${classes.resultCard} ${
            action === 'forward' ? classes.forwardCard : classes.dismissCard
          }`}
        >
          <Flex
            justify={'center'}
            align={'center'}
            h={'100%'}
            w={'100%'}
            bg={action === 'forward' ? SemanticColour.Positive : BrandColour.Yellow}
          >
            <Heading style={{ color: colourGreyNeutral1 }}>
              {action === 'forward'
                ? t('features.customer-comments.actions.sent')
                : t('features.customer-comments.actions.dismissed')}
            </Heading>
          </Flex>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default CustomerCommentAnimationCard
