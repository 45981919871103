import Badge from '@ingka/badge'
import Tabs, { Tab, TabPanel } from '@ingka/tabs'
import { Box } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  radius25,
  spacing50,
  spacing100,
  textColour1,
  textColour3,
} from 'styles/tokens/insikt.tokens'
import { colourBorderLight } from 'styles/tokens/oldSkapa.tokens'

import ConditionalTooltip from 'components/primitives/ConditionalTooltip/ConditionalTooltip.component'
import {  Paragraph } from 'components/primitives/Text'

import { LazyLoader } from '../LazyLoader'
import { useAnalytics } from './TabLayout.hooks'
import { TabLayoutProps } from './TabLayout.types'

export const TabLayout: React.FC<TabLayoutProps> = ({ tabDefinitions, analyticsDescription }) => {
  const nav = useNavigate()
  const { t } = useTranslation()

  const location = useLocation()
  const urlFragment = location.hash.replace('#', '')

  const [activeTab, setActiveTab] = useState<string>(
    tabDefinitions.some((tab) => tab.key === urlFragment) ? urlFragment : tabDefinitions[0].key
  )

  const handleClicked = (newTabKey: string) => {
    if (activeTab !== newTabKey) {
      setActiveTab(newTabKey)
      nav(`#${newTabKey}`)
    }
  }

  useAnalytics(activeTab, analyticsDescription)

  useEffect(() => {
    if (tabDefinitions.some((tab) => tab.key === urlFragment && !tab.disabled?.isDisabled))
      setActiveTab(urlFragment)
    else setActiveTab(tabDefinitions[0].key)
  }, [location, urlFragment, setActiveTab, tabDefinitions])

  const tabs = (
    <Box
      style={{
        borderBottom: `1px solid ${colourBorderLight}`,
        width: '100%',
        backgroundColor: 'white',
        borderRadius: radius25,
      }}
    >
      {tabDefinitions.map((tab, index) => (
        <ConditionalTooltip
          condition={!!tab.disabled && tab.disabled.isDisabled}
          position="trailing"
          tooltipText={t(tab.disabled?.tooltipText ?? '')}
          key={tab.key}
        >
          <Tab
            key={tab.key}
            disabled={tab.disabled?.isDisabled}
            tabIndex={index}
            tabPanelId={tab.key}
            text={
              <Paragraph
                style={{
                  opacity: tab.disabled?.isDisabled ? '50%' : '100%',
                  height: '100%',
                  alignContent: 'flex-end',
                  // Make the active one more bold
                  color: activeTab === tab.key ? textColour1 : textColour3,
                }}
              >
                {t(tab.tKeyForTitle)}
              </Paragraph>
            }
            role="tab"
            ssrIcon={tab.icon}
            style={{
              cursor: tab.disabled?.isDisabled ? 'not-allowed' : 'pointer',
              alignItems: 'flex-end',
              marginInlineEnd: spacing100,
              marginInlineStart: 0,
              padding: `${spacing100} ${spacing50}`,
            }}
          >
            {/* Customer comments counter badge */}
            {tab.badgeCount && (
              <div
                className="comments-counter"
                style={{ verticalAlign: 'text-top', position: 'absolute', top: 0, right: '-10px'}}
              >
                <Badge
                  colour="yellow"
                  label={tab.badgeCount}
                  size="small"
                  className="comments-counter"
                />
              </div>
            )}
          </Tab>
        </ConditionalTooltip>
      ))}
    </Box>
  )

  const tabsPanel = tabDefinitions.map((tab) => (
    // TODO: We do not really have a padding in the designs, but there is a default one comming from the TabPanel component
    // Ask Pablo how are we feeling
    <TabPanel style={{ padding: '0px' }} key={tab.key} tabPanelId={tab.key}>
      <LazyLoader visible={activeTab === tab.key}>{tab.content}</LazyLoader>
    </TabPanel>
  ))

  return (
    <Tabs
      style={{
        width: '100%',
      }}
      activeTab={activeTab}
      onTabChanged={handleClicked}
      tabs={tabs}
      tabPanels={tabsPanel}
    />
  )
}
