import Button from '@ingka/button'
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal'
import { Flex, Stack } from '@mantine/core'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import { User } from 'lib/types/user.types'

import { useMarkAllNotificationsAsRead } from 'state/slices/api/modules/user/user.slice'

import { Notification } from 'components/features/General/NotificationsButton/Notification'
import { Divider } from 'components/primitives/Divider'
import { Heading, Paragraph } from 'components/primitives/Text'

type NotificationsMenuProps = {
  onClose: () => void
  user: User
  show?: boolean
}

const NotificationsMenu: React.FC<NotificationsMenuProps> = ({ onClose, user, show }) => {
  const { t } = useTranslation()

  const [markAllNotificationsAsRead, { isLoading }] = useMarkAllNotificationsAsRead()

  const sortedNotifications = _.orderBy(
    user.notifications,
    (notif) => new Date(notif.createdDate),
    'desc'
  ).slice(0, 5)

  return (
    <Modal visible={show} handleCloseBtn={onClose}>
      <Sheets
        header={<ModalHeader />}
        footer={
          <ModalFooter>
            <Button hidden />
          </ModalFooter>
        }
      >
        <ModalBody>
          <Flex align="center" justify="space-between">
            <Heading>{t('features.notifications.title')}</Heading>
            <Button
              type="secondary"
              small
              onClick={() => {
                markAllNotificationsAsRead(user.id)
              }}
              text={t('features.notifications.actions.mark-all-as-read')}
              loading={isLoading}
            />
          </Flex>
          <Divider />
          <Stack>
            {sortedNotifications.length === 0 && (
              <Paragraph>{t('features.notifications.warnings.no-notifications')}</Paragraph>
            )}
            {sortedNotifications.map((notification) => (
              <Notification notification={notification} key={notification.id} />
            ))}
          </Stack>
        </ModalBody>
      </Sheets>
    </Modal>
  )
}

export default NotificationsMenu
