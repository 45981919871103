import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { useValidateParams } from 'hooks/navigation/useValidateParams'

import { CoachingSessionEditor } from 'components/features/CoachingSessions/CoachingSessionEditor'
import { PageHeader } from 'components/layout/PageHeader'
import { RedirectingLayout } from 'components/layout/RedirectingLayout'

import { useBackHref } from './EditCoachingSessionPage.hooks'

const EditCoachingSessionPage: React.FC = () => {
  const { t } = useTranslation()
  const backHref = useBackHref()
  const { coworkerId, coachingSessionId } = useParams()
  const locationState = useLocation().state

  useValidateParams(['coworkerId', 'coachingSessionId'])
  if (!coworkerId || !coachingSessionId) return <RedirectingLayout />

  return (
    <>
      <PageHeader
        title={t('features.coaching-session.edit-coaching-session.back-button')}
        backHref={backHref}
      />
      <CoachingSessionEditor
        prepare={locationState?.prepare ?? false}
        coworkerId={coworkerId!}
        coachingSessionId={coachingSessionId}
      />
    </>
  )
}

export default EditCoachingSessionPage
