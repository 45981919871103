import { GameEvent } from 'lib/types/game.types'

import { apiSlice } from 'state/slices/api/api.slice'

import { ApplyGameEventInput } from './game.slice.types'

export const GAME_STATE_TAG = 'Game'

const gameSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchGameEvents: builder.query<GameEvent[], string>({
      query: (legacyId) => `/game/${legacyId}/events`,
      providesTags: [GAME_STATE_TAG],
    }),
    applyGameEvent: builder.mutation<void, ApplyGameEventInput>({
      query: ({ gameEventId, legacyId, userId }) => ({
        url: `/game/${legacyId}/events/${gameEventId}`,
        body: {
          userId: userId,
        },
        method: 'PUT',
      }),
      invalidatesTags: [GAME_STATE_TAG],
    }),
  }),
})

export const {
  useFetchGameEventsQuery: useGameEvents,
  useApplyGameEventMutation: useApplyGameEvent,
} = gameSlice
