import checkmarkCircle from '@ingka/ssr-icon/paths/checkmark-circle'
import clock from '@ingka/ssr-icon/paths/clock'
import shieldCheckmark from '@ingka/ssr-icon/paths/shield-checkmark'

import { MissionStatus } from 'lib/types/mission.types'

export const getMetricIcon = (missionStatus: MissionStatus, initialScore: number) => {
  if (missionStatus === MissionStatus.Completed) {
    return checkmarkCircle
  }

  if (isNaN(initialScore)) {
    return clock
  }

  return shieldCheckmark
}
