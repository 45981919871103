import Button from '@ingka/button'
import linkIcon from '@ingka/ssr-icon/paths/link-out'
import Text from '@ingka/text'
import { space50, space100, space200 } from '@ingka/variables'
import { Box, Flex } from '@mantine/core'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { QaMetricId } from 'config/domain/qa.config'

import { QualityAssessment } from 'lib/types/qualityAssessment.types'
import { FormattingHelper } from 'lib/utils/formatting.helper'
import { QualityAssessmentHelper } from 'lib/utils/qualityAssessment.helper'

import { useFormatDate } from 'hooks/formatting'
import { useCurrentQaDataForCoworker } from 'hooks/qualityAssssments/useCurrentQaDataForCoworker.hooks'

import { backgroundColourDefault, backgroundColourWhite } from 'styles/tokens/insikt.tokens'

import { Paragraph, SubHeading } from 'components/primitives/Text'

interface Props {
  assessments: QualityAssessment[]
  coworkerId: string
  qaId: QaMetricId
  hideGeneralNotes?: boolean
}

const QaNotes = ({ coworkerId, qaId, hideGeneralNotes }: Props) => {
  const formatDate = useFormatDate()
  const { t } = useTranslation()

  const qaData = useCurrentQaDataForCoworker(coworkerId)

  const filteredQas = QualityAssessmentHelper.filterQasById(qaData?.currentQas ?? [], qaId)

  if (filteredQas.length === 0) {
    return (
      <Box>
        <Text>{t('qa.notes.no-notes-available')}</Text>
      </Box>
    )
  }

  return (
    <Box
      style={{
        backgroundColor: backgroundColourDefault,
        borderRadius: 5,
        padding: 5,
        marginTop: space200,
      }}
    >
      {filteredQas.map((qa, index) => {
        const qaNotes = qa.groups
          .map((group) => {
            if (group.notes) {
              return group.notes
            }
            return group.questions.find((q) => q.id === qaId)?.notes
          })
          .filter((n) => n)

        const sc = qa.groups
          .find((group) => group.questions.find((q) => q.id === qaId))
          ?.questions.find((q) => q.id === qaId)?.score

        return (
          <Flex
            key={index}
            direction={'column'}
            style={{
              backgroundColor: backgroundColourWhite,
              margin: space100,
              padding: space100,
            }}
          >
            <Flex gap={space50} align={'center'} mb={space50}>
              <Paragraph
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
                $bold
              >
                <Paragraph style={{ marginRight: 5 }}>{t('qa.notes.assessment')}</Paragraph>
                <Paragraph>{index + 1}</Paragraph>
              </Paragraph>

              {'-'}

              <Paragraph style={{ marginLeft: 5 }}>
                {'Score: ' + FormattingHelper.displayNumber(qa.score) + ''}
              </Paragraph>

              {'-'}
              <Paragraph $bold>{qa?.mediaType ?? 'N/A'}</Paragraph>
              {'-'}

              <Paragraph>{formatDate(dayjs(qa.evaluationDate.value).toDate())}</Paragraph>
              <Flex style={{ flexGrow: 1 }} />
              <Button
                iconOnly
                type="tertiary"
                small
                ssrIcon={linkIcon}
                href={qa?.link}
                hidden={!qa?.link}
                target="_blank"
                rel="noreferrer"
              />
            </Flex>

            {!hideGeneralNotes && (
              <Paragraph
                style={{
                  backgroundColor: backgroundColourDefault,
                  padding: space100,
                }}
              >
                <Paragraph $bold>{'Notes'}</Paragraph>
                {qa.notes ? qa.notes : t('qa.notes.no-general-notes-available')}
              </Paragraph>
            )}

            <SubHeading
              style={{
                marginTop: space100,
                marginBottom: space50,
              }}
            >
              {t('qa.notes.question') + ' ' + qaId}
            </SubHeading>
            <Paragraph>
              <Paragraph $bold style={{ marginBottom: space50 }}>
                {'Score: ' + FormattingHelper.displayNumber(sc ?? NaN)}
              </Paragraph>
            </Paragraph>
            <Paragraph
              style={{
                backgroundColor: backgroundColourDefault,
                padding: space100,
              }}
            >
              <Paragraph $bold>{'Notes'}</Paragraph>
              {qaNotes[0] ? qaNotes[0] : t('qa.notes.no-question-notes-available')}
            </Paragraph>
          </Flex>
        )
      })}
    </Box>
  )
}

export default QaNotes
