import { QaMetricId } from 'config/domain/qa.config'

import { ISODateString } from 'lib/types/common.types'

export type QualityAssessment = {
  id: QaMetricId
  groups: QualityAssessmentQuestionGroup[]
  /** The weighted total score of all question groups found in the assessment. */
  score: number
  /** Notes made by the team lead during assessment. */
  notes: string
  /** A timestamp representing the time the assessment was completed. */
  evaluationDate: {
    value: ISODateString
  }
  /** The type of assessment made in the Speechminer tool. */
  assessmentType?: QaType
  /** The ID of the interaction series that this belongs to. */
  interactionId?: string
  /** The ID of the resource associated with this interaction, e.g. a call recording. */
  resourceId?: string
  /** Link to the interaction in speechminer. */
  link?: string
  mediaType?: string
  /** The ID of the resource associated with this interaction in Speechminer. */
  speechminerResourceId?: string
}

export type QualityAssessmentQuestionGroup = {
  id: QaMetricId
  /** The name of the question group. */
  title: string
  /** What order the question group appears in the question form. */
  order: number
  /** The weighted total score of all questions found in the group. */
  score: number
  /** How much the group score influences the total. */
  weight: number
  /** The questions found in the group. */
  questions: QualityAssessmentQuestion[]
  /** Notes made by the team lead during assessment. */
  notes: string
}

export type QualityAssessmentQuestion = {
  id: QaMetricId
  /** The name of the question. */
  title: string
  /** What order the question appears in the question group. */
  order: number
  /**
   * An assessment made by the team lead on how well the co-worker lived up to the question.
   *
   * Note that even though a score of 0-100 is used, this may not correspond to the format
   * the team lead was presented with during the assessment. As an example, assessments
   * may be multiple choice, but are translated into scores of 0, 50 and 100.
  . */
  score: number
  /** How much the question score influences the group total. */
  weight: number

  notes?: string
}

export enum QaType {
  QAR = 'QAR',
  QAS = 'QAS',
  QSA = 'QSA',
}

export type RecentQAs = {
  QAs: QualityAssessment[]
  startDate: ISODateString
}
