import Button from '@ingka/button'
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal'
import Select, { Option } from '@ingka/select'
import { space100 } from '@ingka/variables'
import { Group } from '@mantine/core'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'
import { CustomElementClickEventKeys } from 'lib/utils/analytics/analytics.types'
import { DateHelper } from 'lib/utils/date.helper'

import { useUpdateCoachingSession } from 'state/slices/api/modules/coachingSession'

import { DatePicker } from 'components/primitives/DatePicker'

import { CoachingSessionModalProps } from './CoachingSessionPreviewList.types'

const options = [1, 2, 3, 4, 5, 6]

const CoachingSessionModal: React.FC<CoachingSessionModalProps> = ({
  closeModal,
  visible,
  coachingSession,
}) => {
  const { t } = useTranslation()
  const { coworkerId } = useParams()
  const [updateCoachingSession] = useUpdateCoachingSession()
  const [hasChanged, setHasChanged] = useState<boolean>(false)

  // Placeholder
  const [plannedDate, setPlannedDate] = useState<Date>(new Date(coachingSession.plannedDate))
  const [assessmentsNeeded, setAssessmentsNeeded] = useState<number>(
    coachingSession.assessmentsNeeded
  )

  useEffect(() => {
    if (dayjs(plannedDate).toISOString() !== coachingSession.plannedDate) {
      return setHasChanged(true)
    } else if (
      coachingSession.assessmentsNeeded !== assessmentsNeeded &&
      options.includes(assessmentsNeeded)
    ) {
      return setHasChanged(true)
    } else {
      return setHasChanged(false)
    }
  }, [plannedDate, assessmentsNeeded, coachingSession])

  const handleClickUpdate = async () => {
    const updateCoachingSessionArgs = {
      coworkerId: coworkerId,
      coachingSessionId: coachingSession.id,
      plannedDate: DateHelper.dateToString(plannedDate),
      assessmentsNeeded: assessmentsNeeded,
    }

    await updateCoachingSession(updateCoachingSessionArgs)
    analyticsHelper.createCustomElementClickEvent(
      {
        key: CustomElementClickEventKeys.EditCoaching,
        description:
          'Custom event for tracking when a teamlead edits date/assessments needed for a coaching session.',
      },
      updateCoachingSessionArgs
    )
    closeModal()
  }

  return (
    <Modal handleCloseBtn={closeModal} visible={visible}>
      <Sheets
        alignment="right"
        footer={
          <ModalFooter>
            <Button
              small
              disabled={!hasChanged}
              type="primary"
              text={t('features.coaching-session.actions.update-coaching-session')}
              onClick={handleClickUpdate}
              style={{ marginTop: space100 }}
            />
          </ModalFooter>
        }
        header={
          <ModalHeader
            ariaCloseTxt="Close button text"
            backBtnClick={closeModal}
            title={t('features.coaching-session.edit-coaching-session.title')}
          />
        }
      >
        <ModalBody>
          <Group>
            <Select
              id="selectAssessments"
              label={t('features.coaching-session.actions.select-assessments-number')}
              onChange={(e) => {
                setAssessmentsNeeded(Number(e.target.value))
              }}
              value={String(assessmentsNeeded)}
            >
              {options.map((v, i) => {
                return <Option name={String(v)} value={String(v)} key={String(v)} />
              })}
            </Select>
            <DatePicker
              id="1"
              label={t('features.coaching-session.actions.choose-planned-date')}
              value={plannedDate}
              onChange={(v) => {
                setPlannedDate(v)
              }}
              minDate={new Date()}
            />
          </Group>
        </ModalBody>
      </Sheets>
    </Modal>
  )
}

export default CoachingSessionModal
