import SSRIcon from '@ingka/ssr-icon'
import noteIcon from '@ingka/ssr-icon/paths/note'
import { space50, space75, space100 } from '@ingka/variables'
import { Box, Group, Stack } from '@mantine/core'
import DOMPurify from 'dompurify'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'

import { CoachingSession } from 'lib/types/coachingSession.types'
import { jsonToHtml } from 'lib/utils/generateHTML.helper'

import { useCurrentPageType } from 'hooks/navigation/useSectionPaths.hooks'

import { colourNeutralGrey100 } from 'styles/tokens/oldSkapa.tokens'

import { Widget } from 'components/composites/Shared/Widget'
import { SubHeading } from 'components/primitives/Text'

type Props = {
  coachingSession: CoachingSession
}

const FormattedCoworkerNote: React.FC<Props> = ({ coachingSession }) => {
  const { t } = useTranslation()
  const coworkerNote = coachingSession.coworkerNote?.note
  const pageType = useCurrentPageType()

  const santizedHTML = DOMPurify.sanitize(coworkerNote || '')

  const transKey = 'features.coworker-note.warnings'

  const renderHTMLMarkup = () => {
    if (!coworkerNote || coworkerNote === '')
      return (
        <Box>
          {pageType === 'coworker'
            ? t(`${transKey}.for-coworker.no-note`)
            : t(`${transKey}.for-teamleader.no-note`)}
        </Box>
      )

    if (coworkerNote && coworkerNote) {
      const noteAsJson = JSON.parse(santizedHTML)
      const parsedHTML = parse(jsonToHtml(noteAsJson))
      return <Box>{parsedHTML}</Box>
    }
  }

  return (
    <>
      <Widget border style={{ marginTop: space100 }}>
        <Stack mih={'15vh'}>
          <Group gap={space50}>
            <SSRIcon paths={noteIcon} />
            <SubHeading>
              {pageType === 'coworker'
                ? t(`${transKey}.for-coworker.title`)
                : t(`${transKey}.for-teamleader.title`)}
            </SubHeading>
          </Group>
          <Box p={`${space50} ${space75}`} bg={colourNeutralGrey100} mih={'10vh'}>
            {renderHTMLMarkup()}
          </Box>
        </Stack>
      </Widget>
    </>
  )
}

export default FormattedCoworkerNote
