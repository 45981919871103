import InlineMessage from '@ingka/inline-message'
import { useTranslation } from 'react-i18next'

import { Coworker } from 'lib/types/coworker.types'
import { Mission } from 'lib/types/mission.types'

import MissionOverviewCoachingPointers from '../components/MissionOverviewCoachingPointers.component'
import MissionOverviewConnectedMetric from '../components/MissionOverviewConnectedMetric.component'
import MissionOverviewQaProgression from '../components/MissionOverviewQaProgression.component'
import MissionOverviewTitle from '../components/MissionOverviewTitle.component'

import OverviewBase from './OverviewBase.component'

interface Props {
  mission: Mission
  coworker: Coworker
}

const CompletedOverview: React.FC<Props> = ({ mission, coworker }) => {
  const { t } = useTranslation()

  return (
    <OverviewBase>
      <MissionOverviewTitle
        missionCompleted={mission.endDate}
        qaId={mission.qaId}
        title={mission.title}
      />

      <MissionOverviewCoachingPointers coachingPointers={mission.coachingPointers} />

      <MissionOverviewQaProgression mission={mission} coworkerId={coworker.legacyId} />

      {mission.metricId ? (
        <MissionOverviewConnectedMetric
          metricId={mission.metricId}
          coworker={coworker}
          mission={mission}
          missionEnd={mission.endDate}
          completed
        />
      ) : (
        <InlineMessage variant="informative" title={t('features.mission.preview.no-metric')} />
      )}
    </OverviewBase>
  )
}

export default CompletedOverview
