import { space50 } from '@ingka/variables'
import { Stack } from '@mantine/core'
import { useEffect } from 'react'

import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'
import { CustomViewEventInput, CustomViewKeys } from 'lib/utils/analytics/analytics.types'

import { useTeamleadId } from 'hooks/id/useTeamleadId.hooks'

import { Widget } from 'components/composites/Shared/Widget'
import { CoworkersOverview } from 'components/features/Coworkers/CoworkersOverview'

const ManageCoworkersPage: React.FC = () => {
  const teamleadId = useTeamleadId()

  useEffect(() => {
    const input: CustomViewEventInput = {
      key: CustomViewKeys.Coworkers,
      description: 'Custom event for tracking when a teamlead goes to the coworkers overview page.',
      coworkerId: undefined,
    }

    analyticsHelper.createCustomViewEvent(input)
  }, [])

  return (
    <>
      {teamleadId && (
        <Stack gap={space50}>
          <Widget>
            <CoworkersOverview legacyId={teamleadId} showSearchBar={false} />
          </Widget>
        </Stack>
      )}
    </>
  )
}

export default ManageCoworkersPage
