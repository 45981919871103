import { countBy, maxBy } from 'lodash'
import { useEffect, useState } from 'react'

import { useTeamleadId } from 'hooks/id/useTeamleadId.hooks'

import { useCoworkers, useTeamQuery } from 'state/slices/api'

export const useTeamNameByTlId = () => {
  const [teamId, setTeamId] = useState<string | undefined>(undefined)
  const teamleadId = useTeamleadId()
  const { data: coworkersData } = useCoworkers(teamleadId)
  const { data: teamData, isSuccess, isError,  } = useTeamQuery(teamId)

  useEffect(() => {
    if (!coworkersData) return undefined

    const teams = countBy(coworkersData.activeCoworkers, 'teamId')
    const mostPopularTeam = maxBy(Object.keys(teams), (teamId: string) => teams[teamId])
    setTeamId(mostPopularTeam)
  }, [coworkersData])

  return { teamData, isSuccess, isError }
}
