import { skipToken } from '@reduxjs/toolkit/query'

import { MissionStatus } from 'lib/types/mission.types'
import { getCountryFlag } from 'lib/utils/countryFlags.helper'

import { useCoworker, useMissions, useTeamQuery } from 'state/slices/api'

export const useLoadProfileData = (coworkerId: string) => {
  const { data: coworker, isLoading: isLoadingCoworker } = useCoworker(coworkerId)

  const { data: team, isLoading: isLoadingTeam } = useTeamQuery(coworker?.teamId ?? skipToken)
  const { data: missions = [], isLoading: isLoadingMission } = useMissions(coworkerId)

  const isLoading = isLoadingCoworker || isLoadingTeam || isLoadingMission

  const flag = getCountryFlag(coworker?.country ?? '')
  const completedMissions = missions.filter((mission) => mission.status === MissionStatus.Completed)

  return { coworker, isLoading, team, flag, completedMissions }
}
