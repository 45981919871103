import Button from '@ingka/button'
import expand from '@ingka/ssr-icon/paths/expand'
import { space50 } from '@ingka/variables'
import { Divider, Flex, Grid } from '@mantine/core'
import { isEqual } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Mission, MissionStatus } from 'lib/types/mission.types'

import { useFormatDate } from 'hooks/formatting'
import { useCurrentPageType } from 'hooks/navigation/useSectionPaths.hooks'

import { backgroundColourDefault, backgroundColourWhite } from 'styles/tokens/insikt.tokens'

import { MissionCoachingPointersFormatted } from 'components/composites/Missions/MissionCoachingPointersFormatted'
import { MissionStatusIndicator } from 'components/composites/Missions/MissionStatusIndicator'
import { Widget, WidgetFooter, WidgetHeader } from 'components/composites/Shared/Widget'
import { MissionOverview } from 'components/features/Missions/MissionOverview'
import MissionOverviewDraftSwitch from 'components/features/Missions/MissionOverview/components/MissionOverviewDraftSwitch.component'
import { GreyBoldedSubheading, Paragraph, SubHeading } from 'components/primitives/Text'

type MissionRecapProps = {
  coworkerId: string
  mission: Mission
  visualiseChanges?: boolean
  showStatus?: boolean
}

const MissionRecap: React.FC<MissionRecapProps> = ({ coworkerId, mission, showStatus = true }) => {
  const { t } = useTranslation()
  const [openDrawer, setDrawerOpen] = useState(false)

  const formatDate = useFormatDate()

  const activeValues = {
    title: mission.title,
    coachingPointers: mission.coachingPointers,
    target: mission.target,
    endDate: mission.endDate,
  }
  const isChanged = !isEqual(mission.changes, activeValues)

  const [showChanges, setShowChanges] = useState(
    isChanged && mission.suggestedToComplete ? false : true
  )
  const selectedValues = showChanges ? mission.changes : activeValues
  const pageType = useCurrentPageType()

  // sub status for mission recap
  function getMissionSubStatus(mission: Mission) {
    if (mission.suggestedToComplete) {
      return t(
        'features.coaching-session.summary-commit.missions-recap.sub-status.suggested-to-be-completed'
      )
    }

    if (isChanged) {
      return t('features.coaching-session.summary-commit.missions-recap.sub-status.updated-mission')
    }

    if (mission.status === MissionStatus.Draft) {
      return t('features.coaching-session.summary-commit.missions-recap.sub-status.new-draft')
    }
    return t('features.coaching-session.summary-commit.missions-recap.sub-status.active-mission')
  }

  let missionCurrentStatus = getMissionSubStatus(mission)

  return (
    <>
      <Widget
        style={{ background: backgroundColourDefault }}
        header={
          <WidgetHeader
            title={<SubHeading>{selectedValues.title}</SubHeading>}
            subtitle={
              pageType === 'teamlead' &&
              showStatus &&
              t('features.coaching-session.summary-commit.missions-recap.caption', {
                status: missionCurrentStatus,
              })
            }
            rightSideContent={
              pageType === 'teamlead' ? (
                isChanged &&
                mission.status === MissionStatus.Active &&
                !mission.suggestedToComplete && (
                  <MissionOverviewDraftSwitch
                    isChanged={isChanged}
                    showChanges={showChanges}
                    setShowChanges={setShowChanges}
                  />
                )
              ) : (
                <MissionStatusIndicator status={mission.status} />
              )
            }
          />
        }
        footer={
          <>
            <Divider color={backgroundColourWhite} />
            <Flex justify="end">
              <WidgetFooter>
                <Button
                  type="tertiary"
                  small
                  text={t(
                    'features.coaching-session.summary-commit.missions-recap.actions.see-more'
                  )}
                  ssrIcon={expand}
                  iconPosition="trailing"
                  onClick={() => setDrawerOpen(true)}
                />
              </WidgetFooter>
            </Flex>
          </>
        }
      >
        <Grid>
          <Grid.Col span={6}>
            <Flex direction={'column'} my={space50}>
              <GreyBoldedSubheading>
                {t('features.coaching-session.summary-commit.missions-recap.content')}
              </GreyBoldedSubheading>
              <Paragraph>{t(`qa.${mission.qaId}`)}</Paragraph>
            </Flex>

            <Flex direction={'column'} my={space50}>
              <GreyBoldedSubheading>
                {t('features.mission.preview.coaching-pointers')}
              </GreyBoldedSubheading>
              <MissionCoachingPointersFormatted
                coachingPointers={selectedValues.coachingPointers}
              />
            </Flex>
          </Grid.Col>

          <Grid.Col span={6} my={space50}>
            <Flex direction={'column'} my={space50}>
              <GreyBoldedSubheading>
                {t('features.mission.preview.mission-planned', {
                  date: formatDate(new Date(selectedValues.endDate)),
                })}
              </GreyBoldedSubheading>
            </Flex>
            <Flex direction={'column'} my={space50}>
              <GreyBoldedSubheading>{t('features.mission.preview.target')}</GreyBoldedSubheading>
              <Paragraph>{selectedValues.target}</Paragraph>
            </Flex>
          </Grid.Col>
        </Grid>
      </Widget>

      {/* Drawer component for mission details */}
      {openDrawer && (
        <MissionOverview
          missionId={mission.id}
          opened={openDrawer}
          close={() => setDrawerOpen(false)}
        />
      )}
    </>
  )
}

export default MissionRecap
