import FormField from '@ingka/form-field'
import RadioButton from '@ingka/radio-button'
import SSRIcon from '@ingka/ssr-icon'
import { space250 } from '@ingka/variables'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { QaMetricId } from 'config/domain/qa.config'

import { MissionStatus } from 'lib/types/mission.types'
import { MissionHelper } from 'lib/utils/mission.helper'

import { useMissionForQaId } from 'hooks/missions/useMissionForQaId.hooks'

import { colourNeutralGrey200, colourSemanticPositive } from 'styles/tokens/oldSkapa.tokens'

import QaScore from 'components/composites/QualityAssessments/QaScore'
import { useValidators } from 'components/pages/teamlead/AssignMissionPage/AssignMissionPage.validation'

import { MissionQaMetricsFormProps } from './MissionQaMetricsTable.types'

export type QaMetricsTableRowProps = {
  question: {
    groupId: string
    key: string
    id: string
    label: string
    name: string
    value: string
  }
  qaSelectForm: UseFormReturn<MissionQaMetricsFormProps> & {
    watch: (field: keyof MissionQaMetricsFormProps) => QaMetricId
    resetField: (field: keyof MissionQaMetricsFormProps) => void
  }
  isChecked: boolean
  handleCheckboxSelected: (checked: boolean, id: string) => void
  coworkerId: string
}

const QaMetricsTableRow: React.FC<QaMetricsTableRowProps> = ({
  question,
  qaSelectForm,
  isChecked,
  handleCheckboxSelected,
  coworkerId,
}) => {
  const { t } = useTranslation()
  const { control, formState, watch } = qaSelectForm
  const { validateMetric } = useValidators()

  const { qaId } = watch()
  const questionId = question.id

  const mission = useMissionForQaId(questionId, coworkerId)

  return (
    <>
      <tr
        style={{
          textAlign: 'center',
          verticalAlign: 'middle',
          cursor: 'pointer',
        }}
        onClick={() => handleCheckboxSelected(true, question.id)}
      >
        <td style={{ paddingLeft: space250 }}>
          <Controller
            name="qaId"
            control={control}
            rules={{
              required: t('features.mission.create-mission.fields.metric.errors.missing'),
              validate: () => validateMetric(qaId),
            }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <FormField
                  data-testid="form-field-metric"
                  shouldValidate={formState.errors.qaId !== undefined}
                  valid={formState.errors.qaId == null}
                  validation={{
                    msg: formState.errors.qaId?.message as string,
                  }}
                >
                  <RadioButton
                    id={question.id}
                    subtle
                    label={
                      <p
                        style={{
                          fontWeight: mission?.status === MissionStatus.Active ? 'bold' : '',
                          color:
                            mission?.status === MissionStatus.Active ? colourSemanticPositive : '',
                        }}
                      >
                        {t(`qa.${questionId}`) as string}
                      </p>
                    }
                    value={value ?? question.id}
                    checked={isChecked ?? question.id === value}
                    onChange={(e) => {
                      onChange(e.target.checked)
                      handleCheckboxSelected(e.target.checked, question.id)
                    }}
                    onBlur={onBlur}
                  />
                </FormField>
              )
            }}
          />
        </td>

        {/* PreviousScore */}
        <td>
          <QaScore coworkerId={coworkerId} qaId={questionId} type="previous" />
        </td>

        {/*  newScore */}
        <td>
          <QaScore coworkerId={coworkerId} qaId={questionId} type="current" />
        </td>

        {/* In mission indicator */}
        <td align="center" style={{ textAlign: 'center', verticalAlign: 'middle' }}>
          <SSRIcon
            style={{
              backgroundColor: colourNeutralGrey200,
              padding: '5px',
              width: '35px',
              height: '35px',
              borderRadius: '50%',
            }}
            paths={MissionHelper.getIconFromMission(mission)}
          />
        </td>
      </tr>
    </>
  )
}

export default QaMetricsTableRow
