import List from '@ingka/list'
import { Trans, useTranslation } from 'react-i18next'

import { Paragraph } from 'components/primitives/Text'

import * as Styled from './WelcomeModal.styling'

const CoworkerContentInstructions = () => {
  const { t } = useTranslation()

  return (
    <div>
      <Paragraph>
        <Styled.Paragraph>
          {t('introduction.welcome-modal.coworker-content.instructions-1')}
        </Styled.Paragraph>

        <List>
          <li>{t('introduction.welcome-modal.coworker-content.instructions-2')}</li>
          <li>{t('introduction.welcome-modal.coworker-content.instructions-3')}</li>
          <li>{t('introduction.welcome-modal.coworker-content.instructions-4')}</li>
        </List>

        <Styled.Paragraph>
          <Trans i18nKey="introduction.welcome-modal.feedback">
            If you have a great idea for Insikt or think that something is missing or not working,
            reach out to us on
            <a
              href="https://teams.microsoft.com/l/team/19%3aRQ5meDGPGb_a4y_-xzZU0lsjHWePLYPMEku8dQLjK-41%40thread.tacv2/conversations?groupId=152e29cf-423f-4115-b34d-e7ddf18e96ae&tenantId=720b637a-655a-40cf-816a-f22f40755c2c"
              target="_blank"
              rel="noreferrer"
            >
              Teams
            </a>
            !
          </Trans>
        </Styled.Paragraph>
      </Paragraph>
    </div>
  )
}

export default CoworkerContentInstructions
