import { ReactNode } from 'react'

import { SkapaIconPaths } from 'lib/consts'

export type TabDefinitions = TabDef[]

export interface TabDef {
  key: string
  tKeyForTitle: string
  content: ReactNode
  disabled?: {
    tooltipText: string
    isDisabled: boolean
  }
  icon: SkapaIconPaths
  badgeCount?: ReactNode
}

export interface TabLayoutProps {
  tabDefinitions: TabDefinitions
  analyticsDescription?: string
}

// Separate from TabKeys since they are used in different contexts
export enum CoworkerCoachingSessionTabKeys {
  CoachingSession = 'coworker-coaching-session',
  Mission = 'coworker-mission',
}
