import SkapaText from '@ingka/text'
import { space100 } from '@ingka/variables'

import { textColour2, textColour3 } from 'styles/tokens/insikt.tokens'
import { colourBlueHeading } from 'styles/tokens/newInsikt.tokens'
import {
  colourNeutralGrey500,
  colourSemanticNegative,
  colourSemanticPositive,
} from 'styles/tokens/oldSkapa.tokens'

import styled from 'styled-components'

import { TextProps } from './Text.types'

const Text = styled(SkapaText)<TextProps>`
  ${({ $bold }) => ($bold ? 'font-weight: bold;' : '')}
  ${({ $italic }) => ($italic ? 'font-style: italic;' : '')}
`

/** Large text used for titles. */
export const Title = styled(Text)`
  margin-bottom: ${space100};
`

Title.defaultProps = {
  headingSize: 'l',
  tagName: 'h2',
}

export const TitleBlue = styled(Text)`
  color: ${colourBlueHeading};
  margin-bottom: 0;
  font-size: 2.25rem;
  font-weight: 700;
`
export const SubTitleBlue = styled(Text)`
  color: ${colourBlueHeading};
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 700;
`

/** Large text used short-length text of medium emphasis. */
export const Heading = styled(Text)``

Heading.defaultProps = {
  headingSize: 'm',
  tagName: 'h3',
}

/** Slightly greyed-out subtitle/helper text */
export const HelperText = styled(Text)`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: ${textColour3};
  line-height: 150%; /* 18px */
`
HelperText.defaultProps = {
  bodySize: 's',
}

/** Large Heading used for numbers in Customer Feedback */
export const LargeHeading = styled(Heading)`
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 132%; /* 68.64px */
  letter-spacing: -0.473px;
  color: ${textColour2};
`

export const Subtitle = styled(Heading)`
  margin-bottom: ${space100};
  letter-spacing: -0.25px;
`

/** Medium-size text used short-length text of lower emphasis. */
export const SubHeading = styled(Text)``

SubHeading.defaultProps = {
  headingSize: 's',
  tagName: 'h4',
}

/** Text used for paragraphs. */
export const Paragraph = styled(Text)``

/* Small text used for annotations. **/
export const Caption = styled(Text)`
  font-style: italic;
`

Caption.defaultProps = {
  bodySize: 's',
}

/** Small text used before titles to introduce headlines. */
export const Overline = styled(Text)`
  text-transform: uppercase;
`

Overline.defaultProps = {
  bodySize: 's',
}

/** Text used for form field labels. */
export const Label = styled(Text)`
  font-weight: 300;
`
Label.defaultProps = {
  bodySize: 's',
}

/* Bolded subheadings used for mission preview progress and connected metrics */
export const GreyBoldedSubheading = styled(Paragraph)`
  color: ${colourNeutralGrey500};
  font-weight: 900;
`
/* Large text in color to show difference value in progress */
export const PositiveNumber = styled(SubHeading)`
  color: ${colourSemanticPositive};
  font-weight: 900;
`
export const NegativeNumber = styled(SubHeading)`
  color: ${colourSemanticNegative};
  font-weight: 900;
`
