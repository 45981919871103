import { space50 } from '@ingka/variables'
import { Box } from '@mantine/core'
import * as DOMPurify from 'dompurify'

import { isJsonString, jsonToHtml } from 'lib/utils/generateHTML.helper'

type MissionCoachingPointersFormattedProps = {
  coachingPointers: string
}
const MissionCoachingPointersFormatted: React.FC<MissionCoachingPointersFormattedProps> = ({
  coachingPointers,
}) => {
  const santizedHTMLCoachingPointers = DOMPurify.sanitize(coachingPointers || '')

  const renderMarkup = () => {
    return { __html: jsonToHtml(JSON.parse(santizedHTMLCoachingPointers)) }
  }

  return (
    <>
      {isJsonString(coachingPointers) ? (
        <Box m={space50} dangerouslySetInnerHTML={renderMarkup()} />
      ) : (
        <Box>{coachingPointers}</Box>
      )}
    </>
  )
}

export default MissionCoachingPointersFormatted
