import { skipToken } from '@reduxjs/toolkit/dist/query'

import {
  isMetricId,
  MetricId,
  metricsConfig,
  qualityAssessmentConfig,
} from 'config/domain/metrics.config'
import { isQaMetricId } from 'config/domain/qa.config'

import { MetricOwnerType, MetricValueType, PeriodType } from 'lib/types/metric.types'
import { DateHelper } from 'lib/utils/date.helper'

import { useMetrics } from 'state/slices/api'

type UseDatasetsArgs = {
  metricId: MetricId
  selectedValueType: MetricValueType
  startDate: Date
  endDate: Date
  coworkerId: string
  teamId?: string
}

export const useDatasets = ({
  metricId,
  selectedValueType,
  startDate,
  endDate,
  coworkerId,
  teamId,
}: UseDatasetsArgs) => {
  const indicatorConfig = isMetricId(metricId)
    ? metricsConfig[metricId]
    : isQaMetricId(metricId)
      ? qualityAssessmentConfig
      : undefined

  const { metricBehaviour } = indicatorConfig ?? {}

  const { noTrendData: noTrend, noTeamData } = metricBehaviour ?? {}

  const valueType = noTrend ? MetricValueType.Raw : selectedValueType

  const startDateAsString = DateHelper.dateToString(startDate)
  const endDateAsString = DateHelper.dateToString(endDate)

  const coworkerQuery = useMetrics({
    metricId: metricId,
    owner: {
      id: coworkerId,
      type: MetricOwnerType.Coworker,
    },
    valueType,
    periodType: PeriodType.Day,
    timeRange: {
      start: startDateAsString,
      end: endDateAsString,
    },
  })

  const teamMetricArgs =
    noTeamData || teamId == null
      ? skipToken
      : {
          metricId: metricId,
          owner: {
            id: teamId,
            type: MetricOwnerType.Team,
          },
          valueType,
          timeRange: {
            start: startDateAsString,
            end: endDateAsString,
          },
          periodType: PeriodType.Day,
        }

  const teamQuery = useMetrics(teamMetricArgs)

  return [coworkerQuery, teamQuery]
}
