import { LineAnnotationOptions } from 'chartjs-plugin-annotation'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { Coworker } from 'lib/types/coworker.types'
import { Mission } from 'lib/types/mission.types'

import { comparisonColour } from 'styles/tokens/insikt.tokens'
import { colourBrandRed } from 'styles/tokens/oldSkapa.tokens'

import { MetricLineChart } from 'components/features/Metrics/MetricLineChart'

interface Props {
  mission: Mission
  coworker: Coworker
  missionEnd: string
  completed?: boolean
}
const MissionMetricChart = ({ mission, coworker, missionEnd, completed }: Props) => {
  const { t } = useTranslation()

  const annotations: LineAnnotationOptions[] = [
    {
      xMin: dayjs(mission.startedDate).toDate().getTime(),
      xMax: dayjs(mission.startedDate).toDate().getTime(),
      borderWidth: 2,
      label: {
        content: t('features.mission.preview.mission-start'),
        display: true,
        position: 'start',
        z: 1,
      },

      borderColor: colourBrandRed,
    },
    {
      xMin: dayjs(missionEnd).toDate().getTime(),
      xMax: dayjs(missionEnd).toDate().getTime(),
      borderWidth: 2,
      label: {
        content: t('features.mission.preview.mission-end'),
        display: true,
        position: 'start',
        z: 1,
      },
      borderColor: colourBrandRed,
    },
  ]

  if (!completed) {
    annotations.push({
      xMin: dayjs().toDate().getTime(),
      xMax: dayjs().toDate().getTime(),
      borderWidth: 2,
      label: {
        content: t('features.mission.preview.today'),
        display: true,
        position: 'end',
        z: 1,
        xAdjust: -25,
      },
      borderColor: comparisonColour,
    })
  } else {
    annotations.push({
      xMin: dayjs(mission.completedDate).toDate().getTime(),
      xMax: dayjs(mission.completedDate).toDate().getTime(),
      borderWidth: 2,
      label: {
        content: t('features.mission.preview.completed'),
        display: true,
        position: 'end',
        z: 1,
        xAdjust: -25,
      },
      borderColor: comparisonColour,
    })
  }

  if (!mission.metricId) {
    return <></>
  }

  return (
    <MetricLineChart
      data-testid="performance-chart"
      metricId={mission.metricId}
      coworkerId={coworker.legacyId}
      teamId={coworker.teamId}
      startDate={dayjs(mission.startedDate).subtract(1, 'months').toDate()}
      endDate={new Date(missionEnd)}
      annotations={annotations}
    />
  )
}

export default MissionMetricChart
