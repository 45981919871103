import Button from '@ingka/button'
import Modal, { ModalBody, ModalHeader, Sheets } from '@ingka/modal'
import create from '@ingka/ssr-icon/paths/create-new'
import { space100 } from '@ingka/variables'
import { Box, Group } from '@mantine/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Divider } from 'components/primitives/Divider'
import { Paragraph, SubHeading, Subtitle } from 'components/primitives/Text'

type ContactModalProps = {
  opened: boolean
  onClose: () => void
}

const ContactModal: React.FC<ContactModalProps> = (modalProps) => {
  const { t } = useTranslation()
  const { opened, onClose } = modalProps
  const serviceDeskLink = 'https://jira.digital.ingka.com/servicedesk/customer/portal/499'

  // Workaround for adding underline on hover in inline styles
  const onMouseEnter = (e: any) => {
    e.target.style.textDecoration = 'underline'
  }
  const onMouseLeave = (e: any) => {
    e.target.style.textDecoration = 'none'
  }

  return (
    <>
      <Modal visible={opened} handleCloseBtn={onClose}>
        <Sheets
          alignment="right"
          aria-label="Feedback and user request"
          footer={null}
          header={<ModalHeader />}
          size="small"
        >
          <ModalBody>
            <Box px={space100}>
              <Subtitle>{t('features.meta.feedback.title')}</Subtitle>
              <Link
                to={serviceDeskLink}
                target="_blank"
                style={{ textDecoration: 'none', cursor: 'pointer' }}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <Group justify="space-between" align="center" my={space100}>
                  <SubHeading style={{ letterSpacing: '-0.35px' }}>
                    {t('features.meta.feedback.service-desk-link.title')}
                  </SubHeading>
                  <Button type="primary" small iconOnly ssrIcon={create} />
                </Group>
              </Link>
              <Paragraph>{t('features.meta.feedback.service-desk-link.description')}</Paragraph>
              <Divider />
            </Box>
          </ModalBody>
        </Sheets>
      </Modal>
    </>
  )
}

export default ContactModal
