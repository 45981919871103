import chat from '@ingka/ssr-icon/paths/chat'
import checkmarkBox from '@ingka/ssr-icon/paths/checkmark-box'
import magnifyingGlass from '@ingka/ssr-icon/paths/magnifying-glass'
import people from '@ingka/ssr-icon/paths/people'
import person from '@ingka/ssr-icon/paths/person'

import { TabLayout } from 'components/composites/Shared/TabLayout/TabLayout.component'
import { TabDefinitions } from 'components/composites/Shared/TabLayout/TabLayout.types'
import { CoachingSessionPreviewList } from 'components/features/CoachingSessions/CoachingSessionPreviewList'
import { EmbedReport } from 'components/features/EmbedReport'
import { MissionPreviewList } from 'components/features/Missions/MissionPreviewList'
import { CustomerCommentsPage } from 'components/pages/shared/CustomerCommentOverviewPage'

import CoworkerInformationTab from '../CoworkerInformationTab/CoworkerInformationTab.component'
import { TabKeys } from './TeamleadTabs.constants'

type TeamleadTabsProps = {
  coworkerId: string
}

const TeamleadTabs: React.FC<TeamleadTabsProps> = ({ coworkerId }) => {
  const tabsArray: TabDefinitions = [
    {
      key: TabKeys.Missions,
      content: <MissionPreviewList />,
      tKeyForTitle: 'pages.manage-coworker.tabs.missions.title',
      icon: checkmarkBox,
    },
    {
      key: TabKeys.Explore,
      content: <EmbedReport reportName="top-level-dashboard" pageTitle={TabKeys.Explore} />,
      tKeyForTitle: 'pages.manage-coworker.tabs.explore.title',
      icon: magnifyingGlass,
    },
    {
      key: TabKeys.CoachingSession,
      content: <CoachingSessionPreviewList coworkerId={coworkerId} />,
      tKeyForTitle: 'pages.manage-coworker.tabs.coaching-sessions.title',
      icon: people,
    },
    {
      key: TabKeys.CoworkerInformation,
      content: <CoworkerInformationTab />,
      tKeyForTitle: 'pages.manage-coworker.tabs.coworker-information.title',
      icon: person,
    },
    {
      key:  TabKeys.CustomerComments,
      content: <CustomerCommentsPage />,
      tKeyForTitle: 'pages.manage-coworker.tabs.customer-comments.title',
      icon: chat,
      // Disable customerComments tab in v2
      disabled: {
        isDisabled: process.env.REACT_APP_ENV === 'v2', 
        tooltipText: 'common.coming-soon',
      },
    },
  ]

  return (
    <TabLayout
      tabDefinitions={tabsArray}
      analyticsDescription="Custom event for tracking when a teamlead switches between tabs on coworker page."
    />
  )
}

export default TeamleadTabs
