import { useEffect } from 'react'

import { UserRole } from 'lib/types/user.types'

import { useUser } from 'hooks/users'

import { RedirectingLayout } from 'components/layout/RedirectingLayout'
import { useNavigateWithReplace } from 'components/pages/global/StartPage/StartPage.hooks'

// TODO: Move
export const DEMO_TEAM_LEAD_ID = 'DEMO_T2_LEAD'

/** Pseudo-page used to redirect the user to the correct page based on their role. */
const StartPage: React.FC = () => {
  const navigate = useNavigateWithReplace()

  const { data: user } = useUser()

  useEffect(() => {
    if (user) {
      const { role, legacyId } = user
      if (role === UserRole.Admin) {
        navigate('/admin')
      } else if (role === UserRole.TeamLead) {
        navigate(`/tl/${legacyId}/coworkers`)
      } else if (role === UserRole.Coworker) {
        navigate(`/cw/${legacyId}`)
      } else if (role === UserRole.Guest) {
        navigate('/guest')
      } else if (role === UserRole.Qs) {
        navigate('/qs/users')
      } else if (role === UserRole.Demo) {
        navigate(`demo/tl/${DEMO_TEAM_LEAD_ID}/coworkers`)
      } else if (process.env.REACT_APP_ENV === 'dev') {
        throw new Error('Cannot match the role to the navigation path')
      }
    }
  }, [navigate, user])

  return <RedirectingLayout />
}

export default StartPage
