import { space125 } from '@ingka/variables'

export const loadingWidgetStyle = {
  marginTop: space125,
  height: '20vh',
  width: '50vw',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
}

// TODO: Make this global?
export const centeredFlex = { justifyContent: 'center', display: 'flex' }
