import Button from '@ingka/button'
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal'
import SSRIcon from '@ingka/ssr-icon'
import InformationCircle from '@ingka/ssr-icon/paths/information-circle'
import Tooltip from '@ingka/tooltip'
import { space50, space100 } from '@ingka/variables'
import { Flex, Progress } from '@mantine/core'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { useUser } from 'hooks/users'

import { useApplyGameEvent, useGameEvents } from 'state/slices/api'

import {
  colourNeutralGrey100,
  colourSemanticCaution,
  colourSemanticPositive,
} from 'styles/tokens/oldSkapa.tokens'

import GameInfo from './components/GameInfo.component'
import { Paragraph, SubHeading } from 'components/primitives/Text'

import { getEventText, getLevel, getTotalXpAmountForLevel, xpAmountForLevelUp } from './Level.utils'

interface Props {
  legacyId: string
}

const Level = ({ legacyId }: Props) => {
  // Data and hooks
  const { data: gameEvents = [], isSuccess } = useGameEvents(legacyId)
  const [applyEvent] = useApplyGameEvent()
  const [isInfoOpen, setIsInfoOpen] = useState(false)

  // Local state
  const [transitionDuration, setTransitionDuration] = useState(1500)

  const [showEventModal, setShowEventModal] = useState(true) // State for the event modal visibility.

  const [currentXp, setCurrentXp] = useState(NaN) // State for the current xp
  const [currentLevel, setCurrentLevel] = useState(NaN) // State for the current level

  // Additional state to manage the progress bar behavior.
  const [progressBarValue, setProgressBarValue] = useState(0)
  const [animateLevelUp, setAnimateLevelUp] = useState(false)

  const user = useUser().data
  const isSelf = user?.legacyId === legacyId

  // Effects

  useEffect(() => {
    // Calculate the current xp and level when new gameEvents data is available.
    if (isSuccess) {
      // Calculate the new xp based on the updated gameEvents data.
      const newXp = gameEvents.reduce((acc, event) => {
        if (event.applied === true) {
          return acc + event.xp
        }
        return acc
      }, 0)

      const newLevel = getLevel(newXp) // Calculate the new level based on the new xp.

      // Animate the progress bar to full before updating level state.
      if (newLevel > currentLevel) {
        setAnimateLevelUp(true) // Start the level-up animation.
        setProgressBarValue(100) // Set the progress bar to full (100%
        setCurrentXp(newXp) // Update the currentXp state to the new xp.

        setTimeout(() => {
          setTransitionDuration(0) // Set the transition duration to 0 to skip the progress bar animation.
        }, 1500)

        setTimeout(() => {
          // Update states if needed.
          setCurrentLevel(newLevel) // If the level has changed, update the state.
          setAnimateLevelUp(false) // Reset the animation state after it's done.
          setProgressBarValue(0)
        }, 2000) // Animation duration is 2000 ms as per Progress component's transitionDuration + 500 ms to show the level-up modal after animation.
      } else {
        // Update states if needed.
        if (newXp !== currentXp) setCurrentXp(newXp) // If the xp has changed, update the state.
        if (newLevel !== currentLevel) setCurrentLevel(newLevel) // If the level has changed, update the state.
      }
    }
  }, [gameEvents, currentLevel, currentXp, setCurrentXp, isSuccess])

  useEffect(() => {
    // Adjust the progress bar value based on the currentXp and the currentLevel.
    if (!animateLevelUp) {
      // Normal progress bar behavior when not animating level-up.
      setTransitionDuration(1500) // Reset the transition duration to the default value (1500 ms).

      setProgressBarValue(
        ((currentXp - getTotalXpAmountForLevel(currentLevel)) / xpAmountForLevelUp(currentLevel)) *
          100
      )
    }
  }, [currentXp, currentLevel, animateLevelUp, progressBarValue])

  return (
    <Flex direction={'column'} gap={space50} py={space50}>
      <Flex direction={'row'} justify={'space-between'}>
        <SubHeading>
          {'Insikt level '} {currentLevel}
        </SubHeading>

        <Tooltip tooltipText={t('features.game.progress-tooltip')}>
          <Flex align={'center'} gap={space50}>
            <Paragraph style={{ cursor: 'default' }}>
              {currentXp - getTotalXpAmountForLevel(currentLevel)} {'/'}{' '}
              {xpAmountForLevelUp(currentLevel)}
              {' XP'}
            </Paragraph>
            <SSRIcon
              paths={InformationCircle}
              style={{ width: space100, cursor: 'pointer' }}
              onClick={() => setIsInfoOpen(true)}
            />
          </Flex>
        </Tooltip>
      </Flex>

      <Progress.Root
        property="progress-bar"
        size={30}
        radius={10}
        w={'100%'}
        bg={colourNeutralGrey100}
        transitionDuration={transitionDuration}
      >
        <Progress.Section
          color={progressBarValue === 100 ? colourSemanticPositive : colourSemanticCaution}
          value={progressBarValue}
        >
          <Progress.Label>
            {progressBarValue === 100 && t('features.game.level-up', { level: currentLevel + 1 })}
          </Progress.Label>
        </Progress.Section>
      </Progress.Root>

      {isSelf && (
        <Flex direction={'column'} gap={space50}>
          {gameEvents
            .filter((e) => !e.applied)
            .map((event) => {
              return (
                <Modal
                  visible={showEventModal}
                  handleCloseBtn={async () => {
                    await applyEvent({
                      legacyId,
                      gameEventId: event.id,
                      userId: user.id,
                    })
                    setShowEventModal(false)
                    setTimeout(() => {
                      setShowEventModal(true)
                    }, 3000)
                  }}
                >
                  <Prompt
                    titleId="event-pop-up-prompt"
                    title={getEventText(event)} // Prompt's heading
                    header={<ModalHeader ariaCloseTxt="Close prompt"></ModalHeader>}
                    footer={
                      <ModalFooter>
                        <Button type="emphasised" small>
                          {t('features.game.apply-event')}
                        </Button>
                      </ModalFooter>
                    }
                  >
                    <Flex justify={'center'}>
                      {t('features.game.you-earned-xp', { xp: event.xp })}
                    </Flex>
                  </Prompt>
                </Modal>
              )
            })}
        </Flex>
      )}

      <GameInfo legacyId={legacyId} isOpen={isInfoOpen} close={() => setIsInfoOpen(false)} />
    </Flex>
  )
}

export default Level
