import { shadowSizeDefault, space100, space300 } from '@ingka/variables'
import { Box, Flex } from '@mantine/core'

import { colourBorderLight, colourNeutralWhite } from 'styles/tokens/oldSkapa.tokens'

type StickyFooterProps = {
  children: React.ReactNode
}
const StickyFooter: React.FC<StickyFooterProps> = ({ children, ...otherProps }) => {
  return (
    <Box
      pos="fixed"
      w="100%"
      left={0}
      bottom={0}
      bg={colourNeutralWhite}
      style={{
        zIndex: 500,
        borderTop: `1px solid ${colourBorderLight}`,
        ':hover': {
          boxShadow: shadowSizeDefault,
        },
      }}
      {...otherProps}
    >
      <Box>
        <Flex justify="space-between" align="center" px={space300} py={space100} gap={space100}>
          {children}
        </Flex>
      </Box>
    </Box>
  )
}

export default StickyFooter
