import { configureStore } from '@reduxjs/toolkit'

import { middleware as apiMiddleware, reducer as api } from 'state/slices/api'

const middleware: any = [apiMiddleware]

export const store = configureStore({
  reducer: {
    api,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
})

export type AppStore = typeof store
export type RootState = ReturnType<typeof store.getState>
