import { Route } from 'react-router-dom'

import { AdminLayout } from 'components/layout'
import {
  AdminPage,
  ManageBadgesPage,
  ManageCountriesPage,
  ManageOnboardingPage,
  UsersPage,
} from 'components/pages/admin'
import AdminStatsPage from 'components/pages/admin/AdminStatsPage/AdminStatsPage.component'

import CoworkerRoutes from './CoworkerRoutes.component'
import TeamleadRoutes from './TeamleadRoutes.component'

/**
 * Be aware that this is *not* a ReactNode but rather a list of JSX.element
 * The motivation for this is that react-router only allows <Route/> as child elements.
 */
const AdminRoutes = (
  <Route path="admin" element={<AdminLayout />}>
    <Route path="" element={<AdminPage />} />
    <Route path="users" element={<UsersPage />} />
    <Route path="badges" element={<ManageBadgesPage />} />
    <Route path="countries" element={<ManageCountriesPage />} />
    <Route path="user-fixtures" element={<ManageOnboardingPage />} />
    <Route path="admin-stats" element={<AdminStatsPage />} />
    {CoworkerRoutes}
    {TeamleadRoutes}
  </Route>
)

export default AdminRoutes
