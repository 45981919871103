import InlineMessage from '@ingka/inline-message'
import warningTriangle from '@ingka/ssr-icon/paths/warning-triangle'
import { PropsWithChildren } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

import { Divider } from 'components/primitives/Divider'
import { Caption, Paragraph } from 'components/primitives/Text'

const CustomErrorBoundary: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation()

  return (
    <ErrorBoundary
      FallbackComponent={({ error }) => (
        <InlineMessage
          variant="negative"
          ssrIcon={warningTriangle}
          title={t('common.error-boundary.title')}
          body={
            <>
              {t('common.error-boundary.body')}
              <Divider />
              <Caption>{t('common.error-boundary.details')}</Caption>
              <br />
              <Paragraph>
                <span style={{ fontWeight: 'bold' }}>{t('common.error-boundary.error-name')}</span>{' '}
                {error.name}
              </Paragraph>
              <Paragraph>
                <span style={{ fontWeight: 'bold' }}>
                  {t('common.error-boundary.error-message')}
                </span>{' '}
                {error.message}
              </Paragraph>
            </>
          }
          actions={[
            {
              text: t('common.actions.refresh-page'),
              onClick: () => window.location.reload(),
            },
            {
              text: t('common.actions.report-problem'),
              href: 'https://teams.microsoft.com/l/channel/19%3ae72c7ab094db421abdff64761d6203c7%40thread.tacv2/Bug%2520Reports?groupId=152e29cf-423f-4115-b34d-e7ddf18e96ae&tenantId=720b637a-655a-40cf-816a-f22f40755c2c',
            },
          ]}
        />
      )}
    >
      {children}
    </ErrorBoundary>
  )
}
export default CustomErrorBoundary
