import { fontSize300, space50, space200 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { Coworker } from 'lib/types/coworker.types'
import { Mission } from 'lib/types/mission.types'

import { colourNeutralGrey700 } from 'styles/tokens/oldSkapa.tokens'

import { Paragraph } from 'components/primitives/Text'

import MissionMetricChart from '../../MissionMetricChart/MissionMetricChart.component'

interface Props {
  metricId: string
  coworker: Coworker
  mission: Mission
  missionEnd: string
  completed?: boolean
}

const MissionOverviewConnectedMetric = ({
  metricId,
  coworker,
  mission,
  missionEnd,
  completed,
}: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <Flex
        direction={'column'}
        pt={space200}
        align={'start'}
        style={{ width: '100%', height: '100%', flexGrow: 1 }}
      >
        <Paragraph
          style={{
            fontSize: fontSize300,
            color: colourNeutralGrey700,
            fontWeight: 'bold',
            marginBottom: space50,
          }}
        >
          {t('features.mission.preview.connected-metric') +
            ' - ' +
            t(`common.metrics.${mission.metricId}.name` as string)}
        </Paragraph>
        <MissionMetricChart
          coworker={coworker}
          mission={mission}
          missionEnd={missionEnd}
          completed={completed}
        />
      </Flex>
    </>
  )
}

export default MissionOverviewConnectedMetric
