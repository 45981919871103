import { orderBy } from 'lodash'
import { useMemo } from 'react'

import { Mission } from 'lib/types/mission.types'

export const useMissionMonthSplit = (missions: Mission[]) => {
  const splitMonths = useMemo(() => {
    const finishedMissions = missions.filter((mission) => mission.completedDate)
    const months = finishedMissions.reduce(
      (acc, mission) => {
        const month = new Date(mission.completedDate!).getMonth()
        const year = new Date(mission.completedDate!).getFullYear()
        const index = `${year}-${month}`
        acc[index] = acc[index] ? [...acc[index], mission] : [mission]
        return acc
      },
      {} as Record<string, Mission[]>
    )
    const sortedMonths = Object.entries(months)
      .map(([key, value]) => {
        return {
          key,
          missions: orderBy(value, (mission) => mission.completedDate, 'desc'),
        }
      })
      .sort((a, b) => {
        return b.key.localeCompare(a.key)
      })
      .map(({ key, missions }) => {
        const [year, month] = key.split('-')
        return {
          year: parseInt(year),
          month: parseInt(month),
          missions,
        }
      })

    return sortedMonths
  }, [missions])

  return splitMonths
}
