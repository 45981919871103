import SSRIcon from '@ingka/ssr-icon'
import { Group } from '@mantine/core'
import { FC } from 'react'

import { SkapaIconPaths } from 'lib/consts'

import { colourGreyNeutral2, radius25,spacing25, spacing75 } from 'styles/tokens/insikt.tokens'

interface CommentInfoGroupProps {
  icon?: SkapaIconPaths
  children: React.ReactNode
}

const CustomerCommentInfoGroup: FC<CommentInfoGroupProps> = ({ icon, children }) => {
  return (
    <Group
      bg={colourGreyNeutral2}
      p={`${spacing25} ${spacing75}`}
      gap={spacing25}
      wrap="wrap"
      align="center"
      style={{ borderRadius: radius25 }}
    >
      {icon && (
        <SSRIcon
          paths={icon}
          style={{ width: '1.25rem', height: '1.25rem', marginRight: spacing25 }}
        />
      )}
      {children}
    </Group>
  )
}

export default CustomerCommentInfoGroup
