import { getAnalytics, setUserId } from 'firebase/analytics'
import { User } from 'firebase/auth'
import { useEffect, useState } from 'react'

import { onAuthStateChanged, signIn } from 'config/firebase/firebase.config'

import { invalidateTags } from 'state/slices/api'
import { useAppDispatch } from 'state/utilities'

export const useAuthUser = () => {
  const dispatch = useAppDispatch()
  const [authUser, setAuthUser] = useState<User>()
  const analytics = getAnalytics()

  useEffect(() => {
    onAuthStateChanged().then((user) => {
      if (user) {
        setAuthUser(user)

        setUserId(analytics, user.email)

        dispatch(invalidateTags(['Coworker']))
        dispatch(invalidateTags(['User']))
      } else {
        signIn()
      }
    })
  }, [dispatch, analytics])

  return authUser
}
