import { fontSize300 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { colourNeutralGrey700 } from 'styles/tokens/oldSkapa.tokens'

import { MissionCoachingPointersFormatted } from 'components/composites/Missions/MissionCoachingPointersFormatted'
import { Paragraph } from 'components/primitives/Text'

interface Props {
  coachingPointers: string
}

const MissionOverviewCoachingPointers = ({ coachingPointers }: Props) => {
  const { t } = useTranslation()

  return (
    <Flex direction={'column'}>
      <Paragraph
        style={{
          fontSize: fontSize300,
          color: colourNeutralGrey700,
          fontWeight: 'bold',
        }}
      >
        {t('features.mission.preview.coaching-pointers')}
      </Paragraph>
      <MissionCoachingPointersFormatted coachingPointers={coachingPointers} />
    </Flex>
  )
}

export default MissionOverviewCoachingPointers
