import { useTranslation } from 'react-i18next'

import { MissionStatus } from 'lib/types/mission.types'

import {
  colourBrandBlue,
  colourCampaignSustainability,
  colourNeutralGrey300,
} from 'styles/tokens/oldSkapa.tokens'

import { StatusIndicator } from 'components/composites/Shared/StatusIndicator'

import { MissionStatusIndicatorProps } from './MissionStatusIndicator.types'

const MissionStatusIndicator: React.FC<MissionStatusIndicatorProps> = ({ status }) => {
  const { t } = useTranslation()

  let color, label
  switch (status) {
    case MissionStatus.Draft:
      color = colourNeutralGrey300
      label = t('features.mission.status.draft')
      break
    case MissionStatus.Active:
      color = colourCampaignSustainability
      label = t('features.mission.status.active')
      break
    case MissionStatus.Completed:
      color = colourBrandBlue
      label = t('features.mission.status.completed')
      break
  }

  return <StatusIndicator label={label} color={color} />
}

export default MissionStatusIndicator
