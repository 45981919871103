import { Box, Breadcrumbs, Divider } from '@mantine/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'
import { useValidateParams } from 'hooks/navigation/useValidateParams'

import { colourGreyNeutral3, spacing50, spacing100 } from 'styles/tokens/insikt.tokens'
import { colourBrandBlue } from 'styles/tokens/oldSkapa.tokens'

import { CoworkerCompletedCoachingSessions } from 'components/features/CoachingSessions/CoachingSessionPreviewList/CoworkerCompletedCoachingSessions.component'
import { RedirectingLayout } from 'components/layout/RedirectingLayout'

const linkStyles = {
  color: colourBrandBlue,
  textDecoration: 'none',
  underline: `1px solid ${colourBrandBlue}`,
  cursor: 'pointer',
  outline: 'none',
  borderBottom: `1px solid ${colourBrandBlue}`,
}

const CoachingSessionsPage: React.FC = () => {
  const { t } = useTranslation()

  const { coworkerId } = useParams()

  const { coworkerPath } = useSectionPaths()

  useValidateParams('coworkerId')
  if (!coworkerId) return <RedirectingLayout />

  const breadcrumbMenu = [
    {
      title: t('features.coaching-session.completed-coaching-sessions.breadcrumbs-menu.home'),
      href: `${coworkerPath}`,
    },
    {
      title: t(
        'features.coaching-session.completed-coaching-sessions.breadcrumbs-menu.coaching-sessions'
      ),
      href: `${coworkerPath}/coaching-sessions`,
    },
  ].map((item) => (
    <nav key={item.title}>
      <Link
        style={
          item.href === window.location.pathname
            ? { ...linkStyles, fontWeight: 'bold' } // bolded if it's the current page
            : linkStyles
        }
        to={item ? item.href : '/'}
      >
        {item.title}
      </Link>
    </nav>
  ))

  return (
    <Box data-testid="page-coaching-sessions">
      <Box my={spacing100}>
        <Breadcrumbs>{breadcrumbMenu}</Breadcrumbs>
      </Box>
      <Divider size={'xs'} color={colourGreyNeutral3} my={spacing50} />

      <CoworkerCompletedCoachingSessions />
    </Box>
  )
}

export default CoachingSessionsPage
