import { QaType } from 'lib/types/qualityAssessment.types'
import { MissionHelper } from 'lib/utils/mission.helper'
import { QualityAssessmentHelper } from 'lib/utils/qualityAssessment.helper'

import { useCurrentQaDataForCoworker } from 'hooks/qualityAssssments/useCurrentQaDataForCoworker.hooks'

import { useMissions } from 'state/slices/api'

export const useCurrentSessionData = (coworkerId: string) => {
  const { data: missions = [] } = useMissions(coworkerId)

  const coachingCycleAssessments = useCurrentQaDataForCoworker(coworkerId)?.currentQas ?? []

  const coachingCycleMissions = MissionHelper.getActiveMissions(missions)

  // Resolution
  const resolutionAssessments = QualityAssessmentHelper.filterQasByTopId(
    coachingCycleAssessments,
    QaType.QAR
  )
  const resolutionMissions = MissionHelper.filterMissionsByType(missions, QaType.QAR)

  // Sales
  const salesAssessments = QualityAssessmentHelper.filterQasByTopId(
    coachingCycleAssessments,
    QaType.QAS
  )
  const salesMissions = MissionHelper.filterMissionsByType(missions, QaType.QAS)

  // SAC
  const sacAssessments = QualityAssessmentHelper.filterQasByTopId(
    coachingCycleAssessments,
    QaType.QSA
  )
  const sacnMissions = MissionHelper.filterMissionsByType(missions, QaType.QSA)

  return {
    assessments: coachingCycleAssessments,
    missions: coachingCycleMissions,
    resolution: {
      assessments: resolutionAssessments,
      missions: resolutionMissions,
    },
    sales: {
      assessments: salesAssessments,
      missions: salesMissions,
    },
    sac: {
      assessments: sacAssessments,
      missions: sacnMissions,
    },
  }
}
