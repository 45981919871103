import { Grid } from '@mantine/core'
import { orderBy } from 'lodash'

import { spacing75 } from 'styles/tokens/insikt.tokens'

import { CustomerCommentAnimationCard } from 'components/features/CustomerComments/CustomerCommentAnimations'
import classes from 'components/features/CustomerComments/CustomerCommentAnimations/CustomerCommentAnimations.module.css'
import { CustomerCommentContent } from 'components/features/CustomerComments/CustomerCommentPreview'

import { CustomerCommentPreviewProps } from '../CustomerComments.types'

const CustomerCommentPreview: React.FC<CustomerCommentPreviewProps> = ({
  customerComments: comments,
  availableActions,
  cardActions,
  onConfirm,
}) => {
  return (
    <Grid gutter={spacing75} justify={'flex-start'}>
      {orderBy(comments, 'date', 'desc').map((comment) => {
        const action = cardActions[comment.interactionId] ?? null
        return (
          <Grid.Col
            key={comment.interactionId}
            span={{ base: 6, xs: 12, md: 6 }}
            className={`${classes.cardWrapper} ${action ? classes[action + 'Animation'] : ''}`}
          >
            {!action ? (
              <CustomerCommentContent
                key={comment.interactionId}
                comment={comment}
                actions={availableActions}
                onConfirm={(action) => onConfirm(comment.interactionId, action)}
              />
            ) : (
              <CustomerCommentAnimationCard action={action} />
            )}
          </Grid.Col>
        )
      })}
    </Grid>
  )
}

export default CustomerCommentPreview
