import Text from '@ingka/text'
import { Stack } from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { useState } from 'react'

import { debounceTimeDefault } from 'styles/tokens/insikt.tokens'

import { Widget } from 'components/composites/Shared/Widget'
import { UsersOverview } from 'components/features/Users/UsersOverview'
import { PageHeader } from 'components/layout/PageHeader'

import * as Styled from './UsersPage.styling'

type UsersPageProps = {
  demoSearch?: boolean
}

const UsersPage = ({ demoSearch = false }: UsersPageProps) => {
  const [searchTerm, setSearchTerm] = useState(demoSearch ? 'DEMO_T' : '')
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, debounceTimeDefault)

  return (
    <>
      <PageHeader backHref={demoSearch ? undefined : '/admin'} title="Users" />

      <Stack>
        <Widget>
          <Text tagName="p">
            {'You can search for any user here by their full name or IKEA ID.'}
          </Text>
          <Text tagName="p">{'You can also search for just the beginning of a name or ID.'}</Text>

          <Styled.SearchField
            id="userSearchTerm"
            type="text"
            value={searchTerm}
            disabled={demoSearch}
            onChange={(e: any) => setSearchTerm(e.target.value)}
          />
          <UsersOverview query={debouncedSearchTerm} demoSearch={demoSearch} />
        </Widget>
      </Stack>
    </>
  )
}

export default UsersPage
