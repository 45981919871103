import { useTranslation } from 'react-i18next'

import { isQaMetricId, QaMetricId } from 'config/domain/qa.config'

import { useTranslatedQuestions } from 'state/slices/api/api.hooks'

export const useQuestionTitle = (qaId: QaMetricId) => {
  const { t } = useTranslation()
  const { data, isLoading } = useTranslatedQuestions()
  if (!isQaMetricId(qaId)) {
    return { title: t('common.error.n/a'), isLoading: false }
  }

  // In case there is no translations on the backend - should not happen - use the translation from the frontend
  // Those are less nice, but a good default
  const title = data?.translations[qaId]?.title ?? t(`qa.${qaId}`)

  return { title, isLoading }
}
