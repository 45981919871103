import { fontSize300 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { Mission } from 'lib/types/mission.types'

import { colourNeutralGrey700 } from 'styles/tokens/oldSkapa.tokens'

import { Paragraph } from 'components/primitives/Text'

import MissionQaProgress from '../../MissionQaProgress/MissionQaProgress.component'

interface Props {
  mission: Mission
  coworkerId: string
  showChanges?: boolean
}

const MissionOverviewQaProgression = ({ mission, coworkerId, showChanges }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <Flex w={'100%'} direction={'column'} align={'start'}>
        <Paragraph
          style={{
            fontSize: fontSize300,
            color: colourNeutralGrey700,
            fontWeight: 'bold',
          }}
        >
          {t('features.mission.preview.qa-progress')}
        </Paragraph>
      </Flex>

      <MissionQaProgress mission={mission} showChanges={showChanges} coworkerId={coworkerId} />
    </>
  )
}

export default MissionOverviewQaProgression
