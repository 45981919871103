import { ErrorBoundary } from 'components/shared/ErrorBoundary'

import * as Styled from './Widget.styling'

type WidgetProps = {
  /** Element inserted at the start of the Widget. The element should be a WidgetHeader */
  header?: React.ReactNode
  /** Element inserted at the end of the Widget. The element should be a WidgetFooter */
  footer?: React.ReactNode
  border?: boolean
} & React.HTMLAttributes<HTMLDivElement>

const Widget: React.FC<WidgetProps> = ({
  children,
  header,
  footer,
  border = false,
  ...htmlAttributes
}) => {
  return (
    <ErrorBoundary>
      <Styled.Widget $border={border} {...htmlAttributes}>
        <>
          {header}
          {children}
          {footer}
        </>
      </Styled.Widget>
    </ErrorBoundary>
  )
}

export default Widget
