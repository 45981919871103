import Skeleton from '@ingka/skeleton'
import SSRIcon from '@ingka/ssr-icon'
import accessibility from '@ingka/ssr-icon/paths/accessibility'
import checkmark from '@ingka/ssr-icon/paths/checkmark-green-small'
import person from '@ingka/ssr-icon/paths/person'
import { Flex, Group, Stack } from '@mantine/core'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useCoworkerId } from 'hooks/id/useCoworkerId.hooks'

import {
  colourGreyNeutral2,
  radius25,
  spacing25,
  spacing50,
  spacing75,
  spacing100,
} from 'styles/tokens/insikt.tokens'

import { LoadingSkeleton } from 'components/composites/Shared/LoadingSkeleton/LoadingSkeleton.component'
import { Widget } from 'components/composites/Shared/Widget'
import { Paragraph, TitleBlue } from 'components/primitives/Text'

import Level from './Level/Level.component'
import { useLoadProfileData } from './ProfileInfo.hook'
import ProfilePhoto from './ProfilePhoto/ProfilePhoto.component'

export const ProfileInfo: FC = () => {
  const { t } = useTranslation()
  const coworkerId = useCoworkerId()

  const { coworker, isLoading, team, flag, completedMissions } = useLoadProfileData(coworkerId)

  if (isLoading) return <LoadingSkeleton height={'25vh'} />

  return (
    <Widget
      border
      style={{
        minHeight: '25vh',
        height: '100%',
      }}
    >
      {isLoading && <Skeleton style={{ minHeight: '25vh', minWidth: '100%' }} />}

      {coworker && team && (
        <>
          <Flex
            gap={spacing75}
            justify="flex-start"
            align="center"
            direction={{ base: 'column', xs: 'row' }} // Stack on small screens, row on larger
            wrap="wrap"
            miw={'100%'}
          >
            <Group mt={spacing100}>
              <ProfilePhoto coworker={coworker} />
            </Group>

            <Stack gap={spacing25} style={{ flexGrow: 1 }}>
              <TitleBlue>{coworker.fullName}</TitleBlue>
              <Stack
                gap={2}
                bg={colourGreyNeutral2}
                p={spacing50}
                my={spacing25}
                style={{ borderRadius: radius25 }}
              >
                <Group gap={spacing50}>
                  <SSRIcon paths={person} />
                  <Paragraph>{coworker.roleTitle}</Paragraph>
                </Group>
                <Group gap={spacing50}>
                  <SSRIcon paths={accessibility} />
                  <Paragraph>{`${team.name} ${flag}`}</Paragraph>
                </Group>
                <Group gap={spacing50}>
                  <SSRIcon paths={checkmark} style={{ margin: `${2}px 0 ${-4}px` }} />
                  <Paragraph>
                    <b>{completedMissions.length ?? t('common.errors.n/a')}</b>{' '}
                    {t('features.coaching-session.overview.completed-missions')}
                  </Paragraph>
                </Group>
              </Stack>
              <Level legacyId={coworkerId} />
            </Stack>
          </Flex>
        </>
      )}
    </Widget>
  )
}
