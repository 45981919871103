import SSRIcon from '@ingka/ssr-icon'
import Text from '@ingka/text'
import { space50, space75 } from '@ingka/variables'
import { Accordion, Group, Stack } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { Mission } from 'lib/types/mission.types'

import { missionIconSquare, missionsWidgetStyle } from 'styles/missions'

import { Widget } from 'components/composites/Shared/Widget'
import { Label, SubHeading } from 'components/primitives/Text'

import { MissionRecap } from '../MissionRecap'
import { useSplitMissions } from './MissionDiffs.hooks'

type MissionDiffsProps = {
  coworkerId: string
  missions: Mission[]
}

const MissionDiffs: React.FC<MissionDiffsProps> = ({ coworkerId, missions }) => {
  const { t } = useTranslation()
  const displayObject = useSplitMissions(missions)
  return (
    <Widget style={missionsWidgetStyle}>
      <Stack gap={space50} px={space75}>
        <SubHeading>
          {t('features.coaching-session.completed-coaching-session.mission-changes.title')}
        </SubHeading>
        <Label>
          {t('features.coaching-session.completed-coaching-session.mission-changes.subtitle')}
        </Label>
        {displayObject.map((missionDefinition, index) => (
          <Accordion key={index}>
            <Accordion.Item value={missionDefinition.headerText}>
              <Accordion.Control disabled={missionDefinition.missions.length === 0}>
                <Group wrap="nowrap">
                  <SSRIcon style={missionIconSquare} paths={missionDefinition.iconPath}></SSRIcon>
                  <div style={{ marginLeft: '10px' }}>
                    <Text>{missionDefinition.headerText}</Text>
                    <Text bodySize="s">{missionDefinition.note}</Text>
                  </div>
                </Group>
              </Accordion.Control>
              {missionDefinition.missions.map((mission) => (
                <Accordion.Panel key={mission.id}>
                  <MissionRecap
                    mission={mission}
                    coworkerId={coworkerId}
                    visualiseChanges={missionDefinition.visualiseChanges}
                  />
                </Accordion.Panel>
              ))}
            </Accordion.Item>
          </Accordion>
        ))}
      </Stack>
    </Widget>
  )
}

export default MissionDiffs
