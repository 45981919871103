import SSRIcon from '@ingka/ssr-icon'
import SkapaTooltip from '@ingka/tooltip'
import { radiusS, space50, space100 } from '@ingka/variables'

import { backgroundColourWhite, colourGreyNeutral3 } from 'styles/tokens/insikt.tokens'

import styled from 'styled-components'

export const Widget = styled.div<{ $border?: boolean }>`
  background-color: ${backgroundColourWhite};

  padding: ${space100};
  border-radius: ${radiusS};
  ${({ $border }) => ($border ? `border: 1px solid ${colourGreyNeutral3}` : '')}
`

export const HeaderIcon = styled(SSRIcon)`
  margin-right: ${space50};
  height: 2rem;
  width: 2rem;
`

export const Tooltip = styled(SkapaTooltip)`
  margin-left: auto;
  white-space: pre-wrap;
`
