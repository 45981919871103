import dayjs from 'dayjs'
import { forEach, toNumber } from 'lodash'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { OperationalMetricId } from 'config/domain/metrics.config'
import { QaMetricId } from 'config/domain/qa.config'

import { Coworker } from 'lib/types/coworker.types'
import { Mission, MissionStatus } from 'lib/types/mission.types'
import { Team } from 'lib/types/team.types'
import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'
import { CustomElementClickEventKeys } from 'lib/utils/analytics/analytics.types'
import { DateHelper } from 'lib/utils/date.helper'

import { useCurrentCoachingSession } from 'hooks/coachingSessions'
import { useUser } from 'hooks/users'

import { useCreateMission, useUpdateChangesMission } from 'state/slices/api'

import { MissionDraftFormFields } from 'components/features/Missions/MissionDraftEditor/MissionDraftEditor.types'

type UseCreateMissionProps = {
  coworkerId: string
  selectedCheckboxId: QaMetricId
}

export const useCreateMissionDraft = ({
  coworkerId,
  selectedCheckboxId,
}: UseCreateMissionProps): any => {
  const { data: user } = useUser()
  const [createMission, { isLoading: isLoadingCreateMission, isSuccess: isSuccessCreateMission }] =
    useCreateMission()

  const { data: currentCoachingSession } = useCurrentCoachingSession(coworkerId!)

  const createForm = useForm<MissionDraftFormFields>({
    defaultValues: {
      title: undefined,
      coachingPointers: undefined,
      endDate: dayjs(currentCoachingSession?.plannedDate)
        .add(1, 'month')
        .toDate(), // One month from CS plannedDate
      target: 75,

      metricId: undefined,

      qaId: selectedCheckboxId as QaMetricId,
      createdDate: new Date(),
      createdBy: user?.legacyId,
    },
    mode: 'all',
  })

  const { getValues, handleSubmit, formState, trigger } = createForm

  let formErrors: boolean[] = []

  forEach(formState.errors, (err) => {
    if (err?.type !== 'validate') {
      formErrors.push(true)
    } else {
      formErrors.push(false)
    }
  })

  const onSubmit = async () => {
    // If there's no user, no coworker or ANY form fields have returned an error, don't submit
    if (!user || !coworkerId || formErrors.some((err) => err === true)) {
      trigger()
      return
    }
    try {
      const { title, coachingPointers, metricId, qaId, target, endDate, changes } = getValues()

      const missionInput = {
        title,
        coachingPointers,
        target: toNumber(target),
        endDate: DateHelper.dateToString(new Date(endDate)),
        qaId: qaId as QaMetricId,
        metricId:
          metricId && metricId !== 'Choose an option' ? (metricId as OperationalMetricId) : null,
        startedDate: null,
        createdDate: DateHelper.dateToString(new Date()),
        createdBy: user.legacyId,
        status: MissionStatus.Draft,
        changes: {
          ...changes,
        },
      }
      const newMissionId = await createMission({
        coworkerId,
        mission: missionInput,
      }).unwrap()

      analyticsHelper.createCustomElementClickEvent(
        {
          key: CustomElementClickEventKeys.NewMission,
          description: 'Custom event for tracking when a teamlead creates a new mission.',
        },
        { ...missionInput, missionId: newMissionId, coworkerId: coworkerId }
      )
      console.log('Mission created, navigating away')
      createForm.reset()
    } catch (error) {
      const { title, coachingPointers, metricId, target, endDate } = getValues()
      trigger()
      createForm.reset(
        {
          title: title,
          endDate: endDate,
          target: target,
          coachingPointers: coachingPointers,
          metricId: metricId,
        },
        { keepValues: true }
      )
      console.error('Error creating mission:', error)
    }
  }

  useEffect(() => {
    createForm.setValue('qaId', selectedCheckboxId)
  }, [selectedCheckboxId, createForm])

  useEffect(() => {
    if (!isLoadingCreateMission && isSuccessCreateMission) {
      createForm.reset()
    }
  }, [isSuccessCreateMission, createForm.reset, isLoadingCreateMission, createForm])

  return {
    createForm,
    onSubmit,
    handleSubmit,
    selectedCheckboxId,
    isLoadingCreateMission,
    isSuccessCreateMission,
  }
}

type UseUpdateMissionProps = {
  coworkerId: string
  mission: Mission
  coworker?: Coworker
  team?: Team
}

export const useUpdateMissionDraft = ({ coworkerId, mission }: UseUpdateMissionProps) => {
  const [updateChanges, { isSuccess: isSuccessUpdateMission, isLoading: isLoadingUpdateMission }] =
    useUpdateChangesMission()

  const { data: user } = useUser()

  // Edit mission form with preloaded default values and submit
  const form = useForm<MissionDraftFormFields>({
    defaultValues: {
      title: mission?.changes.title,
      coachingPointers: mission?.changes.coachingPointers,
      target: mission?.changes.target,
      startedDate: mission?.startedDate ? dayjs(mission?.startedDate).toDate() : dayjs().toDate(),
      endDate: mission?.changes.endDate
        ? dayjs(mission?.changes.endDate).toDate()
        : dayjs().add(1, 'month').toDate(),
      qaId: mission?.qaId as QaMetricId,
      metricId: mission?.metricId as OperationalMetricId,
    },
    mode: 'all',
  })

  const { reset, getValues, handleSubmit, trigger, formState } = form

  let formErrors: boolean[] = []

  forEach(formState.errors, (err) => {
    if (err?.type !== 'validate') {
      formErrors.push(true)
    } else {
      formErrors.push(false)
    }
  })

  //Todo: metricId if not selected while creating the draft, it continues to be undefined on editing??
  const onSubmit = async () => {
    if (!user || !coworkerId || !mission || formErrors.some((err) => err === true)) {
      trigger()
      return
    }
    const { title, coachingPointers, target, endDate, metricId } = getValues()

    const updateInput = {
      ...mission,
      title,
      coachingPointers,
      target: Number(target),
      endDate: DateHelper.dateToString(new Date(endDate)),
      updatedDate: DateHelper.dateToString(new Date()),
      metricId:
        metricId && metricId !== 'Choose an option' ? (metricId as OperationalMetricId) : null,
    }

    try {
      await updateChanges({
        coworkerId,
        missionId: mission.id,
        changesInput: updateInput,
        metricId: updateInput.metricId,
      }).unwrap()
      analyticsHelper.createCustomElementClickEvent(
        {
          key: CustomElementClickEventKeys.EditMission,
          description: 'Custom event for tracking when a teamlead edits a mission.',
        },
        {
          coworkerId,
          missionId: mission.id,
          ...updateInput,
          metricId: updateInput.metricId,
        }
      )
      reset()
      console.log('Mission updated, renavigating')
    } catch (error) {
      trigger()
      reset()
      console.error('Mission update error, ', error)
    }
  }

  return {
    form,
    onSubmit,
    handleSubmit,
    isSuccess: isSuccessUpdateMission,
    isLoading: isLoadingUpdateMission,
  }
}
