import SSRIcon from '@ingka/ssr-icon'
import calendar from '@ingka/ssr-icon/paths/calendar'
import Text from '@ingka/text'
import { space100 } from '@ingka/variables'
import { Flex, Group } from '@mantine/core'
import { skipToken } from '@reduxjs/toolkit/query'
import { useTranslation } from 'react-i18next'

import { CoachingSessionHelper } from 'lib/utils/coachingSession.helper'

import { useFormatDate } from 'hooks/formatting'
import { useCurrentPageType } from 'hooks/navigation/useSectionPaths.hooks'

import { useCoachingSessions } from 'state/slices/api'

import { colourNeutralWhite } from 'styles/tokens/oldSkapa.tokens'

import { SubHeading } from 'components/primitives/Text'

type NextScheduledCoachingProps = {
  coworkerId: string
  isMVP?: boolean
}

const NextScheduledCoaching: React.FC<NextScheduledCoachingProps> = ({
  coworkerId,
  isMVP = false,
}) => {
  const { t } = useTranslation()
  const pageType = useCurrentPageType()
  const formatDate = useFormatDate()

  const { data: coachingSessions = [] } = useCoachingSessions(coworkerId ?? skipToken)

  const upcomingSessionData = CoachingSessionHelper.getUpcomingCoachingSessionData(coachingSessions)

  if (!upcomingSessionData) {
    return null
  }

  // Todo: Since the Figma design for coworker-mvp next session isn't fully ready/decided,
  // I can't decide on making any major changes to this component as it's used in multiple places.
  // For now see UpcomingScheduledSession.tsx for the latest design.

  const nextCoachingSessionRedesign = () => {
    return (
      <>
        <Flex justify="flex-start" align="start" gap={space100}>
          <Group bg={colourNeutralWhite} p={space100} style={{ borderRadius: '4px' }}>
            <SubHeading>{getDateText()}</SubHeading>
            <SSRIcon paths={calendar} color="black" />
          </Group>
        </Flex>
      </>
    )
  }

  const getDateText = () => {
    if (
      upcomingSessionData.isPlanned &&
      !upcomingSessionData.isOverdue &&
      upcomingSessionData.diffInDays === 0
    ) {
      // Today
      return t('features.coaching-session.session-is-due-today')
    } else if (upcomingSessionData.isPlanned && !upcomingSessionData.isOverdue) {
      // Planned
      if (pageType === 'coworker') {
        return t('features.coaching-session.session-in-x-days-coworker', {
          daysUntil: Math.abs(upcomingSessionData.diffInDays),
          formattedDate: formatDate(new Date(upcomingSessionData.formattedDate)),
        })
      }
      return t('features.coaching-session.session-in-x-days', {
        daysUntil: Math.abs(upcomingSessionData.diffInDays),
        formattedDate: formatDate(new Date(upcomingSessionData.formattedDate)),
      })
    } else if (upcomingSessionData.isPlanned && upcomingSessionData.isOverdue) {
      // Overdue
      return (
        <Text style={{ fontWeight: 'bolder' }}>
          {t('features.coaching-session.session-is-overdue', {
            daysUntil: Math.abs(upcomingSessionData.diffInDays),
            formattedDate: formatDate(new Date(upcomingSessionData.formattedDate)),
          })}
        </Text>
      )
    }
  }

  return (
    <>
      {isMVP ? (
        nextCoachingSessionRedesign()
      ) : (
        <Flex justify="left" align="center">
          <Text>{getDateText()}</Text>
        </Flex>
      )}
    </>
  )
}

export default NextScheduledCoaching
