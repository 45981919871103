/** A collection of useful functions for working with dates. */
export class DateHelper {
  /**Todo: BigQueryDate date helper function */

  /** Returns a new Date with the provided number of days subtracted from it*/
  public static subtractDays = (date: Date | string, offset: number) => {
    return new Date(new Date(date).getTime() - offset * 24 * 60 * 60 * 1000)
  }

  /** Returns the number of days between two dates */
  public static getDaysBetweenDates = (startDate: Date, endDate: Date) => {
    const timeBetweenDates = endDate.getTime() - startDate.getTime()

    return Math.floor(timeBetweenDates / (1000 * 60 * 60 * 24))
  }

  /** Returns a string representation of a date in the format YYYY-MM-DD */ // Should maybe be changed to format(date, 'yyyy-MM-dd')
  public static dateToString = (date: Date) => date.toISOString().split('T')[0]

  /** Returns a string representation of a date in the format YYYY-MM */
  public static dateToMonthString = (date: Date) => this.dateToString(date).slice(0, 7)

  private static getToday = () => {
    const date = new Date()
    date.setHours(0, 0, 0, 0)
    return date
  }

  /** Returns a Date representing the end of the day */
  public static getStartOfDate = (date: Date) => {
    const endOfDate = new Date(date)
    endOfDate.setHours(0, 0, 0, 0)
    return endOfDate
  }

  /** Returns a Date representing the end of the day */
  public static getEndOfDate = (date: Date) => {
    const endOfDate = new Date(date)
    endOfDate.setHours(23, 59, 59, 999)
    return endOfDate
  }

  /** A Date representing today */
  public static today = this.getToday()

  /** A Date representing yesterday */
  public static yesterday = DateHelper.subtractDays(DateHelper.today, 1)

  /** A date representing the earliest date that we allow showing data for */
  public static dataCutoffDate = DateHelper.subtractDays(DateHelper.today, 365)

  /** Returns the earliest date in an array of dates */
  public static min = (dates: Date[]) => {
    return dates.reduce((min, date) => (date < min ? date : min))
  }

  /** Returns the latest date in an array of dates */
  public static max = (dates: Date[]) => {
    return dates.reduce((max, date) => (date > max ? date : max))
  }

  /** Checks if a string is a date string */
  public static isDateString = (dateString: string) => !isNaN(Date.parse(dateString))
}
