import { space100 } from '@ingka/variables'
import { Progress } from '@mantine/core'

import { useEvaluateMission } from './MissionProgress.hooks'
import { MissionProgressProps } from './MissionProgress.types'

export const MissionProgress = (props: MissionProgressProps) => {
  const { color, label, value } = useEvaluateMission(props)

  return (
    <Progress.Root
      radius={space100}
      w={'100%'}
      style={{ flex: 'revert', alignSelf: 'center' }}
      size="22px"
    >
      <Progress.Section style={{ borderRadius: space100 }} value={value} color={color}>
        <Progress.Label style={{ fontSize: '12px' }}>{label}</Progress.Label>
      </Progress.Section>
    </Progress.Root>
  )
}
