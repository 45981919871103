import Button from '@ingka/button'
import FormField from '@ingka/form-field'
import InputField from '@ingka/input-field'
import Modal, { ModalBody, Sheets } from '@ingka/modal'
import Select, { Option } from '@ingka/select'
import Switch from '@ingka/switch'
import TextArea from '@ingka/text-area'
import Toast from '@ingka/toast'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { BadgeDefinition, BadgeType } from 'lib/types/badge.types'

import { useEditBadgeMutation } from 'state/slices/api'

import { BadgeWithTooltip } from 'components/composites/Badges'
import { Stack } from 'components/layout/Stack'
import { Heading } from 'components/primitives/Text'

import { iconTypeRegex } from '../ManageBadgesPage.utils'

type BadgeEditProps = {
  badge: BadgeDefinition
}

const BadgeEdit = ({ badge }: BadgeEditProps) => {
  const [editBadge, { isError, isSuccess }] = useEditBadgeMutation()
  const [showModal, setShowModal] = useState(false)
  const [showConfirmationToast, setShowConfirmationToast] = useState(false)
  const [showRejectionToast, setShowRejectionToast] = useState(false)
  const { control, getValues, handleSubmit, formState, watch } = useForm({
    defaultValues: {
      badgeDefinitionId: badge.badgeDefinitionId,
      title: badge.title,
      description: badge.description,
      flair: badge.flair,
      category: badge.category,
      iconType: badge.iconType,
      type: badge.type,
      secret: badge.secret ?? false,
      groupId: badge.group?.groupId ?? '',
      groupOrder: badge.group?.order.toString() ?? '',
    },
  })

  const onSubmit = useCallback(() => {
    const { groupId, groupOrder, ...values } = getValues()

    const order = Number.parseInt(groupOrder)
    const group =
      groupId && order != null && !Number.isNaN(order)
        ? {
            groupId,
            order,
          }
        : undefined

    const badge = {
      ...values,
    }

    if (group) {
      Object.assign(badge, { group })
    }

    editBadge(badge)
    setShowModal(false)
  }, [editBadge, getValues])

  useEffect(() => {
    if (isSuccess) {
      setShowConfirmationToast(true)
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      setShowRejectionToast(true)
    }
  }, [isError])

  const editedBadge = watch()
  const { groupId, groupOrder, type } = editedBadge
  const validateBadgeGroup = useCallback(() => {
    if (!(groupId || groupOrder)) {
      return true
    }

    if (groupId && groupOrder) {
      return true
    }

    return 'Please fill out both group fields or leave both empty.'
  }, [groupId, groupOrder])

  return (
    <div>
      <Stack>
        <BadgeWithTooltip badge={badge} showInColor shouldWeShowLock={false} />
        <Button type="secondary" onClick={() => setShowModal(true)} text="Edit" />
      </Stack>
      <Toast
        isOpen={showConfirmationToast}
        text="The badge was created successfully."
        onCloseRequest={() => setShowConfirmationToast(false)}
      />
      <Toast
        isOpen={showRejectionToast}
        text="An error occurred while creating the badge."
        onCloseRequest={() => setShowRejectionToast(false)}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal visible={showModal} handleCloseBtn={() => setShowModal(false)}>
          <Sheets alignment="left" header={null} footer={null}>
            <ModalBody>
              <Heading>{'Preview'}</Heading>
              <BadgeWithTooltip badge={editedBadge} showInColor shouldWeShowLock={false} />
              <FormField>
                <InputField
                  disabled
                  label="Badge ID"
                  id="badgeId"
                  data-testid="badgeId"
                  type="text"
                  value={badge.badgeDefinitionId}
                />
              </FormField>
              <Controller
                name="title"
                control={control}
                rules={{
                  required: 'Please provide a title.',
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    shouldValidate={formState.submitCount !== 0}
                    valid={formState.errors.title == null}
                    validation={{
                      msg: formState.errors.title?.message,
                    }}
                  >
                    <InputField
                      label="Title"
                      id="title"
                      data-testid="title"
                      type="text"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  </FormField>
                )}
              />
              <Controller
                name="description"
                control={control}
                rules={{
                  required: 'Please provide a description.',
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    shouldValidate={formState.submitCount !== 0}
                    valid={formState.errors.description == null}
                    validation={{
                      msg: formState.errors.description?.message,
                    }}
                  >
                    <TextArea
                      label="Description"
                      id="description"
                      data-testid="description"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  </FormField>
                )}
              />
              <Controller
                name="category"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    shouldValidate={formState.submitCount !== 0}
                    valid={formState.errors.category == null}
                    validation={{
                      msg: formState.errors.category?.message,
                    }}
                  >
                    <InputField
                      label="Category"
                      id="category"
                      data-testid="category"
                      type="text"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  </FormField>
                )}
              />
              <Controller
                name="flair"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    shouldValidate={formState.submitCount !== 0}
                    valid={formState.errors.flair == null}
                    validation={{
                      msg: formState.errors.flair?.message,
                    }}
                  >
                    <TextArea
                      label="Flair"
                      id="flair"
                      data-testid="flair"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  </FormField>
                )}
              />
              <Controller
                name="iconType"
                control={control}
                rules={{
                  required: 'Please select an icon type.',
                  pattern: {
                    value: iconTypeRegex,
                    message:
                      "The icon type must be either 'bronze', 'silver', 'gold' or 'awarded'.",
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    shouldValidate={formState.submitCount !== 0}
                    valid={formState.errors.iconType == null}
                    validation={{
                      msg: formState.errors.iconType?.message,
                    }}
                  >
                    <InputField
                      label="Icon type"
                      id="iconType"
                      data-testid="iconType"
                      type="text"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  </FormField>
                )}
              />
              <Controller
                name="type"
                control={control}
                rules={{
                  required: "The icon type must be either 'bronze', 'silver', 'gold' or 'awarded'.",
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    shouldValidate={formState.submitCount !== 0}
                    valid={formState.errors.type == null}
                    validation={{
                      msg: formState.errors.type?.message,
                    }}
                  >
                    <Select
                      label="Badge type"
                      id="type"
                      data-testid="type"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    >
                      <Option value="achieved" name="Achieved" />
                      <Option value="awarded" name="Awarded" />
                    </Select>
                  </FormField>
                )}
              />
              <Controller
                name="secret"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    shouldValidate={formState.submitCount !== 0}
                    valid={formState.errors.secret == null}
                    validation={{
                      msg: formState.errors.secret?.message,
                    }}
                  >
                    <Switch
                      label="Secret"
                      id="secret"
                      data-testid="secret"
                      checked={value}
                      value="secret"
                      disabled={type === BadgeType.Awardable}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  </FormField>
                )}
              />
              <Controller
                name="groupId"
                control={control}
                rules={{ validate: validateBadgeGroup }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    shouldValidate={formState.submitCount !== 0}
                    valid={formState.errors.groupId == null}
                    validation={{
                      msg: formState.errors.groupId?.message,
                    }}
                  >
                    <InputField
                      type="text"
                      label="Group ID"
                      id="groupId"
                      data-testid="groupId"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  </FormField>
                )}
              />
              <Controller
                name="groupOrder"
                control={control}
                rules={{
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'The group order must consist of only digits.',
                  },
                  validate: validateBadgeGroup,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    shouldValidate={formState.submitCount !== 0}
                    valid={formState.errors.groupOrder == null}
                    validation={{
                      msg: formState.errors.groupOrder?.message,
                    }}
                  >
                    <InputField
                      type="text"
                      label="Group order"
                      id="groupOrder"
                      data-testid="groupOrder"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  </FormField>
                )}
              />
              <Button type="primary" htmlType="submit" text="Save changes" />
            </ModalBody>
          </Sheets>
        </Modal>
      </form>
    </div>
  )
}

export default BadgeEdit
