import FormField from '@ingka/form-field'
import Select, { Option } from '@ingka/select'
import { space100 } from '@ingka/variables'
import { Box } from '@mantine/core'
import { useMemo } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ConnectedMetricIdForMissions, connectedMetricsConfig } from 'config/domain/metrics.config'

import { Coworker } from 'lib/types/coworker.types'
import { Mission, MissionStatus } from 'lib/types/mission.types'

import { MissionDraftFormFields } from 'components/features/Missions/MissionDraftEditor/MissionDraftEditor.types'
import { Label, SubHeading } from 'components/primitives/Text'

type Props = {
  form: UseFormReturn<MissionDraftFormFields> & {
    watch: (field: keyof MissionDraftFormFields) => ConnectedMetricIdForMissions
  }
  coworker: Coworker
  isEditing?: boolean
  mission: Mission
}

const ConnectedMetricStep: React.FC<Props> = ({ form, coworker, isEditing, mission }) => {
  const { t } = useTranslation()

  const { control, watch } = form

  const useAllowedMetrics = () =>
    useMemo(
      () => Object.values(connectedMetricsConfig).filter((metric) => metric.allowedUses.missions),
      []
    )
  const allowedMetrics = useAllowedMetrics() ?? []

  return (
    <>
      <SubHeading>{t('features.mission.create-mission.steps.connected-metric.title')}</SubHeading>
      <Label>{t('features.mission.create-mission.steps.connected-metric.subtitle')}</Label>

      <Box my={space100}>
        <Controller
          name="metricId"
          control={control}
          render={({ field }) => (
            <FormField
              data-testid="form-field-connected-metric"
              fieldHelper={{
                msg: t('features.mission.create-mission.fields.connected-metric.hint'),
              }}
            >
              <Select
                label={t('features.mission.create-mission.fields.connected-metric.title')}
                id="metricId"
                data-testid="input-field-connected-metric"
                {...field}
                disabled={isEditing && mission.metricId && mission.status === MissionStatus.Active}
              >
                {watch('qaId') &&
                  allowedMetrics.map((metric) => (
                    <Option
                      key={metric.id}
                      label={t(`common.metrics.${metric.id}.name`)}
                      value={metric.id}
                    />
                  ))}
              </Select>
            </FormField>
          )}
        />
      </Box>
    </>
  )
}

export default ConnectedMetricStep
