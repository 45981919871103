import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import { UserRole } from 'lib/types/user.types'

import { useUser } from 'hooks/users'

import { MainLayout } from 'components/layout/MainLayout'
import { NotFoundPage } from 'components/pages/global/NotFoundPage'

import AdminRoutes from './AdminRoutes.component'
import CoworkerRoutes from './CoworkerRoutes.component'
import DemoRoutes from './DemoRoutes.component'
import GlobalRoutes from './GlobalRoutes.component'
import QsRoutes from './QsRoutes.component'
import TeamleadRoutes from './TeamleadRoutes.component'

const Routing = () => {
  const { pathname } = useLocation()
  const { data: user } = useUser()

  // Whenever the path changes, scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        {GlobalRoutes}
        {user?.role === UserRole.Coworker && <>{CoworkerRoutes}</>}
        {user?.role === UserRole.TeamLead && <>{TeamleadRoutes}</>}
        {user?.role === UserRole.Admin && <>{AdminRoutes}</>}
        {user?.role === UserRole.Qs && <>{QsRoutes}</>}
        {user?.role === UserRole.Demo && <>{DemoRoutes}</>}
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}
export default Routing
