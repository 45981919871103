import { SkipToken } from '@reduxjs/toolkit/dist/query'

import { CoachingSessionStatus } from 'lib/types/coachingSession.types'

import { useCoachingSessions } from 'state/slices/api/modules/coachingSession/coachingSession.slice'

/** Fetches the currently upcoming or active coaching session for a co-worker with the given ID */
export const useCurrentCoachingSession = (coworkerId: string | SkipToken) => {
  const { data: coachingSessions, isSuccess, ...meta } = useCoachingSessions(coworkerId)

  return {
    data: coachingSessions?.find(
      (coachingSession) => coachingSession.status === CoachingSessionStatus.Planned
    ),
    isSuccess,
    ...meta,
  }
}
