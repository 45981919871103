import { apiSlice } from 'state/slices/api/api.slice'

export type PowerBiResponse = {
  name: string
  url: string
  initialPage?: string
  displayName: string
}

// TODO: Do we need this type alias?

// TODO: unify? At some point response and input might differ, but not likely
type PowerBiInput = {
  name: string
  url: string
}

export const POWER_BI_STATE_TAG = 'PowerBi'

const reportSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchPowerBiURLs: builder.query<PowerBiResponse[], void>({
      query: () => '/power-bi/reports',
      providesTags: [POWER_BI_STATE_TAG],
    }),
    createPowerBiURL: builder.mutation<string, PowerBiInput>({
      query: (report) => ({
        url: `/power-bi/${report.name}`,
        method: 'POST',
        body: report.url,
      }),
      invalidatesTags: [POWER_BI_STATE_TAG],
    }),
    editPowerBiURL: builder.mutation<PowerBiResponse, PowerBiInput>({
      query: (report) => ({
        url: `/report/${report.name}`,
        method: 'PUT',
        body: report.url,
      }),
      invalidatesTags: [POWER_BI_STATE_TAG],
    }),
  }),
})

export const { useCreatePowerBiURLMutation, useFetchPowerBiURLsQuery, useEditPowerBiURLMutation } =
  reportSlice
