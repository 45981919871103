import './UsersOnboarding.css'
import Button from '@ingka/button'
import { LoadingBall } from '@ingka/loading'
import Table, { TableBody, TableHeader } from '@ingka/table'
import React, { useEffect } from 'react'

import { UserRole } from 'lib/types/user.types'

import { useFetchFixturesQuery, useUpdateFixturesMutation } from 'state/slices/api'

import { Widget, WidgetFooter } from 'components/composites/Shared/Widget'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { EditModal } from './EditModal/EditModal.component'

type UserFixture = {
  id: string
  legacyId: string
  role: UserRole
  edited?: boolean
  deleted?: boolean
}

export type IndexedUserFixture = UserFixture & {
  index: number
}

const UsersOnboarding = () => {
  const { data: userFixtures, isLoading, error } = useFetchFixturesQuery()

  const [updateUserFixtures] = useUpdateFixturesMutation()

  const [users, setUsers] = React.useState<UserFixture[]>([])
  const [changes, setChanges] = React.useState<boolean>(false)
  const [showCompletionModal, setShowModal] = React.useState(false)
  const [modalData, setModalData] = React.useState<IndexedUserFixture>()

  useEffect(() => {
    if (!userFixtures) return
    const mappedFixtures = userFixtures.map((fixture) => ({
      id: fixture.emailUsername,
      // TODO: Checks if this is failing?
      role: fixture.role as UserRole,
      legacyId: fixture.legacyId,
    }))
    setUsers(mappedFixtures)
  }, [userFixtures])

  const saveUser = (user: IndexedUserFixture) => {
    const newUsers = [...users]
    user.id = user.id.split('@')[0]
    newUsers[user.index] = {
      id: user.id,
      legacyId: user.legacyId,
      role: user.role,
      edited: true,
      deleted: false,
    }
    setUsers(newUsers)
    setChanges(true)
  }

  const handleUserDelete = (index: number) => {
    setUsers((prev) => {
      prev[index].deleted = !prev[index].deleted
      prev[index].edited = false
      return prev
    })
    setChanges(true)
    setShowModal(false)
  }

  const handleBackendUpdate = async () => {
    if (!changes) return

    const updatedUsers = users
      .filter((user) => user.deleted || user.edited)
      .map((user) => ({
        emailUsername: user.id,
        ...user,
      }))
    await updateUserFixtures(updatedUsers)

    setChanges(false)
    setUsers((prev) =>
      prev
        .filter((user) => !user.deleted)
        .map((user) => ({
          ...user,
          edited: false,
        }))
    )
  }

  if (error) return <UnexpectedErrorMessage />

  if (isLoading) return <LoadingBall />

  return (
    <Widget
      footer={
        <WidgetFooter justify="flex-end">
          <Button
            text="Save the changes"
            type="primary"
            // TODO: We should replaReactce this with a more accessible styling once we have discussed it
            disabled={!changes}
            onClick={handleBackendUpdate}
          />
        </WidgetFooter>
      }
    >
      <Table fullWidth inset>
        <TableHeader>
          <tr>
            <th>{'Email Username'}</th>
            <th>{'Legacy ID'}</th>
            <th>{'Desired Role'}</th>
          </tr>
        </TableHeader>
        <TableBody className="table">
          {users.map((user, index) => (
            <tr
              style={{
                backgroundColor: user.deleted ? 'lightcoral' : user.edited ? 'lightgreen' : 'white',
              }}
              key={user.legacyId}
              onClick={() => {
                setShowModal(true)
                setModalData({
                  ...user,
                  id: user.id + '@ingka.com',
                  index,
                })
              }}
            >
              <td>{user.id}</td>
              <td>{user.legacyId}</td>
              <td>{user.role}</td>
            </tr>
          ))}
          <tr
            key="add-new"
            onClick={() => {
              setModalData({
                id: '',
                legacyId: '',
                role: UserRole.Admin,
                index: users.length,
              })
              setShowModal(true)
            }}
          >
            <td>{'Click to add'}</td>
            <td></td>
            <td></td>
          </tr>
        </TableBody>
      </Table>
      {showCompletionModal && (
        <EditModal
          setShowCompletionModal={setShowModal}
          user={modalData}
          saveUser={saveUser}
          handleUserDelete={handleUserDelete}
        />
      )}
    </Widget>
  )
}

export default UsersOnboarding
