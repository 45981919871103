import { Outlet, Route } from 'react-router-dom'

import { TeamleadLayout } from 'components/layout'
import { CoachingSessionOverviewPage } from 'components/pages/shared'
import { EditCoachingSessionPage, ManageCoworkerPage } from 'components/pages/teamlead'
import PrepareCoachingSessionPage from 'components/pages/teamlead/PrepareCoachingSessionsPage/PrepareCoachingSessionPage.component'
import { TeamleadLandingPage } from 'components/pages/teamlead/TeamleadLandingPage/TeamleadLandingPage.component'

/**
 * Be aware that this is *not* a ReactNode but rather a hook return a list of JSX.element
 * The motivation for this is that react-router only allows <Route/> as child elements.
 */
const TeamleadRoutes = (
  <Route path="tl/:teamleadId" element={<TeamleadLayout />}>
    <Route path="coworkers" element={<Outlet />}>
      <Route path="" element={<TeamleadLandingPage />} />
      <Route path=":coworkerId" element={<Outlet />}>
        <Route path="" element={<ManageCoworkerPage />} />
        <Route path="coaching-sessions" element={<Outlet />}>
          <Route path=":coachingSessionId" element={<CoachingSessionOverviewPage />} />
          <Route path=":coachingSessionId/flow" element={<EditCoachingSessionPage />} />
          <Route path=":coachingSessionId/prepare" element={<PrepareCoachingSessionPage />} />
        </Route>
      </Route>
    </Route>
  </Route>
)

export default TeamleadRoutes
