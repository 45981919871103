import { breakpointM } from '@ingka/variables'
import { useMediaQuery } from '@mantine/hooks'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { backgroundColourDefault } from 'styles/tokens/insikt.tokens'

export const useShowSidebar = () => {
  const [showSidebar, setShowSidebar] = useState(true)
  const isAboveM = useMediaQuery(`(min-width: ${breakpointM})`)

  useEffect(() => {
    if (isAboveM) {
      setShowSidebar(true)
    } else {
      setShowSidebar(false)
    }
  }, [isAboveM])

  return [showSidebar, setShowSidebar] as const
}

export const useBackgroundColor = () => {
  const { teamleadId, coworkerId } = useParams()
  if (coworkerId && !teamleadId) {
    return 'white'
  }
  return backgroundColourDefault
}
