import Text from '@ingka/text'
import { Box } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { QaMetricId } from 'config/domain/qa.config'

import { colourGreyNeutral2, fontSizeSmall, radius25, spacing25, spacing50, spacing100 } from 'styles/tokens/insikt.tokens'

import { useAssessmentCriteria } from './AssessmentCriteria.hooks'

interface AssessmentProps {
  qaId: QaMetricId
}

export const AssessmentCriteria = ({ qaId }: AssessmentProps) => {
  const { assessmentCriteria } = useAssessmentCriteria(qaId)
  const { t } = useTranslation()
  if (assessmentCriteria.length === 0) return null

  return (
    <Box p={spacing50} bg={colourGreyNeutral2} mt={spacing50} style={{ borderRadius: radius25 }}>
      <Text style={{ fontWeight: 700 }}>
        {t('features.coaching-session.coworker.missions.assessment-criteria')}
      </Text>
      <ul style={{ padding: `${spacing25} ${spacing100} 0` }}>
        {assessmentCriteria.map((criteria: string, index: number) => (
          <li key={index}>
            <Text style={{ fontSize: fontSizeSmall }}>{criteria}</Text>
          </li>
        ))}
      </ul>
    </Box>
  )
}
