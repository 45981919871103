import { useCallback } from 'react'

import { MetricId, metricsConfig } from 'config/domain/metrics.config'
import { isQaMetricId } from 'config/domain/qa.config'

import { FormattingHelper } from 'lib/utils/formatting.helper'

import { useMetricSymbol } from 'hooks/metrics/useMetricSymbol.hooks'

import { useLocale } from 'state/slices/api/api.hooks'

export const useFormatMetric = (metricId: MetricId, id: string, idType: 'coworker' | 'team') => {
  const { isoCode } = useLocale()
  const metricSymbol = useMetricSymbol(metricId, id, idType)

  return useCallback(
    (value: number) => {
      if (isQaMetricId(metricId)) {
        return value.toFixed(0)
      }

      const { metricBehaviour } = metricsConfig[metricId]
      const { decimalPlaces, unit } = metricBehaviour

      if (unit === 'percentage') {
        value = value * 100
      }

      const localisedValue = FormattingHelper.formatNumber(value, decimalPlaces, isoCode)

      if (metricSymbol === undefined) {
        return localisedValue
      } else {
        if (unit === 'percentage') {
          return `${localisedValue}${metricSymbol}`
        } else {
          return `${localisedValue} ${metricSymbol}`
        }
      }
    },
    [metricId, isoCode, metricSymbol]
  )
}
