import { models } from 'powerbi-client'

export const COWORKER_SLICER_TITLE = 'slicer:coworker'
export const TEAM_SLICER_TITLE = 'slicer:team'

export const NAV_BUTTON_HEADER = 'NAV:'
export const PAGE_SWAP_HEADER = 'PAGE:'

export const POWER_BI_SYNC_TIMEOUT = 2500

export const BASE_SLICER_CONFIG = {
  $schema: 'http://powerbi.com/product/schema#basic',
  filterType: models.FilterType.Basic,
  requireSingleSelection: false,
}

export enum LoadingSteps {
  Auth = 0,
  Data = 1,
  Loading = 2,
  Render = 3,
  Final = 4,
}

export const LOADING_STEP_COUNT = LoadingSteps.Final
