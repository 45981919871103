import { MissionHelper } from 'lib/utils/mission.helper'

import { useMissions } from 'state/slices/api'

export const useMissionForQaId = (qaId: string, coworkerId: string) => {
  const { data: missions = [] } = useMissions(coworkerId)

  const activeMissions = MissionHelper.getActiveMissions(missions)
  const mission = activeMissions.find((mission) => mission.qaId === qaId)

  // Return previous score, current score, and mission
  return mission
}
