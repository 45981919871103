import { durationStandardWeb, easeEnter } from '@ingka/variables'

import { colourNeutralGrey100 } from 'styles/tokens/oldSkapa.tokens'

import styled from 'styled-components'

import { StyledTableRowProps } from './CoworkersTable.types'

export const TableCell = styled.td`
  vertical-align: middle;
  text-align: left;
`

export const TableRow = styled.tr<StyledTableRowProps>`
  transition: background-color ${easeEnter} ${durationStandardWeb};

  opacity: ${(props) => (props.active ? 1 : 0.5)};

  &:hover {
    cursor: ${(props) => (props.active ? 'pointer' : 'default')};
    background-color: ${(props) => (props.active ? colourNeutralGrey100 : null)};
  }
`

export const TableRowHeader = styled.tr`
  th {
    font-weight: normal;
  }
`
