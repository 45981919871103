import { space100 } from '@ingka/variables'
import { Box, Divider } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { MissionStatus } from 'lib/types/mission.types'

import { useLastCompletedCoachingSession } from 'hooks/coachingSessions'
import { useUpcomingCoachingSession } from 'hooks/coachingSessions/useUpcomingCoachingSession.hooks'
import { useCoworkerId } from 'hooks/id/useCoworkerId.hooks'

import { useMissions } from 'state/slices/api'

import { colourGreyNeutral3, spacing50 } from 'styles/tokens/insikt.tokens'

import { SessionComment } from 'components/features/CoachingSessions/CoachingSessionOverview/SessionComment'
import { CoworkerCreateNote } from 'components/features/CoworkerMVP/CoworkerCreateNote'
import { TitleBlue } from 'components/primitives/Text'
import UnexpectedErrorMessage from 'components/shared/UnexpectedErrorMessage/UnexpectedErrorMessage.component'

import { MissionsOverview } from './MissionsOverview/MissionsOverview.component'
import { UpcomingCoachingSession } from './UpcomingCoachingSession/UpcomingCoachingSession.component'

export const CoworkerCoachingSession = () => {
  const { t } = useTranslation()
  const coworkerId = useCoworkerId()
  const { data: missions = [], isLoading, isError } = useMissions(coworkerId)

  const { data: lastCompletedCoachingSession } = useLastCompletedCoachingSession(coworkerId)
  const { data: plannedCoachingSession } = useUpcomingCoachingSession(coworkerId)

  const filteredMissions = missions.filter((mission) => mission.status === MissionStatus.Active)

  if (isError) return <UnexpectedErrorMessage />

  return (
    <>
      <UpcomingCoachingSession />
      <Box my={space100}>
        {plannedCoachingSession && (
          <CoworkerCreateNote
            coworkerId={coworkerId ?? ''}
            coachingSession={plannedCoachingSession}
          />
        )}
      </Box>
      <Divider size={'xs'} color={colourGreyNeutral3} />

      <TitleBlue style={{ paddingTop: spacing50 }}>
        {t('features.coaching-session.coworker.missions.title')}
      </TitleBlue>
      <MissionsOverview missions={filteredMissions} isLoading={isLoading} />

      {lastCompletedCoachingSession?.sessionComment && (
        <SessionComment comment={lastCompletedCoachingSession.sessionComment} />
      )}
    </>
  )
}
