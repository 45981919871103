import InlineMessage from '@ingka/inline-message'
import { isEqual } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Coworker } from 'lib/types/coworker.types'
import { Mission } from 'lib/types/mission.types'

import { useCurrentPageType } from 'hooks/navigation/useSectionPaths.hooks'

import MissionOverviewCoachingPointers from '../components/MissionOverviewCoachingPointers.component'
import MissionOverviewConnectedMetric from '../components/MissionOverviewConnectedMetric.component'
import MissionOverviewDraftSwitch from '../components/MissionOverviewDraftSwitch.component'
import MissionOverviewQaProgression from '../components/MissionOverviewQaProgression.component'
import MissionOverviewTitle from '../components/MissionOverviewTitle.component'

import OverviewBase from './OverviewBase.component'

interface Props {
  mission: Mission
  coworker: Coworker
}

const ActiveOverview: React.FC<Props> = ({ mission, coworker }) => {
  const { t } = useTranslation()
  const activeValues = {
    title: mission.title,
    coachingPointers: mission.coachingPointers,
    target: mission.target,
    endDate: mission.endDate,
  }
  const pageType = useCurrentPageType()

  const isChanged = !isEqual(mission.changes, activeValues) && pageType === 'teamlead'

  const [showChanges, setShowChanges] = useState(
    isChanged && !mission.suggestedToComplete ? true : false
  )
  const selectedValues = showChanges ? mission.changes : activeValues

  return (
    <OverviewBase>
      {isChanged && !mission.suggestedToComplete && (
        <MissionOverviewDraftSwitch
          isChanged={isChanged}
          showChanges={showChanges}
          setShowChanges={setShowChanges}
        />
      )}

      <MissionOverviewTitle
        missionEnd={selectedValues.endDate}
        missionStarted={mission.startedDate}
        qaId={mission.qaId}
        title={selectedValues.title}
      />

      <MissionOverviewCoachingPointers coachingPointers={selectedValues.coachingPointers} />

      <MissionOverviewQaProgression
        mission={mission}
        showChanges={showChanges}
        coworkerId={coworker.legacyId}
      />

      {mission.metricId ? (
        <MissionOverviewConnectedMetric
          metricId={mission.metricId}
          coworker={coworker}
          mission={mission}
          missionEnd={selectedValues.endDate}
        />
      ) : (
        <InlineMessage variant="informative" title={t('features.mission.preview.no-metric')} />
      )}
    </OverviewBase>
  )
}

export default ActiveOverview
