// Define base event interface with common properties
export interface BaseGameEvent {
  type: GameEventTypes
  xp: number
  applied: boolean
  dateEarned: Date
  dateApplied?: Date
  id: string
  missionId?: string
}

// Enum for your event types
export enum GameEventTypes {
  Initialize = 'Initialize',
  CompleteMission = 'CompleteMission', // Add this new event type
}

// Define specific GameEvent for `Initialize` event
export interface GameEventType_Initialize extends BaseGameEvent {
  type: GameEventTypes.Initialize
}

// Define specific GameEvent for `CompleteMission` event
export interface GameEventType_CompleteMission extends BaseGameEvent {
  type: GameEventTypes.CompleteMission
  missionId: string
}

// Union type for any game event
export type GameEvent = GameEventType_Initialize | GameEventType_CompleteMission

export type Game = {
  createdDate: Date
}

export type GameEventDocument = {
  type: GameEventTypes
  xp: number
  applied: boolean
  dateEarned: Date
  dateApplied?: Date
}

export interface GameEventDTO extends GameEventDocument {
  // Add `id` property to DTO
  id: string
}
