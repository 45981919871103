import { Analytics, getAnalytics, logEvent } from 'firebase/analytics'

import {
  CustomElementClickEventInput,
  CustomEventTypes,
  CustomOtherEventKeys,
  CustomViewEventInput,
  RootEventInput,
} from './analytics.types'

class AnalyticsHelper {
  private analytics: Analytics

  constructor() {
    this.analytics = getAnalytics()
  }

  public createCustomRootEvent = (input: RootEventInput) => {
    logEvent(this.analytics, CustomEventTypes.CustomRootEvent, input)
  }

  public createCustomViewEvent = (input: CustomViewEventInput, optionals?: Object) => {
    const inputWithOptionals = {
      ...input,
      ...optionals,
    }
    logEvent(this.analytics, CustomEventTypes.CustomView, inputWithOptionals)
  }

  public createCustomElementClickEvent = (
    input: CustomElementClickEventInput,
    optionals?: Object
  ) => {
    const inputWithOptionals = {
      ...optionals,
    }
    logEvent(this.analytics, input.key, inputWithOptionals)
  }

  public createCustomOtherEvent = (key: CustomOtherEventKeys, optionals?: Object) => {
    logEvent(this.analytics, key, optionals)
  }
}

export const analyticsHelper = new AnalyticsHelper()
