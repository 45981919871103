import dayjs from 'dayjs'
import { useMemo } from 'react'

import { QaMetricId } from 'config/domain/qa.config'

import { QualityAssessment } from 'lib/types/qualityAssessment.types'

import { useRecentQAs } from 'state/slices/api/modules/qualityAssessment/qualityAssessment.slice'

export interface ScoreEntry {
  id: QaMetricId
  totalScore: number
  averageScore: number
  count: number
}

interface ReturnData {
  scores: ScoreEntry[]
  currentQas: QualityAssessment[]
  startDate: Date
  endDate: Date
  isLoadingQas: boolean
  isFirstSession?: boolean
}

const updateScoresInPlace = (
  id: QaMetricId,
  score: number,
  scoreMap: Map<QaMetricId, ScoreEntry>
) => {
  const entry = scoreMap.get(id) ?? {
    totalScore: 0,
    id,
    count: 0,
    averageScore: 0,
  }

  entry.totalScore += score
  entry.count++
  entry.averageScore = entry.totalScore / entry.count

  scoreMap.set(id, entry)
}

export const useCurrentQaDataForCoworker = (coworkerId: string): ReturnData | null => {
  const { data: QaData, isLoading: isLoadingQas } = useRecentQAs(coworkerId)

  const endDate = dayjs().toDate()

  let scores: ScoreEntry[] = useMemo(() => {
    const scoreMap: Map<QaMetricId, ScoreEntry> = new Map()
    const qualityAssessments = QaData?.QAs ?? []

    if (!qualityAssessments.length) {
      return []
    }

    qualityAssessments.forEach((qa) => {
      updateScoresInPlace(qa.id, qa.score, scoreMap)

      qa.groups.forEach((group) => {
        updateScoresInPlace(group.id, group.score, scoreMap)

        group.questions.forEach((question) => {
          updateScoresInPlace(question.id, question.score, scoreMap)
        })
      })
    })

    return Array.from(scoreMap.values())
  }, [QaData])

  // Return current scores and assessments
  return {
    scores,
    currentQas: QaData?.QAs ?? [],
    startDate: QaData?.startDate ? dayjs(QaData.startDate).toDate() : dayjs().toDate(),
    endDate,
    isLoadingQas,
  }
}
