import { useEffect, useState } from 'react'

import { getFeatureFlag, initializeFeatureFlags } from 'config/featureFlags/featureFlags.config'

export const useFeatureFlag = (featureKey: string) => {
  const [flag, setFlag] = useState(getFeatureFlag(featureKey))

  useEffect(() => {
    const getConfig = async () => {
      await initializeFeatureFlags()

      setFlag(getFeatureFlag(featureKey))
    }

    getConfig()
  }, [featureKey])

  return flag
}
