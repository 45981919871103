import { CustomerComment } from 'lib/types/customerComment.types'

import { apiSlice } from 'state/slices/api/api.slice'

export type ForwardOrDismissCommentArgs = {
  coworkerId: string
  interactionId: string
}

export const customerCommentTeamleadSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerComments: builder.query<CustomerComment[], string>({
      query: (coworkerId) => `/tl/coworkers/${coworkerId}/customerComments`,
      providesTags: (result, error, coworkerId) => [{ type: 'Customer Comment', id: coworkerId }],
    }),
    forwardCustomerComment: builder.mutation<void, ForwardOrDismissCommentArgs>({
      query: ({ coworkerId, interactionId }) => ({
        url: `/tl/coworkers/${coworkerId}/customerComments/${interactionId}/forward`,
        method: 'POST',
      }),
      // Do not refetch customer comments list after forwarding/dismissing a comment
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        try {
          const { coworkerId, interactionId } = data
          await queryFulfilled
          // Only after success, remove the comment from the cache
          dispatch(
            customerCommentTeamleadSlice.util.updateQueryData(
              'getCustomerComments',
              coworkerId,
              (draft) => {
                const index = draft.findIndex((comment) => comment.interactionId === interactionId)
                // Update the cache with the new status
                if (index !== -1) {
                  draft[index].status = 'forwarded'
                }
              }
            )
          )
        } catch (error) {
          console.error(`Error in teamlead forwarding comment: ${error}`)
        }
      },
      invalidatesTags: [],
    }),
    dismissCustomerComment: builder.mutation<void, ForwardOrDismissCommentArgs>({
      query: ({ coworkerId, interactionId }) => ({
        url: `/tl/coworkers/${coworkerId}/customerComments/${interactionId}/dismiss`,
        method: 'POST',
      }),
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            customerCommentTeamleadSlice.util.updateQueryData(
              'getCustomerComments',
              data.coworkerId,
              (draft) => {
                draft.splice(
                  draft.findIndex((comment) => comment.interactionId === data.interactionId),
                  1
                )
              }
            )
          )
        } catch (error) {
          console.error(`Error in teamlead dismissing comment: ${error}`)
        }
      },
      invalidatesTags: [],
    }),
    getForwardedComments: builder.query<CustomerComment[], string>({
      query: (coworkerId) => `/tl/coworkers/${coworkerId}/customerComments/forwarded`,
      providesTags: (result, error, coworkerId: string) => [
        { type: 'Customer Comment', id: coworkerId },
      ],
    }),
  }),
})

export const {
  useGetCustomerCommentsQuery: useGetTeamleadCustomerComments,
  useGetForwardedCommentsQuery: useGetTeamleadForwardedComments,
  useForwardCustomerCommentMutation: useForwardTeamleadCustomerComment,
  useDismissCustomerCommentMutation: useDismissTeamleadCustomerComment,
} = customerCommentTeamleadSlice
