import Button from '@ingka/button'
import FormField from '@ingka/form-field'
import InputField from '@ingka/input-field'
import Modal, { ModalFooter, ModalHeader, Sheets } from '@ingka/modal'
import Select from '@ingka/select'
import { Fragment, useState } from 'react'

import { UserRole } from 'lib/types/user.types'

import { IndexedUserFixture } from '../UsersOnboarding.component'

type ModalProps = {
  setShowCompletionModal: (show: boolean) => void
  user: IndexedUserFixture | undefined
  saveUser: (user: IndexedUserFixture) => void
  handleUserDelete: (index: number) => void
}

export const EditModal: React.FC<ModalProps> = ({
  setShowCompletionModal,
  user,
  saveUser,
  handleUserDelete,
}) => {
  const [currentUser, setCurrentUser] = useState<IndexedUserFixture>(user!)
  const [emailFieldWarning, setShowEmailWarning] = useState('')
  const [showLegacyIdWarning, setShowLegacyIdWarning] = useState(false)

  if (!user) {
    setShowCompletionModal(false)
    return null
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = (e.target.value ?? '').toLowerCase()
    const [email, domain] = value.split('@')
    if (!domain) {
      setShowEmailWarning('Invalid email address')
    } else if (!email.includes('.')) {
      setShowEmailWarning('Ikea email addresses contain a dot between name and surname')
    } else if (domain !== 'ingka.com' && domain !== 'ingka.ikea.com') {
      setShowEmailWarning('Email address must be an Ingka email address')
    } else {
      setShowEmailWarning('')
    }

    setCurrentUser((prev) => ({
      ...prev,
      id: value,
    }))
  }

  const handleLegacyIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = (e.target.value ?? '').toUpperCase()

    const regex = /^[A-Z]{4,5}[0-9]{0,3}$/g

    if (!value || !regex.test(value) || value.length < 5) {
      setShowLegacyIdWarning(true)
    } else {
      setShowLegacyIdWarning(false)
    }

    setCurrentUser((prev) => ({
      ...prev,
      legacyId: value,
    }))
  }

  const handleUserSave = () => {
    if (!currentUser.id || !currentUser.legacyId || !currentUser.role) {
      console.log('Parameters not full!')
      console.log(currentUser.id, currentUser.legacyId, currentUser.role)
      return
    }

    if (
      currentUser.id === user.id &&
      currentUser.legacyId === user.legacyId &&
      currentUser.role === user.role
    ) {
      setShowCompletionModal(false)
      return
    }

    saveUser({
      ...currentUser,
      edited: true,
    })
    setShowCompletionModal(false)
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!(e.target.value as UserRole)) return
    setCurrentUser((prev) => ({
      ...prev,
      role: e.target.value as UserRole,
    }))
  }

  return (
    <Modal visible={true} handleCloseBtn={() => setShowCompletionModal(false)}>
      <Sheets
        aria-label="Add user fixture modal"
        footer={
          <ModalFooter>
            <Fragment key=".0">
              <Button
                text={user.deleted ? 'Cancel Delete' : 'Delete fixture'}
                type="danger"
                disabled={!user.id}
                onClick={() => handleUserDelete(user.index)}
              />
              <Button
                text="Save fixture"
                type="primary"
                disabled={user.deleted || !!emailFieldWarning || showLegacyIdWarning}
                onClick={handleUserSave}
              />
            </Fragment>
          </ModalFooter>
        }
        header={
          <ModalHeader
            ariaCloseTxt="Close button text"
            backBtnClick={() => setShowCompletionModal(false)}
            title={user.id ? 'Edit User' : 'Add User'}
          />
        }
      >
        <div style={{ margin: '10px' }}>
          <FormField
            fieldHelper={{
              id: 'helper-msg-id',
              msg: user.id
                ? 'Cannot modify email - add new'
                : 'Paste the email address of the user you want to add.',
              type: '',
            }}
            valid="warning"
            shouldValidate={emailFieldWarning.length > 0}
            validation={{
              id: 'error-msg-id',
              msg: emailFieldWarning,
              type: 'warning',
            }}
          >
            <InputField
              id="Email"
              type="text"
              label="Email Address"
              onChange={handleEmailChange}
              value={currentUser.id}
              // We cannot change the email of an existing user
              disabled={!!user.id}
            />
          </FormField>

          <FormField
            fieldHelper={{
              id: 'helper-msg-id',
              msg: 'Paste the legacyID of the user you want to add.',
              type: '',
            }}
            valid="warning"
            shouldValidate={showLegacyIdWarning}
            validation={{
              id: 'error-msg-id',
              msg: 'Invalid legacy ID. Must be 4-5 letters followed by 0-3 numbers.',
              type: 'warning',
            }}
          >
            <InputField
              id="LegacyId"
              type="text"
              label="Legacy ID"
              onChange={handleLegacyIdChange}
              value={currentUser.legacyId}
            />
          </FormField>

          <Select
            id="role"
            title="Role"
            label="Desired Role"
            onChange={handleSelectChange}
            value={currentUser.role}
          >
            {Object.values(UserRole).map((role) => (
              <option key={role} value={role}>
                {role}
              </option>
            ))}
          </Select>
        </div>
      </Sheets>
    </Modal>
  )
}
