import { shadowDefaultSize } from 'styles/tokens/insikt.tokens'

type ObjectFit = 'cover' | 'contain' | 'fill' | 'none' | 'scale-down'

export const coworkerPhotoStyle = {
  display: 'inline-flex',
  width: '56px',
  height: '56px',
  borderRadius: '100%',
  aspectRatio: '1/1',
  border: 0,
  objectFit: 'cover' as ObjectFit,
  '&:hover': {
    filter: 'brightness(1.2)',
    boxShadow: shadowDefaultSize,
  },
}
