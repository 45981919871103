import { isQaMetricId } from 'config/domain/qa.config'

import { useCurrentQaDataForCoworker } from './useCurrentQaDataForCoworker.hooks'

const useCurrentScoreByQaId = (qaId: string, coworkerId: string) => {
  const qaData = useCurrentQaDataForCoworker(coworkerId)

  if (!isQaMetricId(qaId)) {
    return NaN
  }

  if (!qaData) {
    return NaN
  }

  const currentScore = qaData.scores.find((entry) => entry.id === qaId)?.averageScore ?? NaN

  return currentScore
}

export default useCurrentScoreByQaId
