import { OperationalMetricId } from 'config/domain/metrics.config'
import { QaMetricId } from 'config/domain/qa.config'

export type MissionUpdate = {
  date: string
  score: number
  changes: MissionChanges
}

export type CreateMissionInput = {
  title: string
  coachingPointers: string
  createdBy: string

  qaId: QaMetricId
  metricId?: OperationalMetricId

  target: number

  endDate: string
  createdDate: string
}

export type Mission = {
  id: string
  createdBy: string
  createdDate: string

  qaId: QaMetricId
  metricId?: OperationalMetricId

  suggestedToComplete: boolean

  title: string
  coachingPointers: string
  target: number
  endDate: string

  status: MissionStatus

  startedDate?: string
  startedScore?: number

  completedDate?: string
  completedScore?: number

  updates: MissionUpdate[]

  changes: MissionChanges
}

export enum MissionStatus {
  Draft = 'draft',
  Active = 'active',
  Completed = 'completed',
}

export type MissionChanges = {
  title: string
  coachingPointers: string
  target: number
  endDate: string
}

export type MissionResult = {
  metricStartScore: number
  metricEndScore: number
  qaStartScore: number
  qaEndScore: number
}
