import Button from '@ingka/button'
import FormField from '@ingka/form-field'
import InputField from '@ingka/input-field'
import Modal, { ModalBody, Sheets } from '@ingka/modal'
import Select, { Option } from '@ingka/select'
import plus from '@ingka/ssr-icon/paths/plus'
import Switch from '@ingka/switch'
import TextArea from '@ingka/text-area'
import Toast from '@ingka/toast'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { BadgeIconType, BadgeType } from 'lib/types/badge.types'

import { useCreateBadgeMutation } from 'state/slices/api'

import { BadgeWithTooltip } from 'components/composites/Badges'
import { Stack } from 'components/layout/Stack'
import { Heading } from 'components/primitives/Text'

import { iconTypeRegex } from '../ManageBadgesPage.utils'

const BadgeCreate = () => {
  const [createBadge, { isError, isSuccess }] = useCreateBadgeMutation()

  const [showModal, setShowModal] = useState(false)
  const [showConfirmationToast, setShowConfirmationToast] = useState(false)
  const [showRejectionToast, setShowRejectionToast] = useState(false)

  const { control, getValues, handleSubmit, formState, reset, watch } = useForm({
    defaultValues: {
      badgeDefinitionId: '',
      title: '',
      description: '',
      flair: '',
      category: '',
      iconType: 'bronze' as BadgeIconType,
      type: 'achieved' as BadgeType,
      secret: false,
      groupId: '',
      groupOrder: '',
    },
  })

  const newBadge = watch()
  const { groupId, groupOrder, type } = newBadge

  const onSubmit = useCallback(() => {
    const { groupId, groupOrder, ...values } = getValues()

    const order = Number.parseInt(groupOrder)
    const group =
      groupId && order && !Number.isNaN(order)
        ? {
            groupId,
            order,
          }
        : undefined

    const badge = {
      ...values,
    }

    if (group) {
      Object.assign(badge, { group })
    }

    createBadge(badge)
    reset()
    setShowModal(false)
  }, [createBadge, getValues, reset])

  useEffect(() => {
    if (isSuccess) {
      setShowConfirmationToast(true)
      reset()
    }
  }, [isSuccess, reset])

  useEffect(() => {
    if (isError) {
      setShowRejectionToast(true)
    }
  }, [isError])

  const validateBadgeGroup = useCallback(() => {
    if ((groupId || groupOrder) && type === BadgeType.Awardable) {
      return 'You can only assign a group to achievable badges.'
    }

    if ((groupId && !groupOrder) || (!groupId && groupOrder)) {
      return 'Please fill out both group fields or leave both empty.'
    }

    return true
  }, [groupId, groupOrder, type])

  return (
    <div>
      <Stack>
        <Button iconOnly ssrIcon={plus} onClick={() => setShowModal(true)} />
      </Stack>
      {/**
       * TODO
       * This doesn't work: the form is reset after submission, so newBadge resets.
       * Find a way to show the created badge instead, e.g. using the mutation return value
       */}
      <Toast
        isOpen={showConfirmationToast}
        text="The badge was created successfully."
        onCloseRequest={() => setShowConfirmationToast(false)}
      />
      <Toast
        isOpen={showRejectionToast}
        text="An error occurred while creating the badge."
        onCloseRequest={() => setShowRejectionToast(false)}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal visible={showModal} handleCloseBtn={() => setShowModal(false)}>
          <Sheets alignment="left" header={null} footer={null}>
            <ModalBody>
              <Heading>{'Preview'}</Heading>
              <BadgeWithTooltip badge={newBadge} showInColor />
              <Controller
                name="badgeDefinitionId"
                control={control}
                rules={{
                  required: 'Please provide a badge ID.',
                  pattern: {
                    value: /^\w*(\.\w+)?\.\w+$/,
                    message:
                      'Please use the naming convention {categoryName}(.{groupName}).{badgeName}',
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    shouldValidate={formState.submitCount !== 0}
                    valid={formState.errors.badgeDefinitionId == null}
                    validation={{
                      msg: formState.errors.badgeDefinitionId?.message,
                    }}
                    fieldHelper={{
                      msg: `Uniquely identifies this badge.
                            Please use the naming convention {categoryName}(.{groupname}).{badgeName}, 
                            e.g. "customerHappiness.csatStreak.0" or "general.completeMission".`,
                    }}
                  >
                    <InputField
                      label="Badge ID"
                      id="badgeId"
                      data-testid="badgeId"
                      type="text"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  </FormField>
                )}
              />
              <Controller
                name="title"
                control={control}
                rules={{
                  required: 'Please provide a title.',
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    shouldValidate={formState.submitCount !== 0}
                    valid={formState.errors.title == null}
                    validation={{
                      msg: formState.errors.title?.message,
                    }}
                  >
                    <InputField
                      label="Title"
                      id="title"
                      data-testid="title"
                      type="text"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  </FormField>
                )}
              />
              <Controller
                name="description"
                control={control}
                rules={{
                  required: 'Please provide a description.',
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    shouldValidate={formState.submitCount !== 0}
                    valid={formState.errors.description == null}
                    validation={{
                      msg: formState.errors.description?.message,
                    }}
                  >
                    <TextArea
                      label="Description"
                      id="description"
                      data-testid="description"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  </FormField>
                )}
              />
              <Controller
                name="category"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    shouldValidate={formState.submitCount !== 0}
                    valid={formState.errors.category == null}
                    validation={{
                      msg: formState.errors.category?.message,
                    }}
                  >
                    <InputField
                      label="Category"
                      id="category"
                      data-testid="category"
                      type="text"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  </FormField>
                )}
              />
              <Controller
                name="flair"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    shouldValidate={formState.submitCount !== 0}
                    valid={formState.errors.flair == null}
                    validation={{
                      msg: formState.errors.flair?.message,
                    }}
                  >
                    <TextArea
                      label="Flair"
                      id="flair"
                      data-testid="flair"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  </FormField>
                )}
              />
              <Controller
                name="iconType"
                control={control}
                rules={{
                  required: 'Please select an icon type.',
                  pattern: {
                    value: iconTypeRegex,
                    message:
                      "The icon type must be either 'bronze', 'silver', 'gold' or 'awarded'.",
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    shouldValidate={formState.submitCount !== 0}
                    valid={formState.errors.iconType == null}
                    validation={{
                      msg: formState.errors.iconType?.message,
                    }}
                  >
                    <InputField
                      label="Icon type"
                      id="iconType"
                      data-testid="iconType"
                      type="text"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  </FormField>
                )}
              />
              <Controller
                name="type"
                control={control}
                rules={{
                  required: 'Please select a badge type.',
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    shouldValidate={formState.submitCount !== 0}
                    valid={formState.errors.type == null}
                    validation={{
                      msg: formState.errors.type?.message,
                    }}
                  >
                    <Select
                      label="Badge type"
                      id="type"
                      data-testid="type"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      hintText=""
                    >
                      <Option value="achieved" name="Achieved" />
                      <Option value="awarded" name="Awarded" />
                    </Select>
                  </FormField>
                )}
              />
              <Controller
                name="secret"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    shouldValidate={formState.submitCount !== 0}
                    valid={formState.errors.secret == null}
                    validation={{
                      msg: formState.errors.secret?.message,
                    }}
                  >
                    <Switch
                      label="Secret"
                      id="secret"
                      data-testid="secret"
                      checked={value}
                      value="secret"
                      disabled={type === BadgeType.Awardable}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  </FormField>
                )}
              />
              <Controller
                name="groupId"
                control={control}
                rules={{ validate: validateBadgeGroup }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    shouldValidate={formState.submitCount !== 0}
                    valid={formState.errors.groupId == null}
                    validation={{
                      msg: formState.errors.groupId?.message,
                    }}
                  >
                    <InputField
                      type="text"
                      label="Group ID"
                      id="groupId"
                      data-testid="groupId"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  </FormField>
                )}
              />
              <Controller
                name="groupOrder"
                control={control}
                rules={{
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'The group order must consist of only digits.',
                  },
                  validate: validateBadgeGroup,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    shouldValidate={formState.submitCount !== 0}
                    valid={formState.errors.groupOrder == null}
                    validation={{
                      msg: formState.errors.groupOrder?.message,
                    }}
                  >
                    <InputField
                      type="text"
                      label="Group order"
                      id="groupOrder"
                      data-testid="groupOrder"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  </FormField>
                )}
              />
              <Button type="primary" htmlType="submit" text="Create badge" />
            </ModalBody>
          </Sheets>
        </Modal>
      </form>
    </div>
  )
}

export default BadgeCreate
