/*
    ROOT EVENT
*/

export interface RootEventInput {
  role: string
  userId: string
}

/*
    CUSTOM VIEW EVENT
*/

export interface CustomViewEventInput {
  key: CustomViewKeys
  description: string
  coworkerId: string | undefined
}

export enum CustomViewKeys {
  Coworkers = 'coworkers',
  CoworkerMissions = 'coworker_missions',
  MissionsActive = 'missions_active',
  MissionsCompleted = 'missions_completed',
  CoworkerDiscovery = 'coworker_discovery',
  CoworkerQa = 'coworker_qa',
  CoworkerCoaching = 'coworker_coaching',
  PrepPrepare = 'prep_prepare',
  PrepReview = 'prep_summary',
  CoachingOverview = 'coaching_overview',
  CoachingMissions = 'coaching_missions',
  CoachingSummary = 'coaching_summary',
}

/*
    ELEMENT CLICK EVENT
*/

export interface CustomElementClickEventInput {
  key: CustomElementClickEventKeys
  description: string
}

export enum CustomElementClickEventKeys {
  PrepareCoaching = 'element_prepare_coaching',
  StartCoaching = 'element_start_coaching',
  InspectCoaching = 'element_inspect_coaching',
  ReviewCoaching = 'element_review_coaching',
  NewMission = 'element_new_mission',
  EditMission = 'element_edit_mission',
  SuggestCompleteMission = 'element_suggest_complete_mission',
  ReadyForCoaching = 'element_ready_for_coaching',
  CompleteCoaching = 'element_complete_coaching',
  EditCoaching = 'element_edit_coaching',
  EvaluatedSmClick = 'review_evaluated_sm',
  PlanCoachingSession = 'plan_coaching_session',
}

/*
    CUSTOM EVENT TYPES
*/
export enum CustomEventTypes {
  CustomView = 'custom_view',
  CustomRootEvent = 'root_event',
  CustomElementClick = 'element_click',
}

/*
    CUSTOM OTHER EVENTS
*/
export enum CustomOtherEventKeys {
  Error = 'ERROR',
  CurrentStatus = 'current_status',
}
