import { models } from 'powerbi-client'
import { HyperlinkClickBehavior } from 'powerbi-models'
import { useEffect, useState } from 'react'

import { useUser } from 'hooks/users'

import { useFetchPowerBiURLsQuery } from 'state/slices/api/modules/power-bi'

import { PowerBiReportMeta, ReportNavigationMap } from './EmbedReport.types'
import { transformToMap } from './EmbedReport.utils'

export enum ReportError {
  Unauthorised = 'no-auth',
  Generic = 'generic',
  NoError = 'no-error',
}

export const useReloadReport = (msAccessToken: any, reportMeta?: PowerBiReportMeta) => {
  const locale = useUser().data?.prefs.locale
  const [reportError, setError] = useState<ReportError>(ReportError.NoError)
  const [isLoading, setLoading] = useState<boolean>(true)
  const [reportConfiguration, setReportConfiguration] = useState<models.IReportEmbedConfiguration>({
    type: 'report',
    tokenType: models.TokenType.Aad,
    settings: {
      layoutType: models.LayoutType.Custom,
      hyperlinkClickBehavior: HyperlinkClickBehavior.NavigateAndRaiseEvent,
      navContentPaneEnabled: false,
      customLayout: {
        displayOption: models.DisplayOption.FitToPage,
      },
      localeSettings: {
        // TODO: Making it default to spain for the test release, probably need changing afterwards
        formatLocale: locale ?? 'es-ES',
      },
    },
    eventHooks: {
      //accessTokenProvider TODO LOOK into adding a accessToken provider
    },
  })

  useEffect(() => {
    const reloadTheReport = async () => {
      if (!msAccessToken || !reportMeta) {
        return
      }

      setLoading(true)

      // Get the embed config from the service
      const reportConfigResponse = await fetch(reportMeta.url, {
        headers: {
          Authorization: `Bearer ${msAccessToken}`,
        },
      })

      if (!reportConfigResponse) {
        setError(ReportError.Generic)
        console.error('Failed to fetch config for report - missing response!')
        return
      }

      if (!reportConfigResponse.ok) {
        if (reportConfigResponse.status === 401) {
          setError(ReportError.Unauthorised)
          return
        }
        setError(ReportError.Generic)
        console.error(
          `Failed to fetch config for report. Status: ${reportConfigResponse.status} ${reportConfigResponse.statusText}`
        )
        return
      }

      const reportConfig = await reportConfigResponse.json()
      // Update the reportConfig to embed the PowerBI report
      setReportConfiguration((prev) => ({
        ...prev,
        embedUrl: reportConfig.embedUrl,
        accessToken: msAccessToken,
        pageName: reportMeta.initialPage,
      }))
      setLoading(false)
    }

    reloadTheReport()
  }, [reportMeta, msAccessToken])

  return {
    reportConfiguration,
    reportError,
    isLoading,
  }
}

export const useReportNavigationMap = () => {
  const [navMap, setNavMap] = useState<ReportNavigationMap>()
  const navResponse = useFetchPowerBiURLsQuery().data

  useEffect(() => {
    if (!navResponse || navResponse.length === 0) return
    setNavMap(transformToMap(navResponse))
  }, [navResponse])

  return navMap
}

export const usePrefilterReport = () => {}
