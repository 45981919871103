import SSRIcon from '@ingka/ssr-icon'
import note from '@ingka/ssr-icon/paths/note'
import { space200 } from '@ingka/variables'
import { Box, Group } from '@mantine/core'
import * as DOMPurify from 'dompurify'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'

import { isJsonString, jsonToHtml } from 'lib/utils/generateHTML.helper'

import { radius25, spacing50, spacing75, spacing100 } from 'styles/tokens/insikt.tokens'
import { colourNeutralGrey100 } from 'styles/tokens/oldSkapa.tokens'

import { Widget } from 'components/composites/Shared/Widget'
import { SubHeading } from 'components/primitives/Text'
type SessionCommentProps = {
  comment: string
}

/** Added html-react-parser package to render RTF output, since 'dagerouslySetInnerHTML' is not ideal  */
const SessionComment: React.FC<SessionCommentProps> = ({ comment }) => {
  const { t } = useTranslation()

  const santizedHTML = DOMPurify.sanitize(comment ?? '')

  const renderHTMLMarkup = () => {
    // yes, this is a little repetitive but it's mandatory to check all various case scenarios
    if (!comment || comment === '')
      return <Box>{t('features.coaching-session.session-comment.no-comment')}</Box>
    if (!isJsonString(comment)) return <Box>{comment}</Box>
    if (isJsonString(comment)) {
      const commentAsJson = JSON.parse(santizedHTML)
      const parsedHTML = parse(jsonToHtml(commentAsJson))
      return <Box>{parsedHTML}</Box>
    } else {
      return <Box>{t('features.coaching-session.session-comment.no-comment')}</Box>
    }
  }

  return (
    <>
      <Widget border style={{ marginTop: spacing100 }}>
        <Group gap={spacing50}>
          <SSRIcon paths={note} />
          <SubHeading>{t('features.coaching-session.session-comment.title')}</SubHeading>
        </Group>
        <Box
          mt={spacing75}
          bg={colourNeutralGrey100}
          p={spacing75}
          mih={space200}
          style={{ borderRadius: radius25 }}
        >
          {renderHTMLMarkup()}
        </Box>
      </Widget>
    </>
  )
}

export default SessionComment
