import { space100, space300 } from '@ingka/variables'
import { Flex } from '@mantine/core'

import { backgroundColourDefault } from 'styles/tokens/insikt.tokens'

const OverviewBase = (props: any) => {
  return (
    <Flex
      px={space300}
      w={'100%'}
      h={'100%'}
      mih={'80vh'}
      bg={backgroundColourDefault}
      py={space100}
      rowGap={space100}
      direction={'column'}
    >
      {props.children}
    </Flex>
  )
}

export default OverviewBase
