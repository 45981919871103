import { colourNeutralGrey200 } from './tokens/oldSkapa.tokens'

export const missionIconSquare = {
  backgroundColor: colourNeutralGrey200,
  padding: '5px',
  width: '35px',
  height: '35px',
  borderRadius: '50%',
}

export const missionsWidgetStyle = {
  backgroundColor: 'white',
  paddingBottom: '30px',
  marginBottom: '20px',
}
