import { SkipToken } from '@reduxjs/toolkit/query'

import { CoachingSessionStatus } from 'lib/types/coachingSession.types'

import { useCoachingSessions } from 'state/slices/api'

export const useUpcomingCoachingSession = (coworkerId: string | SkipToken) => {
  const { data: coachingSessions, ...meta } = useCoachingSessions(coworkerId)

  return {
    data: coachingSessions?.find(
      (coachingSession) => coachingSession.status === CoachingSessionStatus.Planned
    ),
    ...meta,
  }
}
