import { isQaMetricId, QaMetricId } from 'config/domain/qa.config'

import { useTranslatedQuestions } from 'state/slices/api/api.hooks'

export const useAssessmentCriteria = (qaId: QaMetricId) => {
  const { data, isLoading } = useTranslatedQuestions()
  if (!isQaMetricId(qaId)) {
    return { assessmentCriteria: [], isLoading: false }
  }

  const assessmentCriteria: string[] = data?.translations[qaId]?.assessmentCriteria ?? []

  return { assessmentCriteria, isLoading }
}
