import { space100 } from '@ingka/variables'
import { Box, Flex } from '@mantine/core'
import React from 'react'

import { Caption, Heading } from 'components/primitives/Text'

import * as Styled from './Widget.styling'

type WidgetHeaderProps = {
  /** If set, creates a heading with the provided text content. */
  title?: React.ReactNode
  /** If set, creates a subheading with the provided text content. */
  subtitle?: React.ReactNode
  /** If set, shows an icon next to the title */
  ssrIcon?: () => React.SVGProps<SVGElement>[]
  rightSideContent?: React.ReactNode
  /**If set, shows a caption e.g., in Mission preview */
  caption?: React.ReactNode
  /**Toggle button to switch between active and modified draft state */
  switchButton?: React.ReactNode
  /**Make header center of the card */
  centerHeader?: boolean
}

const WidgetHeader: React.FC<WidgetHeaderProps> = ({
  title,
  subtitle,
  ssrIcon,
  rightSideContent,
  caption,
  switchButton,
  centerHeader = false,
}) => {
  return (
    <Box mb={space100} data-testid="widget-header">
      {caption && <Caption data-testid="widget-header-caption">{caption}</Caption>}
      <Flex justify="space-between" align="center">
        <Flex
          align="center"
          justify={centerHeader ? 'center' : 'flex-start'}
          style={centerHeader ? { width: '100%' } : {}}
        >
          {ssrIcon && <Styled.HeaderIcon paths={ssrIcon} />}
          {React.isValidElement(title) ? (
            title
          ) : (
            <Heading data-testid="widget-header-title">{title}</Heading>
          )}
        </Flex>
        <Box style={{ flexGrow: 0 }}>{rightSideContent}</Box>
      </Flex>
      <Flex justify="space-between">
        {subtitle && (
          <>
            {React.isValidElement(subtitle) ? (
              subtitle
            ) : (
              <Caption data-testid="widget-header-subtitle">{subtitle}</Caption>
            )}
          </>
        )}
        <Box style={{ flexGrow: 0 }}>{switchButton}</Box>
      </Flex>
    </Box>
  )
}

export default WidgetHeader
