import {
  Document,
  HeadingNode,
  ListItemNode,
  ListNode,
  Node,
  TextNode,
} from 'lib/types/richTextDocument.types'

/**
 * Converts a JSON document to HTML string.
 * @param json - The JSON document to convert.
 * @returns The HTML string representation of the JSON document.
 */

/**
 * Todo: Refactor this function to use Tiptap/ProseMirror's 'generateHTML()' function
 * for readability and maintainability purposes
 * https://tiptap.dev/docs/editor/guide/output#option-1-json
 */

export function jsonToHtml(json: Document): string {
  const parseNodes = (nodes: Node[]): string => {
    return (
      nodes &&
      nodes
        .map((node) => {
          // Process marks if they exist
          let text = node.type === 'text' ? (node as TextNode).text : ''
          if (node.marks) {
            node.marks.forEach((mark) => {
              switch (mark.type) {
                case 'bold':
                  text = `<strong>${text}</strong>`
                  break
                case 'italic':
                  text = `<em>${text}</em>`
                  break
                case 'underline':
                  text = `<u>${text}</u>`
                  break
                case 'strike':
                  text = `<s>${text}</s>`
                  break
                case 'link':
                  text = `<a href=${mark?.attrs?.href ?? ''} target=${
                    mark?.attrs?.target ?? '_blank'
                  } rel='noopener noreferrer nofollow'>
                  ${text}
                  </a>`
                  break
                default:
                  break
              }
            })
            return text
          }
          if (node.type === 'text') {
            const textNode = node as TextNode
            return textNode.text
          } else {
            const children = node.content ? parseNodes(node.content) : ''
            switch (node.type) {
              case 'paragraph': {
                return `<p>${children}</p>`
              }
              case 'heading': {
                const headingNode = node as HeadingNode
                return `<h${headingNode.attrs.level}>${children}</h${headingNode.attrs.level}>`
              }
              case 'bulletList':
              case 'orderedList': {
                const listNode = node as ListNode
                return `<${listNode.type === 'bulletList' ? 'ul' : 'ol'}>
                ${children}
                </${listNode.type === 'bulletList' ? 'ul' : 'ol'}>`
              }
              case 'listItem': {
                return `<li class="bullet-ordered-list">${children}</li>`
              }
              case 'taskList': {
                // Checkbox list
                return `<ul class="coaching-pointers-taskList" data-type="taskList">
                ${children}
                </ul>`
              }
              case 'taskItem': {
                const taskItemNode = node as ListItemNode
                const checkedAttr = taskItemNode.attrs.checked ? 'checked' : ''
                return `<li>
                <input type="checkbox" data-type="taskItem" ${checkedAttr} disabled> 
                ${children}
                </li>` // disabled in readOnly mode
              }
              default:
                return children
            }
          }
        })
        .join('')
    )
  }
  return `<div class="json-to-html-output">${parseNodes(json.content)}</div>`
}

/**
 * Helper function to check if previously saved session comment/coachingPointers
 * is a JSON string or plain text.
 * @param str - The string to check.
 * To avoid: "Uncaught RangeError: Invalid text node in JSON" if it's not a valid Tiptap JSON string
 */

export const isJsonString = (str: string | undefined): boolean => {
  try {
    const result = JSON.parse(str?.trim() ?? '')
    return typeof result === 'object' && result !== null
  } catch {
    return false
  }
}

/**
 * Helper function to check if a string is empty or not.
 */

export const isEmptyString = (str: string) => str.trim() === ''

/**
 * If there already is value, check its type
 * specially true for old session comment and coaching pointers which were plain text
 * Todo: Might not be needed anymore, refactor to remove if not used
 * @param comment - The comment to check.
 * @returns The session comment as a JSON document.
 */

// rename this function to something more descriptive
export const parseCommentValue = (value: string) => {
  if (isJsonString(value)) {
    // If it's JSON, parse it and use as content
    return JSON.parse(value)
  } else {
    return {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: value.replace(/"/g, ''),
            },
          ],
        },
      ],
    }
  }
}
