import { space25, space100 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import React from 'react'

import { Label, SubHeading, Subtitle } from '../Text'
import * as Styled from './NumberedHeader.styling'

/** Header component for coaching session flow with step counter */
type NumberedHeaderProps = {
  /** If set, creates a heading with the provided text content. */
  title?: React.ReactNode | string
  /** If set, creates a subtitle with the provided text content. */
  subtitle?: React.ReactNode | string
  /** If set, creates a circled pill with the provided count/number step. */
  step?: string | number
} & React.HTMLAttributes<HTMLDivElement>

const NumberedHeader: React.FC<NumberedHeaderProps> = ({ title, subtitle, step }) => {
  return (
    <>
      <Flex justify="flex-start" gap={space100} align="stretch">
        {step && (
          <Styled.StyledPill
            size="small"
            style={{ backgroundColor: 'black' }}
            label={<SubHeading style={{ color: 'white' }}>{step}</SubHeading>}
          ></Styled.StyledPill>
        )}
        {title && (
          <Subtitle data-testid="numbered-header-title" style={{ marginBottom: space25 }}>
            {title}
          </Subtitle>
        )}
      </Flex>
      <Flex>{subtitle && <Label data-testid="numbered-header-subtitle">{subtitle}</Label>}</Flex>
    </>
  )
}

export default NumberedHeader
