import Button from '@ingka/button'
import pencil from '@ingka/ssr-icon/paths/pencil'
import { space150 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import dayjs from 'dayjs'
import { isEqual } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { QaMetricId } from 'config/domain/qa.config'

import { Mission, MissionStatus } from 'lib/types/mission.types'
import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'
import { CustomElementClickEventKeys } from 'lib/utils/analytics/analytics.types'

import { useFormatDate } from 'hooks/formatting'
import {
  useDeleteMissionDraftClicked,
  useSuggestCompleteMissionClicked,
} from 'hooks/missions/useMissionActions.hooks'
import { useUser } from 'hooks/users'

import { backgroundColourDefault } from 'styles/tokens/insikt.tokens'

import { PromptModal } from 'components/composites/Shared/PromptModal'
import { Widget, WidgetFooter, WidgetHeader } from 'components/composites/Shared/Widget'
import { MissionDraftDrawer } from 'components/features/Missions/MissionDraftEditor/MissionDraftDrawer'
import MissionOverviewDraftSwitch from 'components/features/Missions/MissionOverview/components/MissionOverviewDraftSwitch.component'
import MissionQaProgress from 'components/features/Missions/MissionQaProgress/MissionQaProgress.component'
import { GreyBoldedSubheading, Paragraph } from 'components/primitives/Text'

import { MissionCoachingPointersFormatted } from '../../MissionCoachingPointersFormatted'

type MissionPreviewTeaserProps = {
  mission: Mission
  coworkerId: string
}

const MissionPreviewTeaser = ({ mission, coworkerId }: MissionPreviewTeaserProps) => {
  const { t } = useTranslation()
  const user = useUser()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [showSuggestCompleteModal, setShowSuggestCompleteModal] = useState(false)
  const [showDeleteDraftModal, setShowDeleteDraftModal] = useState(false)
  const [handleDeleteMissionDraft, { isLoading: isLoadingDeleteDraft }] =
    useDeleteMissionDraftClicked(coworkerId, mission.id)

  const [suggestMission, suggestMissionArgs, { isLoading: isLoadingSuggestComplete }] =
    useSuggestCompleteMissionClicked(coworkerId, mission.id, mission.suggestedToComplete)

  // Confirm suggest to complete or undo suggested to complete
  const handleSuggestedCompleteMissionClicked = async () => {
    await suggestMission(suggestMissionArgs)
    analyticsHelper.createCustomElementClickEvent(
      {
        key: CustomElementClickEventKeys.SuggestCompleteMission,
        description:
          'Custom event for tracking when a teamlead clicks on the suggest to complete mission button.',
      },

      {
        ...suggestMissionArgs,
        createdBy: user.data?.legacyId,
      }
    )
    setShowSuggestCompleteModal(false)
  }

  const activeValues = {
    title: mission.title,
    coachingPointers: mission.coachingPointers,
    target: mission.target,
    endDate: mission.endDate,
  }
  const isChanged = !isEqual(mission.changes, activeValues)

  const [showChanges, setShowChanges] = useState(
    isChanged && mission.suggestedToComplete ? false : true
  )
  const selectedValues = showChanges ? mission.changes : activeValues

  const formatDate = useFormatDate()

  return (
    <>
      <Widget
        style={{
          backgroundColor: backgroundColourDefault,
        }}
        header={
          <WidgetHeader
            title={selectedValues.title}
            rightSideContent={
              isChanged &&
              mission.status === MissionStatus.Active && (
                <MissionOverviewDraftSwitch
                  isChanged={isChanged}
                  showChanges={showChanges}
                  setShowChanges={setShowChanges}
                />
              )
            }
          />
        }
        footer={
          <Flex justify="flex-end">
            <WidgetFooter>
              <Button
                small
                ssrIcon={pencil}
                iconPosition="leading"
                type="secondary"
                text={t('features.mission.actions.edit-mission')}
                disabled={mission.suggestedToComplete}
                onClick={() => setIsEditing(true)}
              />
              {mission.status === MissionStatus.Draft && (
                <Button
                  type="danger"
                  small
                  text={t('features.mission.actions.delete-draft')}
                  loading={isLoadingDeleteDraft}
                  onClick={() => setShowDeleteDraftModal(true)}
                />
              )}
              {mission.status === MissionStatus.Active && (
                <>
                  <Button
                    type={!mission.suggestedToComplete ? 'primary' : 'secondary'}
                    small
                    text={
                      !mission.suggestedToComplete
                        ? t('features.mission.actions.suggest-to-complete')
                        : t('features.mission.actions.undo-suggest-to-complete')
                    }
                    loading={isLoadingSuggestComplete}
                    onClick={() => setShowSuggestCompleteModal(true)}
                  />
                </>
              )}
            </WidgetFooter>
          </Flex>
        }
      >
        <Flex w={'100%'} direction={'column'} gap={space150}>
          {mission.status === MissionStatus.Active &&
            mission.startedDate &&
            selectedValues.endDate && (
              <Flex direction={'column'}>
                <Paragraph>
                  {t('features.mission.preview.mission-started', {
                    date: formatDate(new Date(mission.startedDate)),
                  })}
                </Paragraph>
                <Paragraph>
                  {t('features.mission.preview.mission-planned', {
                    date: formatDate(new Date(selectedValues.endDate)),
                  })}
                </Paragraph>
                <Paragraph>
                  {dayjs().isBefore(selectedValues.endDate)
                    ? t('features.mission.preview.days-left', {
                        date: dayjs(selectedValues.endDate).diff(dayjs(), 'days'),
                      })
                    : t('features.mission.preview.days-overdue', {
                        date: dayjs().diff(selectedValues.endDate, 'days'),
                      })}
                </Paragraph>
              </Flex>
            )}

          <Flex direction={'column'}>
            <GreyBoldedSubheading>
              {t('features.mission.preview.coaching-pointers')}
            </GreyBoldedSubheading>
            <MissionCoachingPointersFormatted coachingPointers={selectedValues.coachingPointers} />
          </Flex>

          <Flex direction={'column'}>
            <GreyBoldedSubheading>{t('features.mission.preview.qa-progress')}</GreyBoldedSubheading>
            <MissionQaProgress
              mission={mission}
              coworkerId={coworkerId}
              showChanges={showChanges}
            />
          </Flex>
        </Flex>
      </Widget>

      {isEditing && mission && (
        <MissionDraftDrawer
          coworkerId={coworkerId}
          showCreateMission={isEditing}
          closeDrawer={() => setIsEditing(false)}
          mission={mission}
          selectedCheckboxId={mission.qaId as QaMetricId}
          isEditing={true}
        />
      )}

      {/* Suggest to complete */}
      {showSuggestCompleteModal && (
        <PromptModal
          title={
            !mission.suggestedToComplete
              ? t('features.mission.confirm-mission-actions-modal.suggest-to-complete.title')
              : t('features.mission.confirm-mission-actions-modal.undo-suggest-to-complete.title')
          }
          titleId={
            !mission.suggestedToComplete
              ? t('suggest-to-complete-mission-modal')
              : t('undo-suggest-to-complete-mission-modal')
          }
          content={
            !mission.suggestedToComplete
              ? t('features.mission.confirm-mission-actions-modal.suggest-to-complete.warnings')
              : t(
                  'features.mission.confirm-mission-actions-modal.undo-suggest-to-complete.warnings'
                )
          }
          onCancel={() => setShowSuggestCompleteModal(false)}
          onConfirm={() => handleSuggestedCompleteMissionClicked()}
          loading={isLoadingSuggestComplete}
        />
      )}

      {/* Delete draft confirmation */}
      {showDeleteDraftModal && (
        <PromptModal
          title={t('features.mission.confirm-mission-actions-modal.delete-draft.title')}
          titleId={t('delete-draft-mission-modal')}
          content={t('features.mission.confirm-mission-actions-modal.delete-draft.warnings')}
          onCancel={() => setShowDeleteDraftModal(false)}
          onConfirm={() => handleDeleteMissionDraft()}
          loading={isLoadingDeleteDraft}
        />
      )}
    </>
  )
}

export default MissionPreviewTeaser
