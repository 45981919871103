import { Flex } from '@mantine/core'

import { FormattingHelper } from 'lib/utils/formatting.helper'

import { radius25, spacing25, textColour5 } from 'styles/tokens/insikt.tokens'

import { Paragraph } from '../Text'

interface NumberWidgetProps {
  number: number
  label: string
}

export const NumberWidget = ({ number, label }: NumberWidgetProps) => (
  <Flex
    align="center"
    justify="center"
    direction="column"
    style={{
      padding: spacing25,
      backgroundColor: textColour5,
      borderRadius: radius25,
      width: '10rem',
    }}
  >
    <Paragraph style={{ marginBottom: spacing25, fontSize: '12px' }}>{label}</Paragraph>
    <Paragraph style={{ fontWeight: 700, fontSize: '16px' }}>
      {FormattingHelper.displayNumber(number)}
    </Paragraph>
  </Flex>
)
