import { space50, space100 } from '@ingka/variables'
import { Box, Flex } from '@mantine/core'

import { Coworker } from 'lib/types/coworker.types'

import { Heading } from 'components/primitives/Text'

import { BackButton } from './BackButton'

type PageHeaderProps = {
  title: string | React.ReactNode
  backHref?: string
  rightContent?: React.ReactNode
  coworker?: Coworker
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, backHref, rightContent, coworker }) => {
  return (
    <Flex justify="space-between" align="center" pt={space100} pb={space50}>
      <Flex align="center" gap={space50}>
        {backHref && <BackButton to={backHref} />}
        <Box>{title && typeof title === 'string' ? <Heading>{title}</Heading> : title}</Box>
      </Flex>
      <Box>{rightContent}</Box>
    </Flex>
  )
}

export default PageHeader
