import Button from '@ingka/button'
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal'
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Paragraph } from 'components/primitives/Text'

import { LoadingIndicator } from '../LoadingIndicator'

/** A reusable modal component that can be reused in the rest of the application */
type Props = {
  title: string
  titleId: string
  content: ReactNode
  onCancel: () => void
  onConfirm: () => void
  loading?: boolean
}

const PromptModal: React.FC<Props> = ({
  title,
  content,
  onCancel,
  onConfirm,
  titleId,
  loading,
}) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(true)

  const closeModal = () => {
    setIsOpen(false)
    onCancel()
  }
  return (
    <>
      {loading && <LoadingIndicator />}
      <Modal visible={isOpen} handleCloseBtn={closeModal}>
        <Prompt
          title={title}
          datatest-id={`confirmation-modal-${titleId}`}
          titleId={titleId}
          header={<ModalHeader />}
          footer={
            <ModalFooter>
              <Button
                small
                type="secondary"
                text={t('common.actions.cancel')}
                onClick={onCancel}
                loading={loading}
              />
              <Button
                small
                type="primary"
                text={t('common.actions.confirm')}
                htmlType="submit"
                loading={loading}
                onClick={onConfirm}
              />
            </ModalFooter>
          }
        >
          <Paragraph>{content}</Paragraph>
        </Prompt>
      </Modal>
    </>
  )
}

export default PromptModal
