import { TeamleadUser, User } from 'lib/types/user.types'

import { apiSlice } from 'state/slices/api/api.slice'

type MarkNotificationAsReadParams = {
  userId: string
  notificationId: string
}

const userSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    markNotificationAsRead: builder.mutation<void, MarkNotificationAsReadParams>({
      query: ({ userId, notificationId }) => ({
        url: `/users/${userId}/notifications/${notificationId}/mark-read`,
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
    markAllNotificationsAsRead: builder.mutation<void, string>({
      query: (userId) => ({
        url: `/users/${userId}/notifications/mark-read`,
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),

    fetchUser: builder.query<User, string>({
      query: (userId) => `/users/${userId}`,
      providesTags: ['User'],
    }),
    searchForUsers: builder.query<User[], string>({
      query: (searchTerm) => ({
        url: `/users/search/${searchTerm}`,
      }),
      providesTags: (result) =>
        result ? result.map((user) => ({ type: 'User' as const, id: user.legacyId })) : ['User'],
    }),
    syncUserInfo: builder.mutation<void, string>({
      query: (userId) => ({
        url: `/users/${userId}/sync-info`,
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),

    fetchTeamleadUserById: builder.query<TeamleadUser, string>({
      query: (userId) => ({
        url: `/users/${userId}/teamlead`,
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
  }),
})

export const {
  useMarkNotificationAsReadMutation: useMarkNotificationAsRead,
  useMarkAllNotificationsAsReadMutation: useMarkAllNotificationsAsRead,
  useFetchUserQuery,
  useSearchForUsersQuery,
  useSyncUserInfoMutation: useSyncUserInfo,
  useFetchTeamleadUserByIdQuery: useGetTeamleadByLegacyId,
} = userSlice
