import { CustomerComment } from 'lib/types/customerComment.types'

import { apiSlice } from 'state/slices/api/api.slice'

import { ForwardOrDismissCommentArgs } from './customerCommentTeamlead.slice'

export const customerCommentCoworkerSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCoworkerCustomerComments: builder.query<CustomerComment[], string>({
      query: (coworkerId) => `cw/coworkers/${coworkerId}/customerComments`,
      providesTags: (result, error, coworkerId: string) => [
        { type: 'Customer Comment', id: coworkerId },
      ],
    }),
    coworkerDismissComment: builder.mutation<void, ForwardOrDismissCommentArgs>({
      query: ({ coworkerId, interactionId }) => ({
        url: `cw/coworkers/${coworkerId}/customerComments/${interactionId}/dismiss`,
        method: 'POST',
      }),
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            customerCommentCoworkerSlice.util.updateQueryData(
              'getCoworkerCustomerComments',
              data.coworkerId,
              (draft) => {
                draft.splice(
                  draft.findIndex((comment) => comment.interactionId === data.interactionId),
                  1
                )
              }
            )
          )
        } catch (error) {
          console.error(`Error in coworker dismissing comment: ${error}`)
        }
      },
      invalidatesTags: [],
    }),
  }),
})

export const {
  useGetCoworkerCustomerCommentsQuery: useGetCoworkerCustomerComments,
  useCoworkerDismissCommentMutation: useDismissCoworkerCustomerComment,
} = customerCommentCoworkerSlice
