import InlineMessage from '@ingka/inline-message'
import { space100 } from '@ingka/variables'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { GridLayout } from 'components/composites/Shared/GridLayout/GridLayout.component'
import { GridVariant } from 'components/composites/Shared/GridLayout/GridLayout.types'
import { AptitudePanel } from 'components/features/CoworkerMVP/AptitudePanel/AptitudePanel.component'
import { ProfileInfo } from 'components/features/CoworkerMVP/ProfileInfo/ProfileInfo.component'

const CoworkerInformationTab: React.FC = () => {
  const { t } = useTranslation()
  return (
    <>
      <InlineMessage
        style={{ marginTop: space100 }}
        variant="informative"
        title={t('pages.manage-coworker.tabs.coworker-information.inline-message')}
      />

      <GridLayout
        children={[
          {
            element: <ProfileInfo />,
            variant: GridVariant.Half,
          },
          {
            variant: GridVariant.Half,
            element: <AptitudePanel />,
          },
        ]}
      />
    </>
  )
}

export default CoworkerInformationTab
