import { space50 } from '@ingka/variables'
import { Grid } from '@mantine/core'
import { orderBy } from 'lodash'

import { CoachingSessionStatus } from 'lib/types/coachingSession.types'

import { useCurrentPageType } from 'hooks/navigation/useSectionPaths.hooks'

import { useCoachingSessions } from 'state/slices/api'

import { CoachingSessionPreviewSkeleton } from 'components/composites/CoachingSessions/CoachingSessionPreview'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { CoachingSessionPreviewListProps } from './CoachingSessionPreviewList.types'
import CoachingSessionPreviewTable from './CoachingSessionPreviewTable.component'
import { NoPlannedCoachingSessionPrompt } from './NoPlannedCoachingSessionPrompt'

const SKELETON_COUNT = 4

const CoachingSessionPreviewList: React.FC<CoachingSessionPreviewListProps> = ({ coworkerId }) => {
  const { data: coachingSessions, isLoading, isError, isSuccess } = useCoachingSessions(coworkerId)
  const pageType = useCurrentPageType()

  const needToPlanCoachingSession = coachingSessions && coachingSessions.length === 0

  const comparableCoachingSession = orderBy(
    coachingSessions?.filter(
      (coachingSession) =>
        coachingSession.status === CoachingSessionStatus.Completed ||
        coachingSession.status === CoachingSessionStatus.Signed
    ),
    'plannedDate',
    'desc'
  ).at(0)

  return (
    <>
      {isLoading && (
        <Grid mt={space50}>
          {Array.from({ length: SKELETON_COUNT }).map((_, index) => (
            <Grid.Col key={index}>
              <CoachingSessionPreviewSkeleton />
            </Grid.Col>
          ))}
        </Grid>
      )}
      {isError && <UnexpectedErrorMessage />}
      {isSuccess && pageType === 'teamlead' && (
        <>
          {needToPlanCoachingSession && (
            <NoPlannedCoachingSessionPrompt
              coachingSessionCompare={comparableCoachingSession}
              coworkerId={coworkerId}
            />
          )}
          {coachingSessions && coachingSessions.length !== 0 && (
            <CoachingSessionPreviewTable coachingSessions={coachingSessions} />
          )}
        </>
      )}
    </>
  )
}

export default CoachingSessionPreviewList
