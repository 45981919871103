import { radiusS } from '@ingka/variables'
import { Box, Group } from '@mantine/core'

import { BrandColour, spacing25, spacing50, spacing100 } from 'styles/tokens/insikt.tokens'

import { SubHeading } from 'components/primitives/Text'

/**
 * UI component that displays a number and a text with a yellow background.
 * @param count - The number to display.
 * @param text - The text to display.
 */

type NumberTagProps = {
  count: number | React.ReactNode
  text: string | React.ReactNode
}
const NumberTag: React.FC<NumberTagProps> = ({ count, text }) => {
  return (
    <Group my={spacing100} px={spacing50} gap={spacing25}>
      <Box bg={BrandColour.Yellow} px={spacing25} style={{ borderRadius: radiusS }}>
        {typeof count === 'number' ? <SubHeading>{count}</SubHeading> : count}
      </Box>
      {typeof text === 'string' ? <SubHeading>{text}</SubHeading> : text}
    </Group>
  )
}

export default NumberTag
