import Button from '@ingka/button'
import bell from '@ingka/ssr-icon/paths/bell'
import { useState } from 'react'

import { User } from 'lib/types/user.types'

import * as Styled from './NotificationsButton.styling'
import { NotificationsCounter } from './NotificationsCounter'
import { NotificationsMenu } from './NotificationsMenu'

type NotificationsButtonProps = {
  user: User
}

/** Icon button that, when pressed, opens a menu with the user's notifications */
const NotificationsButton: React.FC<NotificationsButtonProps> &
  React.HTMLAttributes<HTMLButtonElement> = ({ user, ...htmlAttributes }) => {
  const [show, setShow] = useState(false)

  const unreadNotificationsCount =
    user.notifications?.reduce((sum, cur) => sum + (cur.read === false ? 1 : 0), 0) ?? 0

  return (
    <Styled.ButtonContainer>
      <Button
        inverseTheme
        data-testid="btn-notifications"
        {...htmlAttributes}
        iconOnly
        ssrIcon={bell}
        type="tertiary"
        small
        onClick={() => setShow(!show)}
      />
      {unreadNotificationsCount !== 0 && (
        <NotificationsCounter
          count={unreadNotificationsCount}
          data-testid="notifications-counter"
        />
      )}

      <NotificationsMenu user={user} show={show} onClose={() => setShow(false)} />
    </Styled.ButtonContainer>
  )
}

export default NotificationsButton
