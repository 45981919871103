import { Route } from 'react-router-dom'

import TeamleadRoutes from './TeamleadRoutes.component'

/**
 * Be aware that this is *not* a ReactNode but rather a list of JSX.element
 * The motivation for this is that react-router only allows <Route/> as child elements.
 */
const DemoRoutes = <Route path="demo">{TeamleadRoutes}</Route>

export default DemoRoutes
