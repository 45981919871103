import Button from '@ingka/button'
import arrowLeft from '@ingka/ssr-icon/paths/arrow-left'
import { Link } from 'react-router-dom'

type BackButtonProps = {
  to: string
}

const BackButton: React.FC<BackButtonProps> = ({ to }) => {
  return (
    <Link to={to}>
      <Button type="tertiary" small iconOnly ssrIcon={arrowLeft} data-testid="btn-back" />
    </Link>
  )
}

export default BackButton
