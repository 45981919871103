import Button from '@ingka/button'
import Modal, { ModalHeader, Prompt } from '@ingka/modal'
import Select, { Option } from '@ingka/select'
import { space100, space750 } from '@ingka/variables'
import { Flex, Group } from '@mantine/core'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { CoachingSession } from 'lib/types/coachingSession.types'
import { MissionStatus } from 'lib/types/mission.types'
import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'
import { CustomElementClickEventKeys } from 'lib/utils/analytics/analytics.types'
import { DateHelper } from 'lib/utils/date.helper'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'
import { useCurrentQaDataForCoworker } from 'hooks/qualityAssssments/useCurrentQaDataForCoworker.hooks'
import { useUser } from 'hooks/users'

import { useCompleteCoachingSession, useCreateCoachingSession, useMissions } from 'state/slices/api'
import { CreateCoachingSessionArgs } from 'state/slices/api/modules/coachingSession/coachingSession.slice.types'

import { DatePicker } from 'components/primitives/DatePicker'
import { Paragraph, SubHeading } from 'components/primitives/Text'

export type ConfirmModalProps = {
  showCompletionModal: boolean
  setShowCompletionModal: (value: boolean) => void
  coworkerId: string
  coachingSession: CoachingSession
}

const assessmentsOptions = ['1', '2', '3', '4', '5', '6']

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  showCompletionModal,
  setShowCompletionModal,
  coworkerId,
  coachingSession,
}) => {
  const { t } = useTranslation()

  const missions = useMissions(coworkerId).data ?? []

  const { data: user } = useUser()

  const navigate = useNavigate()

  const { coworkerPath } = useSectionPaths()

  const [completeCoachingSession, { isLoading: isLoadingCompleteCoachingSession }] =
    useCompleteCoachingSession()

  const qaData = useCurrentQaDataForCoworker(coworkerId)

  const [createCoachingSession, { isLoading: isLoadingCreateCoachingSession }] =
    useCreateCoachingSession()

  const isLoadingComplete =
    isLoadingCreateCoachingSession || isLoadingCompleteCoachingSession || !qaData

  const diff = Math.abs(
    dayjs(coachingSession.plannedDate).diff(coachingSession.createdDate, 'days')
  )

  // Set the state for planned date to default to today + the diff calculated above
  const [plannedDate, setPlannedDate] = useState<Date>(dayjs().add(diff, 'days').toDate())

  // Set the state for assessments needed to the number of assessments for the last session
  const [assessmentsNeeded, setAssessmentsNeeded] = useState<number>(
    // Does not seem necessary, since it is supposed to be always set, but adding
    coachingSession.assessmentsNeeded ?? 3
  )

  const handleCompleteCoachingSessionClicked = async () => {
    await completeCoachingSession({
      coworkerId,
      coachingSessionId: coachingSession.id,
      completedDate: DateHelper.dateToString(new Date()),
      sessionScore: qaData!.scores, // We ensure that this exists with loading state
    })
    if (!user) return
    const createCoachingSessionArgs: CreateCoachingSessionArgs = {
      coworkerId,
      createdBy: user.legacyId,
      createdDate: DateHelper.dateToString(new Date()),
      plannedDate: DateHelper.dateToString(plannedDate),
      assessmentsNeeded: assessmentsNeeded,
    }
    const activeMissions = missions.filter(
      (mission) => mission.status === MissionStatus.Active && !mission.suggestedToComplete
    )
    const newMissions = missions.filter((mission) => mission.status === MissionStatus.Draft)
    const completedMissions = missions.filter(
      (mission) => mission.status === MissionStatus.Active && mission.suggestedToComplete
    )
    await createCoachingSession(createCoachingSessionArgs)
    analyticsHelper.createCustomElementClickEvent(
      {
        key: CustomElementClickEventKeys.CompleteCoaching,
        description: 'Custom event for tracking when a teamlead completed a coaching session.',
      },
      {
        ...createCoachingSessionArgs,
        coachingSessionId: coachingSession.id,
        nrNewMissions: newMissions.length,
        nrActiveMissions: activeMissions.length,
        nrCompletedMissions: completedMissions.length,
      }
    )
    navigate(`${coworkerPath}#coaching-sessions`)
  }

  return (
    <Modal visible={showCompletionModal} handleCloseBtn={() => setShowCompletionModal(false)}>
      <Prompt
        title={t('features.coaching-session.complete-coaching-session.title')}
        titleId="complete-coaching-session-modal"
        header={<ModalHeader />}
        footer={
          <Group justify="center" gap="xl" style={{ margin: space100 }}>
            <Button
              small
              type="secondary"
              text={t('common.actions.cancel')}
              onClick={() => setShowCompletionModal(false)}
            />

            <Button
              small
              type="primary"
              text={t('features.coaching-session.actions.complete-coaching-session')}
              onClick={async () => await handleCompleteCoachingSessionClicked()}
              loading={isLoadingComplete}
            />
          </Group>
        }
      >
        <Paragraph>{t('features.coaching-session.actions.confirm-modal-subtext')}</Paragraph>
        <div style={{ marginTop: space750 }} />
        <SubHeading>
          {t('features.coaching-session.complete-coaching-session.plan-next-session')}
        </SubHeading>
        <Flex mb={space100} align={'end'}>
          <Select
            id="selectAssessments"
            label={t('features.coaching-session.actions.select-assessments-number')}
            onChange={(e) => {
              if (!isNaN(Number(e.target.value))) {
                setAssessmentsNeeded(Number(e.target.value))
              }
            }}
            value={String(assessmentsNeeded)}
          >
            {assessmentsOptions.map((v) => (
              <Option name={v} value={v} key={v} />
            ))}
          </Select>
          <DatePicker
            id="1"
            label={t('features.coaching-session.actions.choose-planned-date')}
            value={plannedDate}
            placement="top"
            onChange={(v) => {
              setPlannedDate(v)
            }}
            minDate={dayjs().add(7, 'days').toDate()}
          ></DatePicker>
        </Flex>
        <Paragraph>
          {t('features.coaching-session.complete-coaching-session.confirmation-message')}
        </Paragraph>
      </Prompt>
    </Modal>
  )
}

export default ConfirmModal
