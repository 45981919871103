import { useDisclosure } from '@mantine/hooks'

import { Mission } from 'lib/types/mission.types'

import useCurrentScoreByQaId from 'hooks/qualityAssssments/useCurrentScoreByQaId.hooks'

import { Widget } from 'components/composites/Shared/Widget'
import { MissionOverview } from 'components/features/Missions/MissionOverview'

import { AssessmentCriteria } from './AssessmentCriteria/AssessmentCriteria.component'
import { ConnectedMetricBanner } from './ConnectedMetricBanner/ConnectedMetricBanner.component'
import { MissionBody } from './MissionBody/MissionBody.component'
import { MissionFocus } from './MissionBody/MissionFocus/MissionFocus.component'
import { MissionHeader } from './MissionHeader/MissionHeader.component'

export type MissionPreviewProps = {
  mission: Mission
  coworkerId: string
}

const MissionPreview: React.FC<MissionPreviewProps> = ({ mission, coworkerId }) => {
  const [opened, { open, close }] = useDisclosure(false)

  const currentScore = useCurrentScoreByQaId(mission.qaId, coworkerId)

  return (
    <Widget border>
      <MissionHeader mission={mission} currentScore={currentScore} open={open} />
      <MissionFocus qaId={mission.qaId} />
      <MissionBody
        initialScore={mission.startedScore ?? NaN}
        currentScore={currentScore}
        mission={mission}
      />

      {/* TODO: Change this whole thingy into new metrics once the time is right   */}
      <ConnectedMetricBanner metricId={mission.metricId} />

      <AssessmentCriteria qaId={mission.qaId} />

      {/* View mission details drawer */}
      {opened && <MissionOverview missionId={mission.id} opened={opened} close={close} />}
    </Widget>
  )
}

export default MissionPreview
