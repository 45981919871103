import Text from '@ingka/text'
import { fontWeightBold, space50, space100 } from '@ingka/variables'
import { Box, Flex } from '@mantine/core'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { Coworker } from 'lib/types/coworker.types'

import { colourBrandBlue, colourNeutralGrey200, fontSize600 } from 'styles/tokens/oldSkapa.tokens'

type PageHeaderProps = {
  plannedDate: string
  coworker: Coworker
}

const EditorHeader: React.FC<PageHeaderProps> = ({ coworker, plannedDate }) => {
  const { t } = useTranslation()

  return (
    <Flex direction={'column'} align="start" pt={space100} pb={space50}>
      <Flex align="center" gap={space50} w={'100%'}>
        {coworker && plannedDate && (
          <>
            <Text
              style={{
                color: colourBrandBlue,
                fontWeight: fontWeightBold,
                fontSize: fontSize600,
              }}
            >
              {t('features.coaching-session.edit-coaching-session.coaching-session-for', {
                name: coworker.fullName,
              })}
            </Text>
            <Box
              style={{
                backgroundColor: colourNeutralGrey200,
                padding: space50,
                borderRadius: space50,
                marginLeft: space50,
              }}
            >
              <Text>
                {t('features.coaching-session.edit-coaching-session.scheduled-at', {
                  date: dayjs(plannedDate).format('YYYY-MM-DD'),
                })}
              </Text>
            </Box>
          </>
        )}
        <span style={{ flexGrow: 1 }} />
      </Flex>
      <Text>{t('features.coaching-session.edit-coaching-session.description')}</Text>
    </Flex>
  )
}

export default EditorHeader
