import Button from '@ingka/button'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { Widget, WidgetFooter, WidgetHeader } from 'components/composites/Shared/Widget'

import { CoworkerActionsProps } from './CoworkerSignCompletedSession.types'

const CoworkerSignCompletedSession: React.FC<CoworkerActionsProps> = ({
  completedSessionAwaiting,
}) => {
  const { t } = useTranslation()
  const { coworkerPath } = useSectionPaths()
  const { pathname } = useLocation()

  return (
    <>
      {completedSessionAwaiting && (
        <Widget
          header={
            <WidgetHeader
              title={t('features.coaching-session.awaiting-signature.title')}
              subtitle={t('features.coaching-session.awaiting-signature.subtitle')}
            />
          }
          footer={
            <WidgetFooter>
              <Link
                to={`${coworkerPath}/coaching-sessions/${completedSessionAwaiting.id}`}
                state={{ from: pathname }}
              >
                <Button
                  type="primary"
                  small
                  text={t('features.coaching-session.actions.go-to-coaching-session')}
                />
              </Link>
            </WidgetFooter>
          }
        ></Widget>
      )}
    </>
  )
}

export default CoworkerSignCompletedSession
