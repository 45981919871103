import Button from '@ingka/button'
import SSRIcon from '@ingka/ssr-icon'
import expand from '@ingka/ssr-icon/paths/expand'
import { Box,Flex } from '@mantine/core'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { Mission, MissionStatus } from 'lib/types/mission.types'

import { useFormatDate } from 'hooks/formatting'

import { spacing25 } from 'styles/tokens/insikt.tokens'

import { Label,SubHeading } from 'components/primitives/Text'

import { transKey } from '../MissionPreview.constants'
import { getMetricIcon } from '../MissionPreview.utils'

export type Props = {
  mission: Mission
  currentScore: number
  open: any
}

export const MissionHeader = ({ mission, currentScore, open }: Props) => {
  const { t } = useTranslation()
  const formatDate: (date: Date) => string = useFormatDate()
  const date = mission.status === MissionStatus.Completed ? mission.completedDate : mission.endDate
  const labelText = t(
    `${transKey}.${mission.status === MissionStatus.Completed ? 'completed-on' : 'due-date'}`,
    {
      date: formatDate(dayjs(date).toDate()),
    }
  )
  return (
    <>
      <Flex align="center">
        <SSRIcon
          paths={getMetricIcon(mission.status, currentScore)}
          style={{ marginRight: spacing25 }}
        />
        <Box ml={spacing25}>
          <SubHeading>{mission.title}</SubHeading>
          <Label>{labelText}</Label>
        </Box>
        <Button
          type="tertiary"
          small
          iconPosition="trailing"
          ssrIcon={expand}
          onClick={open}
          style={{
            marginLeft: 'auto',
          }}
        />
      </Flex>
    </>
  )
}
