import { getTaskListExtension, Link as TipTapLink } from '@mantine/tiptap'
import Document from '@tiptap/extension-document'
import Placeholder from '@tiptap/extension-placeholder'
import TaskItem from '@tiptap/extension-task-item'
import TipTapTaskList from '@tiptap/extension-task-list'
import Underline from '@tiptap/extension-underline'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LinkNode } from 'lib/types/richTextDocument.types'
import { isJsonString, parseCommentValue } from 'lib/utils/generateHTML.helper'

export const useTextEditor = (initialValue: string, placeholderText?: string) => {
  const { t } = useTranslation()
  const [isEditable, setIsEditable] = useState<boolean>(false)
  const [value, setValue] = useState<string>(initialValue)

  const editor = useEditor({
    content: isJsonString(initialValue)
      ? JSON.parse(initialValue)
      : parseCommentValue(initialValue),
    autofocus: true,
    editable: isEditable,
    onUpdate({ editor }) {
      const content = editor.getJSON()
      setValue(JSON.stringify(content))
    },
    extensions: [
      Document.configure({
        content: 'doc',
      }),
      StarterKit,
      Placeholder.configure({
        placeholder: placeholderText || t('common.placeholder-text.editor-placeholder'),
        emptyEditorClass: 'is-editor-empty',
        emptyNodeClass: 'is-empty',
        showOnlyWhenEditable: isEditable, // Show placeholder when in edit mode or doesn't have any content/initial value
      }),
      getTaskListExtension(TipTapTaskList),
      TaskItem.configure({
        nested: true,
        HTMLAttributes: {
          class: 'coaching-pointers-task-items',
        },
      }),
      TipTapLink.configure({
        openOnClick: true,
        linkOnPaste: true,
        autolink: true,
        validate(url) {
          return /^(https?:\/\/)?(www\.)?/.test(url)
        },
        HTMLAttributes: {
          inclusive: false,
          href: (node: LinkNode) => node.attrs.href,
          target: '_blank',
          rel: 'noopener noreferrer nofollow',
          class: 'editor-link',
        },
      }),
      Underline,
    ],
  })

  return {
    editor,
    isEditable,
    setIsEditable,
    value,
  }
}
