import React from 'react'
import { useTranslation } from 'react-i18next'

import { backgroundColourDefault } from 'styles/tokens/insikt.tokens'

import { Stack } from 'components/layout/Stack'
import { Paragraph } from 'components/primitives/Text'

import { Widget } from '../Widget'

type EmptyContentProps = {
  translationKey: string
  tranlationOptions?: Record<string, string>
}

const EmptyContent: React.FC<EmptyContentProps> = ({ translationKey, tranlationOptions }) => {
  const { t } = useTranslation()

  return (
    <Widget style={{ background: backgroundColourDefault }}>
      <Stack>
        <img src="/images/empty-box/emptystate.svg" alt={t(translationKey)} />
        <Paragraph>{t(translationKey, tranlationOptions)}</Paragraph>
      </Stack>
    </Widget>
  )
}

export default EmptyContent
