import people from '@ingka/ssr-icon/paths/people'
import pieChart from '@ingka/ssr-icon/paths/pie-chart'

import { TabDefinitions } from 'components/composites/Shared/TabLayout/TabLayout.types'

import { ManageCoworkersPage } from '../ManageCoworkersPage'
import { TeamSalesPage } from '../TeamSalesPage/TeamSalesPage.component'

export const teamleadLandingPageTabs: TabDefinitions = [
  {
    key: 'team-overview',
    tKeyForTitle: 'features.teamlead.tabs.team-overview',
    icon: people,
    content: <ManageCoworkersPage />,
  },
  {
    key: 'team-sales',
    content: <TeamSalesPage />,
    tKeyForTitle: 'features.teamlead.tabs.team-sales',
    icon: pieChart,
  },
]
