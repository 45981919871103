import Toast from '@ingka/toast'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { APP_VERSION } from 'config/app.config'

import { UserRole } from 'lib/types/user.types'

import { useUser } from 'hooks/users'

import { useUserPrefsQuery } from 'state/slices/api'
import { useUpdatePrefsMutation } from 'state/slices/api/modules/user/userPrefs.slice'

const CHANGELOG_URL = 'https://confluence.build.ingka.ikea.com/display/CSICI/6.+Release+Notes'

const NewVersionToast: React.FC = () => {
  const { t } = useTranslation()

  const { data: user } = useUser()
  const { data: userPrefs } = useUserPrefsQuery()
  const [updatePrefs] = useUpdatePrefsMutation()
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (user && user.role === UserRole.Coworker) {
      setShow(false)
      return
    }
    // As userPrefs can be fetched before the user, we need to check if both are loaded, otherwise we might
    // show the toast for a split second before we disable it based on the role
    if (user && userPrefs && userPrefs.latestAppVersion !== APP_VERSION) {
      setShow(true)
    }
  }, [userPrefs, user])

  const handleLinkClicked = () => {
    handleDismissClicked()
    window.open(CHANGELOG_URL, '_newtab')
  }

  const handleDismissClicked = () => {
    if (!user || !userPrefs) {
      return
    }

    const updatedPrefs = {
      ...userPrefs,
      latestAppVersion: APP_VERSION,
    }

    updatePrefs({ userId: user.id, prefs: updatedPrefs })
    setShow(false)
  }
  return (
    <Toast
      text={t('common.new-version.title')}
      isOpen={show}
      actionClick={handleLinkClicked}
      actionButtonText={t('common.new-version.link') as string}
      onCloseRequest={handleDismissClicked}
    />
  )
}

export default NewVersionToast
