import FormField from '@ingka/form-field'
import { space100 } from '@ingka/variables'
import { Flex, NumberInput } from '@mantine/core'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Coworker } from 'lib/types/coworker.types'
import { Team } from 'lib/types/team.types'

import { MissionDraftFormFields } from 'components/features/Missions/MissionDraftEditor/MissionDraftEditor.types'

type Props = {
  form: UseFormReturn<MissionDraftFormFields> & {
    watch: (field: keyof MissionDraftFormFields) => any
  }
  coworker: Coworker
  team: Team
}

const TargetStep: React.FC<Props> = ({ form, coworker, team }) => {
  const { t } = useTranslation()
  const { control, formState } = form

  return (
    <>
      <Flex justify="space-between" w="100%" gap={space100}>
        <Controller
          name="target"
          control={control}
          rules={{
            required: {
              value: true,
              message: t('features.mission.create-mission.fields.target.errors.missing'),
            },

            pattern: {
              value: /^([0-9]+\.?[0-9]*|\.[0-9]+)$/,
              message: t('features.mission.create-mission.fields.target.errors.invalid-pattern'),
            },
            validate: (value) => {
              return `${value} is a valid title.`
            },
          }}
          render={({ field }) => (
            <FormField
              style={{ maxWidth: '10rem' }}
              data-testid="form-field-target"
              valid={formState.errors.target?.type === 'validate' ? true : false}
              shouldValidate={formState.errors.target ? true : false}
              fieldHelper={{
                msg: t('features.mission.create-mission.fields.target.hint', {
                  coworker: coworker.firstName,
                }),
              }}
              validation={{
                id: 'error-title',
                msg: formState.errors.target?.message,
                type: 'error',
              }}
            >
              <NumberInput
                {...field}
                defaultValue={75}
                min={0}
                max={100}
                id="target"
                data-testid="input-field-target"
                label={t('features.mission.create-mission.steps.focus-area.target.title')}
              />
            </FormField>
          )}
        />
      </Flex>
    </>
  )
}

export default TargetStep
