import Tooltip from '@ingka/tooltip'
import { space50 } from '@ingka/variables'
import { Flex, Switch } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { Paragraph } from 'components/primitives/Text'

interface Props {
  isChanged: boolean
  showChanges: boolean
  setShowChanges: (showChanges: boolean) => void
}

const MissionOverviewDraftSwitch = ({ isChanged, showChanges, setShowChanges }: Props) => {
  const { t } = useTranslation()
  return (
    <Flex align={'center'}>
      <Paragraph style={{ marginRight: space50 }}>{t('features.mission.preview.active')}</Paragraph>
      <Tooltip
        position="bottom"
        tooltipText={
          showChanges
            ? t('features.mission.preview.see-draft')
            : t('features.mission.preview.see-active')
        }
      >
        <Switch
          size="xl"
          radius={20}
          onLabel=""
          offLabel=""
          checked={showChanges}
          onChange={() => setShowChanges(!showChanges)}
          color="dark"
          aria-label="Toggle between active mission and modified draft state"
        />
      </Tooltip>
      <Paragraph style={{ marginLeft: space50 }}>{t('features.mission.preview.draft')}</Paragraph>
    </Flex>
  )
}

export default MissionOverviewDraftSwitch
